






//class generated by TypeWriter in Core API project by ModelGenerator.nt template
export class AddAuditLogRequestModel {
    public objectType: string;
    public objectId: string | null;
    public objectAction: string;
    public message: string;
    public eventType: string;

    constructor(init? : Partial<AddAuditLogRequestModel>) {
        
        Object.assign(this, init);
    }
}
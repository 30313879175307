import { generateUUID } from './utils';

export class FileHelper {
    // generates a random GUID that can be used to store the document
    static generateUUID(): string {
        return generateUUID();
    }
}

export const getFileWithoutExtension = (filename: string): string => {
    if (!filename) {
        return '';
    }
    const index = filename.lastIndexOf('.');
    return index >= 0 ? filename.slice(0, index) : filename;
};

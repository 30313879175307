import { HostListener, Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, distinctUntilChanged, fromEvent, map, takeUntil, tap } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { debounceTime, filter, startWith } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Injectable(/* {
	providedIn: 'root',
} */)
export class BrowserService implements OnDestroy {
	readonly breakpoint$ = this.breakpointObserver
		.observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall, '(min-width: 500px)'])
		.pipe(
			//tap(value => console.log(`handset ${Breakpoints.Handset}`, value)),
			distinctUntilChanged()
		);
	readonly innerHeight$ = new BehaviorSubject<number>(window.innerHeight);
	readonly innerWidth$ = new BehaviorSubject<number>(window.innerWidth);
	readonly isMobile$ = new BehaviorSubject<boolean>(true);
	readonly isDesktop$ = new BehaviorSubject<boolean>(false);
	readonly isLandscape$ = new BehaviorSubject<boolean | undefined>(undefined);
	readonly breakpoints = Breakpoints;
	readonly currentBreakpoint = new BehaviorSubject<string | undefined>(undefined);
	private readonly _detroyed = new Subject<void>();

	constructor(
		private breakpointObserver: BreakpointObserver,
		private router: Router,
		private route: ActivatedRoute,
		private ngZone: NgZone
	) {
		this.breakpoint$
			.pipe(
				tap(() => this.breakpointChanged()),
				takeUntil(this._detroyed) // order matters; must come after other operators, except the following:
				// last, takeUntil, toArray, reduce, count, max, min... (to name a few common ones)
			)
			.subscribe();

		// keep fullUrl$ (see routing helpers) updated as the route changes
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map(() => this.router.url),
			tap(url => this.fullUrl$$.next(url))
			// todo: if needed, add takeUntil(this._detroyed)
		);

		// window resize event
		this.ngZone.runOutsideAngular(() => {
			fromEvent(window, 'resize')
				.pipe(
					takeUntil(this._detroyed),
					debounceTime(100),
					map(() => ({
						innerHeight: window.innerHeight,
						innerWidth: window.innerWidth,
					})),
					tap(size => console.log('Window size:', size)),
					startWith({
						innerHeight: window.innerHeight,
						innerWidth: window.innerWidth,
					})
				)
				.subscribe(innerDimensions => {
					this.ngZone.run(() => {
						this.innerWidth$.next(innerDimensions.innerWidth);
						this.innerHeight$.next(innerDimensions.innerHeight);
						const isLandscape = innerDimensions.innerWidth > innerDimensions.innerHeight;
						this.isLandscape$.next(isLandscape);
					});
				});
		});
	}

	ngOnDestroy(): void {
		this._detroyed.next();
		this._detroyed.complete();
	}

	//#region window resize
	// host listener for window resize not working -- use fromEvent instead
	//@HostListener('window:resize', ['$event'])
	//onResize(event?: Event) {
	//	console.log('Window resized event:', (event?.target as Window)?.innerWidth);
	//	console.log('Window resized:', window.innerWidth);
	//	this.innerWidth$.next(window.innerWidth);
	//}

	getInnerWidth(): Observable<number> {
		return this.innerWidth$.asObservable();
	}

	private breakpointChanged() {
		this.isMobile$.next(this.breakpointObserver.isMatched(Breakpoints.XSmall));
		this.isDesktop$.next(!this.breakpointObserver.isMatched(Breakpoints.XSmall));
		if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
			this.currentBreakpoint.next(Breakpoints.Large);
		} else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
			this.currentBreakpoint.next(Breakpoints.Medium);
		} else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
			this.currentBreakpoint.next(Breakpoints.Small);
		} else if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
			this.currentBreakpoint.next(Breakpoints.XSmall);
		} else if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
			this.currentBreakpoint.next(Breakpoints.Handset);
		} else if (this.breakpointObserver.isMatched('(min-width: 500px)')) {
			// custom breakpoint
			this.currentBreakpoint.next('(min-width: 500px)');
		}
	}
	//#endregion window resize

	//#region routing helpers
	private fullUrl$$ = new BehaviorSubject<string>(this.router.url);
	public fullUrl$ = this.fullUrl$$.asObservable();
	public route$ = combineLatest([this.route.params, this.route.queryParams, this.fullUrl$]).pipe(
		takeUntil(this._detroyed), // order matters
		tap(([params, queryParams, fullUrl]) => console.log(`route:`, params, queryParams, fullUrl)),
		map(([params, queryParams, fullUrl]) => ({
			meetingId: params['id'],
			agendaItemId: params['agendaItemId'],
			documentId: params['documentId'] ?? queryParams['target'],
			previewMode: coerceBooleanProperty(queryParams['preview']),
			userAdmin: coerceBooleanProperty(queryParams['useAdmin']),
			fileView: coerceBooleanProperty(queryParams['fileView']),
			notes: coerceBooleanProperty(queryParams['notes']),
			isViewerRoute: fullUrl.includes('/viewer/'),
			fullUrl: fullUrl,
		})),
		tap(data => console.log(`browser service route data`, data))
	);
	//#endregion routing helpers
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
    constructor() {
        //Authguard will handle redirect so this is just an interim page
        //Goto auth.guard.ts component to see the business rule as depending on the role it will redirect Admins to /admin/home and viewers to just /meetings
    }

    ngOnInit(): void {}
}

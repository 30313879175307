import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-beta-callout',
    templateUrl: './beta-callout.component.html',
    styleUrls: ['./beta-callout.component.scss'],
})
export class BetaCalloutComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

<div
    *ngIf="!documents.length; else showDocs"
    class="uk-text-center uk-width-1-1 no-docs">
    <h3>No documents are available</h3>
</div>

<ng-template #showDocs>
    <div class="table-container uk-text-center">
        <table
            mat-table
            [dataSource]="getDataSource(documents)"
            class="file-list-table">
            <ng-container matColumnDef="date">
                <td mat-cell *matCellDef="let file">
                    <strong>{{ getDateFormatted(file.documentName) }}</strong>
                </td>
            </ng-container>
            <ng-container matColumnDef="documentName">
                <td mat-cell *matCellDef="let file">
                    {{ getFileName(file.documentName) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <td mat-cell *matCellDef="let file">
                    {{ getEmail(file.documentName) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="updateDate">
                <td mat-cell *matCellDef="let file">
                    Last Modified {{ file.updateDate | mstDate: 'DD/MM/YY' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="view">
                <td mat-cell *matCellDef="let file" class="bubbledLink">
                    <app-agenda-document-link
                        [documentId]="file.documentId"
                        [user]="user"></app-agenda-document-link>
                </td>
            </ng-container>
            <ng-container matColumnDef="rsvp">
                <td mat-cell *matCellDef="let file">
                    <button
                        class="rsvp hyperlink clickable uk-button uk-button-link"
                        (click)="openMail(file.documentName)">
                        RSVP
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="help">
                <td mat-cell *matCellDef="let file">
                    <mat-icon
                        #tooltip="matTooltip"
                        (click)="tooltip.toggle()"
                        matTooltip="If the RSVP link does not work on your browser, send an email to 
                         {{ getEmail(file.documentName) }}
                         Subject line
                         RSVP:{{ getFileName(file.documentName) }} {{
                            getDateFormatted(file.documentName)
                        }}"
                        >help_outline</mat-icon
                    >
                </td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <td mat-cell *matCellDef="let file" class="bubbledLink">
                    <button
                        class="hyperlink clickable uk-button uk-button-link"
                        (click)="deleteDocument($event, file.documentId)">
                        Delete
                    </button>
                </td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</ng-template>

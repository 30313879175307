import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DateHelper } from '@ecom/common/helpers/dateHelper';
import { AgendaItemModel } from '@ecom/ecom-app-generated/Models/AgendaItemModel';
import { AgendaSectionModel } from '@ecom/ecom-app-generated/Models/AgendaSectionModel';
import { DocumentAssignmentsModel } from '@ecom/ecom-app-generated/Models/DocumentAssignmentsModel';
import { DocumentModel } from '@ecom/ecom-app-generated/Models/DocumentModel';
import { MeetingModel } from '@ecom/ecom-app-generated/Models/MeetingModel';
import { SharedMeetingHelperService } from '@ecom/services/shared-meeting-helper.service';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-meeting-agenda-report',
    templateUrl: './meeting-agenda-report.component.html',
    styleUrls: ['./meeting-agenda-report.component.scss'],
})
export class MeetingAgendaReportComponent implements OnInit, OnDestroy {
    @Input() activeMeeting!: MeetingModel;

    //all agenda sections for the meeting
    sectionList: AgendaSectionModel[] = [];

    //all agendaItems for the meeting
    agendaItems: AgendaItemModel[] = [];

    //all document assignments
    documentAssignments: DocumentAssignmentsModel[] = [];

    //all the documents for this meeting
    documents: DocumentModel[] = [];

    calculatedEndTime: Date;
    duration = 0;

    //subscriptions;
    sections$: Subscription;
    agendaItems$: Subscription;
    documentAssignemnts$: Subscription;
    documents$: Subscription;

    constructor(private sharedMeetingService: SharedMeetingHelperService) {}

    ngOnInit(): void {
        this.sections$ = this.sharedMeetingService.sectionsReady$.subscribe(
            payload => {
                this.sectionList = payload ?? [];
            }
        );

        this.agendaItems$ =
            this.sharedMeetingService.agendaItemsReady$.subscribe(payload => {
                this.agendaItems = payload ?? [];
            });

        this.documents$ = this.sharedMeetingService.documentsReady$.subscribe(
            payload => {
                this.documents = payload ?? [];
            }
        );

        this.documentAssignemnts$ =
            this.sharedMeetingService.documentAssignmentsReady$.subscribe(
                payload => {
                    this.documentAssignments = payload ?? [];
                }
            );
    }

    ngOnDestroy(): void {
        //clean up
        if (this.sections$) {
            this.sections$.unsubscribe();
        }

        if (this.agendaItems$) {
            this.agendaItems$.unsubscribe();
        }

        if (this.documents$) {
            this.documents$.unsubscribe();
        }

        if (this.documentAssignemnts$) {
            this.documentAssignemnts$.unsubscribe();
        }
    }

    /**
     * @summary Gets Agenda Items for a specific agendaSectionId by using the power of selectors from the state/store
     * @parameters (agendaSectionId: string)
     */
    public getAgendaItems(agendaSectionId: string) {
        return this.agendaItems.filter(
            x => x.agendaSectionId == agendaSectionId
        );
    }

    calculateTime(
        meeting: MeetingModel,
        section: AgendaSectionModel,
        endTime?: boolean
    ): string {
        let retVal: string = '';
        if (endTime === true) {
            let startTime = DateHelper.addMinutes(
                meeting.startDate,
                section.startOffsetInMinutes
            );
            let endTime = DateHelper.addMinutes(
                startTime,
                section.durationInMinutes
            );
            retVal = moment(endTime).format('h:mm A');
        } else {
            let startTime = DateHelper.addMinutes(
                meeting.startDate,
                section.startOffsetInMinutes
            );
            retVal = moment(startTime).format('h:mm A');
        }

        //return the formatted object
        return retVal;
    }

    calculateEndTime(meeting: MeetingModel) {
        this.duration = this.getDuration(meeting);
        return moment(meeting.startDate).add(this.duration, 'minutes').toDate();
    }

    getDuration(meeting: MeetingModel) {
        return meeting.durationInMinutes;
    }
}

<div class="bannerContainer uk-animation-fade" *ngIf="bannerHasValue()">
    <div uk-grid class="uk-grid-small">
        <div class="uk-width-auto">
            <span class="material-icons"> info </span>
        </div>
        <div class="uk-width-expand" [innerHTML]="activeBanner?.value"></div>
        <div class="uk-width-small uk-text-right uk-hidden">
            <span class="material-icons clickable"> close </span>
        </div>
    </div>
</div>

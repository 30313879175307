
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';

import { DocumentModel } from '../Models/DocumentModel'
import { AgendaPreviewModel } from '../Models/AgendaPreviewModel'
import { IdRequestModel } from '../Models/IdRequestModel'
import { DocumentThumbnailModel } from '../Models/DocumentThumbnailModel'
import { AddDocumentRequestModel } from '../Models/AddDocumentRequestModel'
import { IdResponseModel } from '../Models/IdResponseModel'
import { UploadProgressResponseModel } from '../Models/UploadProgressResponseModel'

@Injectable({
	providedIn: 'root'
})
export class DocumentsService extends EndPointService {

	constructor(http: HttpClient, configService: ConfigService) {
		super(http, configService, 'documents');
	}


	public getViewerDocuments(): Promise<ServiceResult<DocumentModel[]>> {
		return this.get<ServiceResult<DocumentModel[]>>('GetViewerDocuments');
	};

	public getAdminDocuments(): Promise<ServiceResult<DocumentModel[]>> {
		return this.get<ServiceResult<DocumentModel[]>>('GetAdminDocuments');
	};

	public getAdminAgendaPreviewDocuments(): Promise<ServiceResult<AgendaPreviewModel>> {
		return this.get<ServiceResult<AgendaPreviewModel>>('GetAdminAgendaPreviewDocuments');
	};

	public getAgendaPreviewDocuments(): Promise<ServiceResult<AgendaPreviewModel>> {
		return this.get<ServiceResult<AgendaPreviewModel>>('GetAgendaPreviewDocuments');
	};
	//get
	public getDocumentThumbnail(model: IdRequestModel): Promise<ServiceResult<DocumentThumbnailModel>> {
		return this.getWithQuery<ServiceResult<DocumentThumbnailModel>, IdRequestModel>('GetDocumentThumbnail', model);
	};

	//get
	public getThumbnailsByMeetingId(model: IdRequestModel): Promise<ServiceResult<DocumentThumbnailModel[]>> {
		return this.getWithQuery<ServiceResult<DocumentThumbnailModel[]>, IdRequestModel>('GetThumbnailsByMeetingId', model);
	};

	//get
	public getDocumentById(model: IdRequestModel): Promise<ServiceResult<DocumentModel>> {
		return this.getWithQuery<ServiceResult<DocumentModel>, IdRequestModel>('GetDocumentById', model);
	};

	//get
	public getDocumentBinaryById(model: IdRequestModel): Promise<ServiceResult<DocumentModel>> {
		return this.getWithQuery<ServiceResult<DocumentModel>, IdRequestModel>('GetDocumentBinaryById', model);
	};


	//post
	public addDocument(model: AddDocumentRequestModel): Promise<ServiceResult<DocumentModel>> {
		return this.post<ServiceResult<DocumentModel>, AddDocumentRequestModel>('AddDocument', model);
	};

	//post
	public deleteDocument(model: IdRequestModel): Promise<ServiceResult<IdResponseModel>> {
		return this.post<ServiceResult<IdResponseModel>, IdRequestModel>('DeleteDocument', model);
	};

	//post
	public getDocumentsByMeetingId(model: IdRequestModel): Promise<ServiceResult<DocumentModel[]>> {
		return this.post<ServiceResult<DocumentModel[]>, IdRequestModel>('GetDocumentsByMeetingId', model);
	};

	//post
	public getUploadProgress(model: IdRequestModel): Promise<ServiceResult<UploadProgressResponseModel>> {
		return this.post<ServiceResult<UploadProgressResponseModel>, IdRequestModel>('GetUploadProgress', model);
	};

	//post
	public hasUpdatedDocumentsByMeetingId(model: IdRequestModel): Promise<ServiceResult<DocumentModel[]>> {
		return this.post<ServiceResult<DocumentModel[]>, IdRequestModel>('HasUpdatedDocumentsByMeetingId', model);
	};
}
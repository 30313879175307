import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from '@ecom/app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakBearerInterceptor } from 'keycloak-angular';

import { Constants } from '@ecom/common/helpers/constants';

import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { DmxCoreModule } from './modules/dmx-core/dmx-core.module';
import { MaterialModule } from './modules/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationService } from './common/authentication/services/authentication.service';

import { filter, take } from 'rxjs';
import { LoggingService } from './common/services/logging-service/logging-service.service';
import { LocalDocumentsService } from './common/services/local-documents-service/local-documents.service';
import { AppWorkflowsService } from './app-workflows/core/app-workflow-service';
import { DocumentsWorkflowv2 } from './app-workflows/workflows/documents-workflow/documents-workflow-v2';
import { AdminDocumentsWorkflowv2 } from './app-workflows/workflows/admin-documents-workflow/admin-documents-workflow-v2';

@NgModule({
    declarations: [],
    imports: [
        //angular defaults test
        BrowserModule,
        AppRoutingModule,

        //custom
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MaterialModule,
        DmxCoreModule,
        HttpClientModule,
    ],
    providers: [
        //DMX authentication service
        AuthenticationService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAuthentication,
            deps: [AuthenticationService],
            multi: true,
        },

        //Keycloak bearer token interceptor
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KeycloakBearerInterceptor,
            multi: true,
        },

        //DMX local storage provisioning - where we store documents on devices for admin / viewer
        {
            provide: APP_INITIALIZER,
            useFactory: localDocumentDbInitializer,
            deps: [AuthenticationService, LoggingService],
            multi: true,
        },

        //WorkflowService,
        //StartupWorkflow,
        //DocumentsWorkflow,
        //AdminDocumentsWorkflow,
        //MeetingsWorkflows,

        //Workflows that run
        {
            provide: APP_INITIALIZER,
            useFactory: workflowInitializer,
            deps: [
                AppWorkflowsService,
                DocumentsWorkflowv2,
                AdminDocumentsWorkflowv2,
                AuthenticationService,
            ],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

// Add this function outside the AppModule class
export function initializeAuthentication(
    authenticationService: AuthenticationService
): () => Promise<Boolean> {
    return () => {
        //console.time("initializeAuthentication");
        var result = authenticationService.initialize();
        //console.timeEnd("initializeAuthentication");
        return result;
    };
}

export function workflowInitializer(
    workflowService: AppWorkflowsService,
    documentsWorkflow: DocumentsWorkflowv2,
    adminDocumentsWorkflow: AdminDocumentsWorkflowv2,
    authenticationService: AuthenticationService
) {
    return () => {
        const excludedUserAgents = [
            'ipad', // Common pattern for iPads
            'tablet', // Generic pattern for many tablets
            'iphone', // Common pattern for iPhones
            'android', // Common pattern for Android devices; includes both phones and tablets
        ];

        authenticationService
            .currentUser()
            .pipe(
                filter(user => user !== undefined),
                take(1)
            )
            .subscribe(async currentUser => {
                if (currentUser) {
                    // Convert the user agent to lowercase for case-insensitive comparison
                    const userAgentLowerCase =
                        navigator.userAgent.toLowerCase();
                    const isExcludedDevice = excludedUserAgents.some(ua =>
                        userAgentLowerCase.includes(ua)
                    );

                    //Add viewer
                    workflowService.addWorkflow(documentsWorkflow);
                    //Add admin workflow only if the user is an admin and the device is not excluded
                    if (!isExcludedDevice && currentUser.userRoles.includes(Constants.UserRoles.Admin)) {
                        workflowService.addWorkflow(adminDocumentsWorkflow);
                    }

                    workflowService.run();
                }
            });

        return Promise.resolve();
    };
}

// Initialize the local db with both databases that we require
export function localDocumentDbInitializer(
    authenticationService: AuthenticationService,
    loggingService: LoggingService
) {
    return async () => {
        authenticationService
            .currentUser()
            .pipe(
                filter(user => user !== undefined),
                take(1)
            )
            .subscribe(async currentUser => {
                if (currentUser) {
                    const localDocumentsService = new LocalDocumentsService();
                    localDocumentsService.createDatabase(
                        Constants.LocalStorage.DocumentsForViewer
                    );

                    if (currentUser.userRoles.includes(Constants.UserRoles.Admin)) {
                        localDocumentsService.createDatabase(Constants.LocalStorage.DocumentsForAdmin);
                    }
                }
            });
        /**
         * Create indexDb entry to store viewer meetings so that offline meeting access is possible
         * July 31, 2023 user story to allow for CCO to be able to use their devices offline to view meetings; 
         * this requires a workflow to cache meetings so that they are available on the device in the indexDb service
         */
        // const localViewerMeetingsDbService = new LocalViewerMeetingsDBService(Constants.MeetingDatabase.DatabaseName);
        // await localViewerMeetingsDbService.createDb();

        //console.timeEnd("localDocumentDbInitializer");
    };
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CommitteeEmailModel } from '@ecom/ecom-app-generated/Models/CommitteeEmailModel';
import { DocumentModel } from '@ecom/ecom-app-generated/Models/DocumentModel';
import { UserModel } from '@ecom/ecom-app-generated/Models/UserModel';
import { FileViewerService } from '@ecom/services/file-viewer.service';
import { AgendaPreviewService } from '../agenda-preview-service';
import { DocumentThumbnailModel } from '@ecom/ecom-app-generated/Models/DocumentThumbnailModel';
import {
    Subject,
    Subscription,
    debounceTime,
    from,
    interval,
    startWith,
    switchMap,
} from 'rxjs';
import { Constants } from '@ecom/common/helpers/constants';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';

@Component({
    selector: 'app-preview-document-list',
    templateUrl: './agenda-preview-list.component.html',
    styleUrls: ['./agenda-preview-list.component.scss'],
})
export class AgendaPreviewListComponent implements OnInit, OnDestroy {
    dataSource = new MatTableDataSource<DocumentModel>([]);
    displayedColumns = ['date', 'documentName', 'view'];
    @Input() isAdmin = false;
    @Input() meetingId: string;
    @Input() documents: DocumentModel[] = [];
    @Input() committeeEmails: CommitteeEmailModel[];
    @Input() user: UserModel;
    @Output() onDeleteDocument: EventEmitter<string> =
        new EventEmitter<string>();

    //all the documents for the meeting
    thumbnails: DocumentThumbnailModel[] = [];

    thumbNails$: Subscription;
    private documentCheckSubject = new Subject<string>();
    constructor(
        private localDocumentsService: LocalDocumentsService,
        private fileViewerService: FileViewerService,
        private agendaPreviewService: AgendaPreviewService
    ) {}

    checkDocument(documentId: string) {
        console.log('checkDocument called with:', documentId);
        this.documentCheckSubject.next(documentId);
    }

    ngOnInit() {
        // Subscribe to the subject with a debounce time using an observer object
        this.documentCheckSubject.pipe(debounceTime(1000)).subscribe({
            next: documentId => {
                console.log('Checking document:', documentId);
                this.getDocumentFileCheck(documentId);
            },
            error: error => {
                console.error(
                    'Error in documentCheckSubject subscription:',
                    error
                );
            },
        });

        if (this.isAdmin) {
            this.displayedColumns.splice(2, 0, 'email', 'updateDate');
            this.displayedColumns.push('delete');
        }
        if (!this.isAdmin) {
            this.displayedColumns.push('rsvp');
            this.displayedColumns.push('help');
        }
    }

    ngOnDestroy(): void {
        if (this.thumbNails$) {
            this.thumbNails$.unsubscribe();
        }
    }

    async deleteDocument(event: MouseEvent, id: string) {
        event.stopPropagation();
        this.onDeleteDocument.emit(id);
    }

    //Get the thumbnail for this document
    async getDocumentFileCheck(documentId: string) {
        let connection: IDBDatabase | null = null;

        try {
            connection = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForViewer
            );
            var document = await this.localDocumentsService.getDocument(
                connection,
                documentId,
                true,
                true
            );

            if (document && document.file) return true;
        } catch (error: any) {
            throw error;
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection) {
                connection.close();
                connection = null;
            }
        }

        return false;
    }

    getDateFormatted(fileName: string) {
        return this.agendaPreviewService.getDateFormatted(fileName);
    }

    getFileName(fileName: string) {
        return this.agendaPreviewService.getFileName(fileName);
    }

    getEmail(fileName: string) {
        return this.agendaPreviewService.getEmail(
            fileName,
            this.committeeEmails
        );
    }

    viewDocument(docId: string, newTab = false) {
        this.fileViewerService.viewDocument(docId, newTab, false); //always view as a "Viewer"
    }

    getDataSource(docs: DocumentModel[]) {
        //sort is hardcoded by meeting date and meeting name
        this.dataSource.data = docs.sort(
            (a, b) =>
                this.agendaPreviewService.getDate(a.documentName).getTime() -
                    this.agendaPreviewService
                        .getDate(b.documentName)
                        .getTime() ||
                this.getFileName(a.documentName).localeCompare(
                    this.getFileName(b.documentName)
                )
        );
        return this.dataSource;
    }

    openMail(fileName: string) {
        const meeting = this.getFileName(fileName);
        const email = this.getEmail(fileName);
        const date = this.agendaPreviewService.getDateFormatted(fileName);
        window.open(
            `mailto:${email}?subject=RSVP: ${meeting} ${date}`,
            '_blank'
        );
    }
}

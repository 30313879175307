<div class="profile-header uk-flex uk-flex-middle">
	<mat-icon class="" aria-hidden="true">account_circle</mat-icon>
	<div *ngIf="user" class="profile-title uk-text-left">
		{{ user.firstName }} {{ user.lastName }}<br />
		{{ user.email }}
	</div>
</div>
<ul style="flex: 0 0 100%; background-color: #fff; padding-top: 10px; margin: -10px">
	<li class="uk-hidden@m" *ngIf="isAdmin == true">
		<a [routerLink]="['/admin/home']" routerLinkActive="active" class="uk-ddn-itm" role="menuitem">Dashboard</a>
	</li>
	<li class="uk-hidden@m" *ngIf="isAdmin == true">
		<a [routerLink]="['/admin/meetings']" routerLinkActive="active" (click)="reloadIfSameUrl('/admin/meetings')" class="uk-ddn-itm" role="menuitem"
			>View Meetings</a
		>
	</li>
	<li class="uk-hidden@m" *ngIf="isAdmin == true && isAgendaPreview">
		<a [routerLink]="['/admin/agendapreview']" routerLinkActive="active" role="menuitem" class="uk-ddn-itm agenda-preview">CPC Materials</a>
	</li>

	<!-- <li style="flex-shrink: 0;" role="none"><a [routerLink]="['/viewer/meetings']" routerLinkActive="active" role="menuitem" (click)="reloadIfSameUrl('/viewer/meetings')" class="uk-visible@s">All Meetings</a></li> -->

	<li class="uk-hidden@m" *ngIf="isAdmin == false">
		<a [routerLink]="['/viewer/meetings']" routerLinkActive="active" (click)="reloadIfSameUrl('/viewer/meetings')" class="uk-ddn-itm" role="menuitem"
			>View Meetings</a
		>
	</li>
	<li class="uk-hidden@m" *ngIf="isAdmin == false && isAgendaPreview">
		<a [routerLink]="['/viewer/agendapreview']" routerLinkActive="active" role="menuitem" class="uk-ddn-itm agenda-preview">CPC Materials</a>
	</li>

	<li *ngIf="isAdmin == true">
		<a class="uk-ddn-itm" role="menuitem" (click)="emitEventToOpenModal()" data-cy="lnkFeedback">Provide feedback for this page</a>
	</li>

	<li *ngIf="isAdmin == true">
		<a [routerLink]="['/admin/settings']" routerLinkActive="active" class="uk-ddn-itm" role="menuitem" data-cy="lnkSettings">Settings</a>
	</li>
	<li *ngIf="isAdmin == false">
		<a [routerLink]="['/viewer/settings']" routerLinkActive="active" class="uk-ddn-itm" role="menuitem" data-cy="lnkSettings">Settings</a>
	</li>

	<li>
		<a [routerLink]="['/help']" class="uk-ddn-itm" role="menuitem" routerLinkActive="active" data-cy="lnkSettings">Help Center</a>
	</li>
	<li>
		<a [routerLink]="['/signout']" class="uk-ddn-itm" role="menuitem" routerLinkActive="active" data-cy="lnkSignOut">Sign Out</a>
	</li>
</ul>

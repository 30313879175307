import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

/* Examples of how to use the badge component in other components:

    1. Default behavior (without any properties set) will render like [ (i) ]:

        <app-badge></app-badge>

    2. Use as informational; will render like [ (i) Info ]:

        <app-badge kind="INFO"></app-badge>

    3. Use as informational with 'Customer' label; will render like [ (i) Custom ]:

        <app-badge kind="INFO" label="Custom"></app-badge>

    4. Use with no label (must explicitly set label to empty string):

        <app-badge [kind]="updatedBadgeKind" [label]="''"></app-badge>

*/

export enum BadgeKind {
	ERROR = 'error',
	INFO = 'info',
	SUCCESS = 'success',
	UPDATED = 'updated',
	WARN = 'warn',
}

interface ViewModel {
	icon: string;
	label: string;
	kind: BadgeKind;
}

@Component({
	selector: 'app-badge',
	templateUrl: './badge.component.html',
	styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnChanges {
	@Input() kind!: BadgeKind;
	@Input() label!: string;
	@Input() icon!: string;

	// 1. Create behavior subjects to hold the values of the properties
	//      so that we can transform the view model based on change to any of them
	private icon$ = new BehaviorSubject<string | undefined>(`info`);
	private kind$ = new BehaviorSubject<BadgeKind>(BadgeKind.INFO);
	private label$ = new BehaviorSubject<string | undefined>(undefined);

	// 2. Create an observable that emits the ViewModel based on the combined latest
	//      values of the behavior subjects
	viewModel$ = combineLatest([this.icon$, this.kind$, this.label$]).pipe(
		map(([icon, kind, label]) => {
			return {
				icon: this.getIcon(icon, kind),
				kind: kind ?? BadgeKind.INFO,
				label: this.getLabel(label, kind),
			} as ViewModel;
		})
	);

	// 3. Using simple change detection, update the observable values
	//      by emitting the value to its relative behavior subject
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['icon']) {
			this.icon$.next(this.icon);
		}
		if (changes['kind']) {
			this.kind$.next(this.kind);
		}
		if (changes['label']) {
			this.label$.next(this.label);
		}
	}

	private getIcon = (icon: string | undefined, kind: BadgeKind) => {
		if (icon) {
			return icon;
		}
		switch (kind) {
			case BadgeKind.UPDATED:
				return 'info';
			default:
				return 'info';
		}
	};

	private getLabel = (label: string | undefined, kind: BadgeKind) => {
		if (label || label === '') {
			return label;
		}
		switch (kind) {
			case BadgeKind.UPDATED:
				return 'Updated';
			default:
				return '';
		}
	};
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private config: any = {};

    constructor(private http: HttpClient) {
        var configKey = this.getKey();

        var configCached = localStorage.getItem(configKey);

        if (configCached != null) {
            console.log(
                `ConfigService loading config from ${configKey} localStorage; using environment.${environment.configName}.ts`,
                configCached
            );
            this.config = JSON.parse(configCached);
        } else {
            console.log(
                `ConfigService loading config from ${configKey} localStorage; using environment.${environment.configName}.ts`,
                configCached
            );
            this.loadConfig(configKey);
        }
    }

    getKey(): string {
        return `cache-${environment.configName}`;
    }

    getEnvironment(): string {
        return `environment.${environment.configName}.ts`;
    }

    get(key: string) {
        return this.config[key];
    }

    getAll() {
        var configKey = this.getKey();
        return localStorage.getItem(configKey);
    }

    loadConfig(key: string) {
        return new Promise((resolve, reject) => {
            try {
                this.config = environment.config;

                localStorage.removeItem(key);
                localStorage.setItem(key, JSON.stringify(this.config));
                this.validate();
                resolve(this.config);
            } catch (err) {
                reject(err);
            }
        });
    }

    validate() {
        // Perform validation for the 'url' property
        const url = this.config['url'];
        if (this.isNullOrEmpty(url)) {
            throw new Error(
                'ConfigService configuration setting {url} is empty or null and must have the app url. Example: https://dmx-web-prod.azurewebsites.net/'
            );
        }
    }

    private isNullOrEmpty(value: any): boolean {
        return value === null || value === undefined || value === '';
    }
}

import { EventEmitter, Injectable } from '@angular/core';
import { TaskPipeline } from '../../core/task-pipeline/task-pipeline';
import { VerifyIndexDbExistsTask } from './tasks/verify-index-db-task';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';
import { GetViewerDocumentsFromServerTask } from './tasks/get-viewer-documents-from-server-task';
import { DocumentsService } from '@ecom/ecom-app-generated/Services/DocumentsService';
import { GetViewerDocumentsFromIndexDbTask } from './tasks/get-viewer-documents-from-index-db-task';
import { TaskResult } from '../../core/task-pipeline/task-interface';
import { IWorkflow } from '../../core/workflow-interface';
import { AppWorkflowsService } from '../../core/app-workflow-service';
import { RemoveOldViewerDocumentsFromIndexDbTask } from './tasks/remove-old-viewer-documents-from-index-db';
import { AddUpdateViewerDocumentsInIndexDbTask } from './tasks/add-update-viewer-documents-in-index-db';
import { GetDocumentThumbnailsTask } from './tasks/get-document-thumbnails-task';
import { ConfigComponent } from '@ecom/pages/console/config/config.component';
import { ConfigService } from '@ecom/common/services/config.service';
import { HttpClient } from '@angular/common/http';
import { GetDocumentContentsTask } from './tasks/get-document-contents-task';
import { config, firstValueFrom } from 'rxjs';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentsWorkflowv2 implements IWorkflow {
    public workflow: string = 'Viewers document sync workflow';
    public repeatInterval: number = 30000;
    public status: 'Ready' | 'Running' = 'Ready';
    public onTaskCompleted: EventEmitter<TaskResult> =
        new EventEmitter<TaskResult>();

    pipeline: TaskPipeline = new TaskPipeline();

    constructor(
        private configService: ConfigService,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private localDocumentsService: LocalDocumentsService,
        private documentsService: DocumentsService
    ) {
        //Tag the completed task with the workflow it came from
        this.pipeline.onTaskCompleted(result => {
            result.workflow = this.workflow;
            this.onTaskCompleted.next(result);
        });

        //Add all the tasks for this workflow
        this.pipeline
            .addTask(new VerifyIndexDbExistsTask(localDocumentsService))
            .addTask(new GetViewerDocumentsFromServerTask(documentsService))
            .addTask(
                new GetViewerDocumentsFromIndexDbTask(localDocumentsService)
            )
            .addTask(
                new RemoveOldViewerDocumentsFromIndexDbTask(
                    localDocumentsService
                )
            )
            .addTask(
                new AddUpdateViewerDocumentsInIndexDbTask(localDocumentsService)
            )
            .addTask(
                new GetDocumentThumbnailsTask(
                    documentsService,
                    localDocumentsService
                )
            )

            //Document content
            .addTask(
                new GetDocumentContentsTask(
                    configService,
                    http,
                    authenticationService,
                    documentsService,
                    localDocumentsService
                )
            );
    }

    async run(): Promise<void> {
        try {
            const runId = AppWorkflowsService.generateRunId();
            this.status = 'Running';
            await this.pipeline.execute(runId);
            this.status = 'Ready';
        } catch (error) {
            this.status = 'Ready';
        }
    }
}

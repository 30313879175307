import { Constants } from '@ecom/common/helpers/constants';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';
import {
    ITask,
    TaskResult,
} from '@ecom/app-workflows/core/task-pipeline/task-interface';
import { DocumentsService } from '@ecom/ecom-app-generated/Services/DocumentsService';
import { DocumentModel } from '@ecom/ecom-app-generated/Models/DocumentModel';
import { ConfigService } from '@ecom/common/services/config.service';
import { HttpClient } from '@angular/common/http';
import { KeycloakUserModel } from '@ecom/common/authentication/models/keycloak-user-model';
import { filter, firstValueFrom } from 'rxjs';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';

export class GetDocumentContentsTask implements ITask {
    totalDataDownloaded = 0;
    totalDownloadTime = 0;
    totalDownloads = 0;
    maxDocumentsAtAnyOneTime = 5;
    partialResultCallback?: (result: TaskResult) => void;
    user: KeycloakUserModel | undefined;

    constructor(
        private configService: ConfigService,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private documentsService: DocumentsService,
        private localDocumentsService: LocalDocumentsService
    ) {}
    async execute(
        context: Map<string, any>,
        onPartialResult?: (result: TaskResult) => void
    ): Promise<TaskResult> {
        try {
            this.totalDataDownloaded = 0;
            this.totalDownloadTime = 0;
            this.totalDownloads = 0;

            this.user = await firstValueFrom(
                this.authenticationService
                    .currentUser()
                    .pipe(filter(user => user !== undefined))
            );

            //This task for each document sends a TaskResult (isPartial=true) with the document that it completed
            this.partialResultCallback = onPartialResult;

            //Ensure we close the connection
            var detailedMessageResult =
                await this.getDocumentContentsTask(context);

            return {
                success: true,
                message: `Get document contents task completed.`,
                data: detailedMessageResult,
                recordCount: this.totalDownloads,
            };
        } catch (error: any) {
            return {
                success: false,
                message: `Get document contents task  failed`,
                data: error.message,
            };
        }
    }

    async getDocumentContentsTask(context: Map<string, any>): Promise<string> {
        let connection: IDBDatabase | null = null;
        var detailedMessage = '';

        try {
            const startTime = performance.now();
            connection = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForViewer
            );
            var documentModels =
                await this.localDocumentsService.getAllDocumentsNotSyncedWithContent(
                    connection
                );
            var documentIds = await documentModels.map(
                documentModel => documentModel.documentId
            );

            if (documentIds.length === 0) {
                // No documents to download
                detailedMessage = 'All document contents in sync.';
                return detailedMessage;
            } else {
                await this.downloadDocuments(documentIds);
                const duration = Math.round(performance.now() - startTime); // Duration in milliseconds, rounded

                if (this.totalDownloadTime > 0) {
                    const aggregateSpeedMbps = (
                        this.totalDataDownloaded /
                        (1024 * 1024) /
                        (duration / 1000)
                    ).toFixed(2);
                    detailedMessage = `Downloaded ${this.totalDownloads} files, in ${(duration / 1000).toFixed(2)} seconds, total data: ${(this.totalDataDownloaded / (1024 * 1024)).toFixed(2)} MB, speed: ${aggregateSpeedMbps} Mbps`;
                    return detailedMessage;
                } else {
                    detailedMessage = `Downloaded ${this.totalDownloads} files, in ${(duration / 1000).toFixed(2)} seconds`;
                    return detailedMessage;
                }
            }
        } catch (error) {
            throw error;
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection) {
                connection.close();
                connection = null;
            }
        }
    }

    private async downloadContent(documentId: string): Promise<void> {
        const startTime = performance.now();
        let url = '';
        let response = null; // Declare the response variable here

        try {
            //Additional performance feature ask - so that we aren't downloading documents twice (once for viewer, and once for admin);
            var foundDocument =
                await this.checkAdminBucketForDocument(documentId);
            if (foundDocument) {
                const duration = Math.round(performance.now() - startTime);
                this.totalDownloads++;
                this.partialResultCallback?.({
                    success: true,
                    message: `Downloaded ${foundDocument!.documentName}`,
                    data: `Discovered in admin local storage`,
                    duration: duration,
                });
                return;
            }

            url = `${this.configService.get(Constants.Config.ConfigApiCoreBaseUrl)}/api/documents/GetFastDocumentBinaryById?id=${documentId}&downloadMetricsId=${documentId}`;
            response = await this.http
                .get(url, { responseType: 'blob', observe: 'response' })
                .toPromise();
            const duration = Math.round(performance.now() - startTime); // Duration in milliseconds, rounded

            if (response && response.status === 200 && response.body) {
                //Get the encrypted key for the document
                const fileKey = response.headers.get('X-File-Key');

                // Check if fileKey is null, undefined, an empty string, or has no length
                if (
                    fileKey === null ||
                    fileKey === undefined ||
                    fileKey === '' ||
                    fileKey.length === 0
                ) {
                    throw Error(
                        'Document key was not retrieved from request header, as expected.'
                    );
                }

                var base64Data = await this.readBlobAsBase64(response.body);
                if (base64Data) {
                    let connection: IDBDatabase | null = null;

                    try {
                        connection =
                            await this.localDocumentsService.openConnection(
                                Constants.LocalStorage.DocumentsForViewer
                            );
                        var document =
                            await this.localDocumentsService.getDocument(
                                connection,
                                documentId,
                                false,
                                true
                            );
                        if (!document)
                            throw Error(
                                'Document meta data could not be found in local storage.'
                            );

                        document.fileKey = fileKey;
                        await this.localDocumentsService.addUpdateDocument(
                            connection,
                            document
                        ); //update document meta data with key
                        await this.localDocumentsService.addUpdateContent(
                            connection,
                            documentId,
                            base64Data
                        ); //update document content

                        //Update total data downloaded and total download time
                        this.totalDataDownloaded += document!.fileSize;
                        this.totalDownloadTime += duration;
                        this.totalDownloads++;
                        const fileSizeMB = (
                            document!.fileSize /
                            (1024 * 1024)
                        ).toFixed(3); // File size in MB, rounded to 2 decimal places
                        const speedMbps = (
                            document!.fileSize /
                            (1024 * 1024) /
                            (duration / 1000)
                        ).toFixed(2); // Speed in Mbps for this file, rounded to 2 decimal places

                        this.partialResultCallback?.({
                            success: true, // Or false, depending on the operation result
                            message: `Downloaded ${document!.documentName}`,
                            data: `file size: ${fileSizeMB} MB, speed: ${speedMbps} Mbps`,
                            duration: duration,
                        });
                    } catch (error: any) {
                        throw error;
                    } finally {
                        //clear variables immediately for fastest garbage collection; yes important
                        base64Data = null;
                        response = null;
                        document = null;

                        //ensure we clean up connection always to avoid memory leaks
                        if (connection) {
                            connection.close();
                            connection = null;
                        }
                    }
                }
            } else {
                // Handle non-success responses
                this.partialResultCallback?.({
                    success: false,
                    message: `The document could not be downloaded from the server. Status: ${response!.status}`,
                    data: `document ID: ${documentId}`, // Some relevant data
                });
            }
        } catch (error: any) {
            const duration = Math.round(performance.now() - startTime);
            // Handle non-success responses
            this.partialResultCallback?.({
                success: false,
                message: `Downloading document {documentId: ${documentId}}`,
                data: error.message,
            });
        }
    }

    private async readBlobAsBase64(blob: Blob): Promise<string | null> {
        return new Promise((resolve, reject) => {
            let reader: FileReader | null = new FileReader(); // Use let for block scope
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64Data = reader!.result
                    ? reader!.result.toString().split(',')[1]
                    : null;
                resolve(base64Data);
                reader = null; // Attempting to nullify reader here might still raise issues
            };
            reader.onerror = error => {
                reject(new Error('Failed to read blob as base64'));
                reader = null; // Same issue as above
            };
        });
    }

    private async asyncPool(
        poolLimit: number,
        array: any[],
        iteratorFn: (value: any) => Promise<void>
    ): Promise<void[]> {
        const ret = [];
        const executing: any[] = [];
        for (const item of array) {
            const p = Promise.resolve().then(() => iteratorFn(item));
            ret.push(p);

            if (poolLimit <= array.length) {
                const e = p.then(() =>
                    executing.splice(executing.indexOf(e), 1)
                ) as Promise<void>;
                executing.push(e);
                if (executing.length >= poolLimit) {
                    await Promise.race(executing);
                }
            }
        }
        return Promise.all(ret);
    }

    private async downloadDocuments(documents: string[]): Promise<void> {
        await this.asyncPool(
            this.maxDocumentsAtAnyOneTime,
            documents,
            document => this.downloadContent(document)
        );
    }

    private async checkAdminBucketForDocument(
        documentId: string
    ): Promise<DocumentModel | null> {
        //Do the check for admin just to save this from being executed from efficiency standpoint
        //As a pure viewer won't have an admin localstorage anyways, so the check technically isn't necessary
        if (!this.user?.userRoles.includes('dmx-admin')) return null;

        let connection1: IDBDatabase | null = null;
        let connection2: IDBDatabase | null = null;
        try {
            connection1 = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForAdmin
            );
            const document = await this.localDocumentsService.getDocument(
                connection1,
                documentId,
                true,
                true
            );

            if (document && document.file) {
                connection2 = await this.localDocumentsService.openConnection(
                    Constants.LocalStorage.DocumentsForViewer
                );
                const documentFromViewer =
                    await this.localDocumentsService.getDocument(
                        connection2,
                        documentId,
                        false,
                        false
                    );

                //Now that we know document exists in admins local storage and content for this file exists in the admin local storage, lets ensure it's the same version by just grabbing the metadata
                if (
                    documentFromViewer &&
                    documentFromViewer.updateDate == document.updateDate
                ) {
                    await this.localDocumentsService.addUpdateContent(
                        connection2,
                        document.documentId,
                        document.file
                    );
                    await this.localDocumentsService.addUpdateThumbnail(
                        connection2,
                        document.documentId,
                        document.thumbnail
                    );

                    //when storing DocumentModel in metadata we never store the content/file and/or thumbnail
                    document.file = '';
                    document.thumbnail = '';
                    await this.localDocumentsService.addUpdateDocument(
                        connection2,
                        document
                    );
                    return document;
                }
            }
        } catch (error: any) {
            throw error;
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection1) {
                connection1.close();
                connection1 = null;
            }

            if (connection2) {
                connection2.close();
                connection2 = null;
            }
        }

        return null;
    }
}

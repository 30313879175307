import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
    //Routes are in individual modules, please add routes at module level
    //Example A: A viewer route would go in src/app/modules/viewer-features.module.ts
    //Example B: An admin route would go in src/app/modules/admin-features.module.ts

    {
        path: '',
        loadChildren: () =>
            import('./modules/dmx-core/dmx-core.module').then(
                m => m.DmxCoreModule
            ),
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./modules/admin-features/admin-features.module').then(
                m => m.AdminFeaturesModule
            ),
    },
    {
        path: 'viewer',
        loadChildren: () =>
            import('./modules/viewer-features/viewer-features.module').then(
                m => m.ViewerFeaturesModule
            ),
    },
    {
        path: 'files',
        loadChildren: () =>
            import('./modules/files-features/files-feature.module').then(
                m => m.FilesFeatureModule
            ),
    },
    {
        path: 'console',
        loadChildren: () =>
            import('./modules/console-features/console-features.module').then(
                m => m.ConsoleFeaturesModule
            ),
    },
    {
        path: 'sandbox',
        loadChildren: () =>
            import('./modules/sandbox-features/sandbox-features.module').then(
                m => m.SandboxFeaturesModule
            ),
    },

    //PageNotFound last in the order - resolve if not match
    { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}

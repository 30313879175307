import { EventEmitter, Injectable } from '@angular/core';
import { TaskPipeline } from '../../core/task-pipeline/task-pipeline';
import { VerifyAdminIndexDbExistsTask } from './tasks/verify-admin-index-db-task';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';
import { DocumentsService } from '@ecom/ecom-app-generated/Services/DocumentsService';
import { GetAdminDocumentsFromIndexDbTask } from './tasks/get-admin-documents-from-index-db-task';
import { TaskResult } from '../../core/task-pipeline/task-interface';
import { IWorkflow } from '../../core/workflow-interface';
import { AppWorkflowsService } from '../../core/app-workflow-service';
import { RemoveOldAdminDocumentsFromIndexDbTask } from './tasks/remove-old-admin-documents-from-index-db';
import { AddUpdateAdminDocumentsInIndexDbTask } from './tasks/add-update-admin-documents-in-index-db';
import { GetAdminDocumentThumbnailsTask } from './tasks/get-document-thumbnails-task';
import { ConfigService } from '@ecom/common/services/config.service';
import { HttpClient } from '@angular/common/http';
import { GetAdminDocumentContentsTask } from './tasks/get-document-contents-task';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';
import { GetAdminDocumentsFromServerTask } from './tasks/get-admin-documents/get-admin-documents-from-server-task';

@Injectable({
    providedIn: 'root',
})
export class AdminDocumentsWorkflowv2 implements IWorkflow {
    public workflow: string = 'Admin document sync workflow';
    public repeatInterval: number = 30000;
    public status: 'Ready' | 'Running' = 'Ready';
    public onTaskCompleted: EventEmitter<TaskResult> =
        new EventEmitter<TaskResult>();

    pipeline: TaskPipeline = new TaskPipeline();

    constructor(
        private configService: ConfigService,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private localDocumentsService: LocalDocumentsService,
        private documentsService: DocumentsService
    ) {
        //Tag the completed task with the workflow it came from
        this.pipeline.onTaskCompleted(result => {
            result.workflow = this.workflow;
            this.onTaskCompleted.next(result);
        });

        //Add all the tasks for this workflow
        this.pipeline
            .addTask(new VerifyAdminIndexDbExistsTask(localDocumentsService))

            //get admin documents entries for specified groupings - so we can organize/prioritize what we download first
            .addTask(new GetAdminDocumentsFromServerTask(documentsService))
            .addTask(
                new GetAdminDocumentsFromIndexDbTask(localDocumentsService)
            )
            .addTask(
                new RemoveOldAdminDocumentsFromIndexDbTask(
                    localDocumentsService
                )
            )
            .addTask(
                new AddUpdateAdminDocumentsInIndexDbTask(localDocumentsService)
            )
            .addTask(
                new GetAdminDocumentThumbnailsTask(
                    documentsService,
                    localDocumentsService
                )
            )

            //now we download the document content for specific groups in this order; as we wouldn't want to download 1000 expired documents first
            .addTask(
                new GetAdminDocumentContentsTask(
                    configService,
                    http,
                    authenticationService,
                    documentsService,
                    localDocumentsService
                )
            );

        //Expired documents
        //.addTask(new GetAdminDocumentContentsTask(configService, http, authenticationService, documentsService, localDocumentsService, 'Expired'))
    }

    async run(): Promise<void> {
        try {
            const runId = AppWorkflowsService.generateRunId();
            this.status = 'Running';
            await this.pipeline.execute(runId);
            this.status = 'Ready';
        } catch (error) {
            this.status = 'Ready';
        }
    }
}

<div style="height: 100vh" uk-grid>
    <div class="uk-width-expand@m">
        <div>
            <app-layout-header headerType="public"></app-layout-header>
        </div>
        <div class="uk-padding-small">
            <div>
                <h2>Logged Out</h2>
            </div>
            <div>
                <p>
                    You have been successfully logged out. Thank you for using
                    our platform. To sign back in, please click
                    <a href="/">here</a>.
                </p>
            </div>
        </div>
    </div>
</div>

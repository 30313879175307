
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';
 
import { IdRequestModel } from '../Models/IdRequestModel'
import { MeetingLocationModel } from '../Models/MeetingLocationModel'

@Injectable({
     providedIn: 'root'
 })
export class MeetingLocationsService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'meetinglocations');
    } 

  //get
  public getByMeetingId(model: IdRequestModel) : Promise<ServiceResult<MeetingLocationModel[]>> {
      return this.getWithQuery<ServiceResult<MeetingLocationModel[]>,IdRequestModel>('GetByMeetingId',model);
  };
  
  
  public getAll() : Promise<ServiceResult<MeetingLocationModel[]>> {
      return this.get<ServiceResult<MeetingLocationModel[]>>('GetAll');
  };
}
export interface ObjectStoreConfig {
    name: string;
    model: any;
    keyPath?: string | null;
}

export interface IndexDbConfig {
    dbName: string;
    version: number;
    objectStores: ObjectStoreConfig[];
}

/**
 * The LocalStorageService class provides a foundational framework for interacting with IndexedDB databases.
 * This class includes a suite of protected methods that facilitate the creation, opening, and manipulation
 * of data within IndexedDB object stores. It is designed to be used as a base class, allowing developers
 * to extend and customize IndexedDB operations according to their specific requirements.
 *
 * Key Features:
 * - Database Creation and Opening: Methods to create a new IndexedDB database or open an existing one,
 *   with validation checks and version management.
 * - Data Manipulation: Includes add, update, delete, and retrieval operations, supporting both in-line
 *   and out-of-line key configurations.
 * - Efficient Data Access: Methods to retrieve all data items or all keys from an object store,
 *   optimizing performance and memory usage.
 *
 * Usage Guidelines:
 * - This class should be extended to create a more specific IndexedDB service tailored to your application's needs.
 * - The protected methods ensure that derived classes have access to core IndexedDB functionality while
 *   allowing you to implement additional features and logic.
 * - Remember to handle large datasets efficiently, especially when using methods like `getAll`, to avoid
 *   performance issues.
 *
 * Example of Extending:
 * ```
 * class MyIndexedDbService extends LocalStorageService {
 *     // Custom methods and properties for your specific use case
 * }
 * ```
 *
 * This approach enables the encapsulation of IndexedDB operations within a service layer, providing a clean
 * and reusable interface for database interactions throughout your application.
 */
export class LocalStorageService {
    private db: IDBDatabase | null = null;

    constructor() {}

    /**
     * Creates or updates an IndexedDB database based on the provided configuration.
     *
     * - Validates the provided database configuration, ensuring the database name is not empty
     *   and the version number is a positive integer.
     * - Opens an IndexedDB database with the specified name and version. If the database does
     *   not exist, it will be created.
     * - Handles the 'onupgradeneeded' event to create object stores based on the configuration
     *   if they do not already exist in the database.
     *
     * @param {IndexDbConfig} config The configuration object for the database, which includes
     *                               the database name, version, and the configurations for
     *                               the object stores to be created or accessed.
     * @returns {Promise<void>} A promise that resolves when the database is successfully created
     *                          or updated, or rejects if an error occurs during the process.
     *
     * Usage Example:
     *   const dbConfig = { dbName: 'myDB', version: 1, objectStores: [...] };
     *   localStorageService.create(dbConfig)
     *       .then(() => console.log('Database created/updated successfully'))
     *       .catch(error => console.error('Error creating/updating database:', error));
     */
    protected async create(config: IndexDbConfig): Promise<void> {
        return new Promise((resolve, reject) => {
            // Validation checks
            if (!config.dbName || config.dbName.trim() === '') {
                throw new Error(
                    'Database name is required and cannot be empty.'
                );
            }
            if (!config.version || config.version <= 0) {
                throw new Error('Database version must be a positive integer.');
            }

            const request = indexedDB.open(config.dbName, config.version); // You can manage the version number as needed

            request.onupgradeneeded = event => {
                const db: IDBDatabase = request.result;

                // Create each object store based on the provided configuration
                config.objectStores.forEach(storeConfig => {
                    if (!db.objectStoreNames.contains(storeConfig.name)) {
                        db.createObjectStore(storeConfig.name, {
                            keyPath: storeConfig.keyPath,
                        });
                    }
                });
            };

            request.onsuccess = event => {
                console.log('Database created/updated successfully');
                resolve();
            };

            request.onerror = event => {
                console.error(
                    'Error creating/updating database:',
                    request.error
                );
                reject(request.error);
            };
        });
    }

    /**
     * Opens an existing IndexedDB database with the specified name.
     *
     * - Attempts to open the database with the given name. If the database does not
     *   exist, the 'onupgradeneeded' event is triggered, indicating the need to create
     *   the database first.
     * - Verifies that the opened database version matches or exceeds the expected version.
     *   This is crucial because IndexedDB, by design, creates a database with version 0 if
     *   it does not exist. Ensuring the version is correct helps avoid unintended consequences
     *   and data structure inconsistencies.
     * - Resolves with the IDBDatabase instance if the database is successfully opened and the
     *   version check passes.
     * - Rejects with an error if the database cannot be opened, does not exist, or if the
     *   version check fails.
     *
     * @param {string} dbName The name of the database to open.
     * @param {number} expectedVersion The expected version number of the database, used to
     *                                 ensure the opened database is up-to-date.
     * @returns {Promise<IDBDatabase>} A promise that resolves with the opened IDBDatabase
     *                                 instance, or rejects with an error if the database
     *                                 cannot be opened or the version check fails.
     *
     * Usage Example:
     *   localStorageService.open('myDB', 1)
     *       .then(db => console.log('Database opened successfully', db))
     *       .catch(error => console.error('Error opening database:', error));
     */

    protected async open(
        dbName: string,
        expectedVersion: number
    ): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(dbName);

            request.onsuccess = event => {
                const db: IDBDatabase = request.result;

                if (db.version < expectedVersion) {
                    console.error(
                        `Database exists with version ${db.version} but expected version is ${expectedVersion}.`
                    );
                    reject(
                        new Error(
                            `Database exists with version ${db.version} but expected version is ${expectedVersion}.`
                        )
                    );
                    return;
                }

                //console.log(`Database opened successfully with version ${db.version}`);
                resolve(db);
            };

            request.onerror = event => {
                console.error(
                    `Error opening database with version ${expectedVersion}:`,
                    request.error
                );
                reject(request.error); // Reject the promise
            };

            request.onupgradeneeded = event => {
                console.error(
                    `Database not found. Expected version ${expectedVersion}. Please create the database before opening it.`
                );
                reject(
                    new Error(
                        `Database not found. Expected version ${expectedVersion}. Please create the database before opening it.`
                    )
                ); // Reject the promise
            };
        });
    }

    /**
     * Adds or updates data in the specified object store of the provided IndexedDB database.
     *
     * This method intelligently handles both in-line and out-of-line key configurations:
     * - For in-line keys (where the key is part of the data object), it uses the `put` method
     *   without a separate key parameter.
     * - For out-of-line keys (where the key is separate from the data object), it includes the
     *   key as a second argument in the `put` method.
     *
     * This flexible approach allows the method to be compatible with different object store
     * configurations.
     *
     * @param {IDBDatabase} db The IndexedDB database instance where the operation will be performed.
     * @param {string} storeName The name of the object store where the data will be added or updated.
     * @param {any} key The key for the data item being added or updated. This is used only for
     *                  out-of-line key configurations.
     * @param {T} data The data object to be added or updated in the object store.
     * @returns {Promise<void>} A promise that resolves when the operation is successful, or rejects
     *                          with an error if the operation fails.
     *
     * Usage Example:
     *   localStorageService.addupdate(db, 'myStore', 'myKey', { prop: 'value' })
     *       .then(() => console.log('Data added/updated successfully'))
     *       .catch(error => console.error('Error updating data:', error));
     */
    protected async addupdate<T>(
        db: IDBDatabase,
        storeName: string,
        key: any,
        data: T
    ): Promise<void> {
        try {
            const transaction = db.transaction(storeName, 'readwrite');
            const objectStore = transaction.objectStore(storeName);
            let request: any;

            // Check if the object store uses in-line keys
            if (objectStore.keyPath) {
                // For in-line keys, do not provide a separate key
                request = objectStore.put(data);
            } else {
                // For out-of-line keys, provide the key as a second argument
                request = objectStore.put(data, key);
            }

            return new Promise((resolve, reject) => {
                request.onsuccess = () => resolve();
                request.onerror = () => reject(request.error);
            });
        } catch (error) {
            console.error('Error updating data:', error);
            throw error; // Rethrow the error to be handled by the caller
        }
    }

    /**
     * Deletes a data item from a specified object store in an IndexedDB database.
     *
     * This method performs a deletion operation based on the provided key. It is essential
     * for managing data within the database, allowing for the removal of specific entries.
     *
     * @param {IDBDatabase} db The IndexedDB database instance where the operation will be performed.
     * @param {string} storeName The name of the object store from which the data item will be deleted.
     * @param {any} key The key corresponding to the data item to be deleted. The key must match the
     *                  key of the item in the object store.
     * @returns {Promise<void>} A promise that resolves when the deletion is successful, or rejects
     *                          with an error if the deletion operation fails.
     *
     * This method is useful for maintaining the integrity and relevance of the data stored in the
     * database by allowing obsolete or unnecessary data to be removed efficiently.
     *
     * Usage Example:
     *   localStorageService.delete(db, 'myStore', 'myKey')
     *       .then(() => console.log('Data deleted successfully'))
     *       .catch(error => console.error('Error deleting data:', error));
     */
    protected async delete(
        db: IDBDatabase,
        storeName: string,
        key: any
    ): Promise<void> {
        try {
            const transaction = db.transaction(storeName, 'readwrite');
            const objectStore = transaction.objectStore(storeName);
            const request = objectStore.delete(key);

            return new Promise((resolve, reject) => {
                request.onsuccess = () => resolve();
                request.onerror = () => reject(request.error);
            });
        } catch (error) {
            console.error('Error deleting data:', error);
            throw error; // Rethrow the error to be handled by the caller
        }
    }

    /**
     * Retrieves a specific data item from a given object store in an IndexedDB database.
     *
     * This method is designed to fetch a single data item based on its key. It's essential for
     * accessing individual entries within the database, providing a way to retrieve data as needed.
     *
     * @param {IDBDatabase} db The IndexedDB database instance from which to fetch the data.
     * @param {string} storeName The name of the object store where the desired data item is located.
     * @param {any} key The key corresponding to the data item being retrieved. This key should
     *                  uniquely identify the item within the object store.
     * @returns {Promise<T | undefined>} A promise that resolves with the retrieved data item if found,
     *                                   or undefined if no item matches the provided key. The promise
     *                                   rejects with an error if the operation fails.
     *
     * This method is useful for selectively accessing data items, particularly when only a single
     * record is required, rather than fetching an entire dataset.
     *
     * Usage Example:
     *   localStorageService.get<MyDataType>(db, 'myStore', 'myKey')
     *       .then(dataItem => {
     *           if (dataItem) {
     *               console.log('Data retrieved successfully', dataItem);
     *           } else {
     *               console.log('No data found for the provided key');
     *           }
     *       })
     *       .catch(error => console.error('Error fetching data:', error));
     */
    protected async get<T>(
        db: IDBDatabase,
        storeName: string,
        key: any
    ): Promise<T | undefined> {
        try {
            const transaction = db.transaction(storeName, 'readonly');
            const objectStore = transaction.objectStore(storeName);
            const request = objectStore.get(key);

            return new Promise((resolve, reject) => {
                request.onsuccess = () => {
                    // Resolve with the result, or undefined if not found
                    resolve(request.result);
                };
                request.onerror = () => {
                    console.error(
                        `Error fetching data from store '${storeName}':`,
                        request.error
                    );
                    reject(request.error);
                };
            });
        } catch (error) {
            console.error(
                `Error fetching data from store '${storeName}':`,
                error
            );
            throw error; // Rethrow the error to be handled by the caller
        }
    }

    /**
     * Retrieves all data items from a specified object store in an IndexedDB database.
     *
     * This method is ideal for scenarios where you need to access all records stored within a
     * specific object store. It uses the `getAll` method of the IndexedDB API, which efficiently
     * fetches the entire dataset from the specified store.
     *
     * @param {IDBDatabase} db The IndexedDB database instance from which to retrieve the data.
     * @param {string} storeName The name of the object store from which to fetch all data items.
     * @returns {Promise<T[]>} A promise that resolves with an array of all data items in the
     *                         specified object store. If the store is empty, it resolves to an
     *                         empty array. The promise rejects with an error if the operation
     *                         fails.
     *
     * This method is particularly useful for operations that require analysis or manipulation of
     * the entire dataset, such as data exports, reporting, or bulk updates.
     *
     * IMPORTANT: Exercise caution when storing large data sets within model properties in an object
     * store. This is particularly crucial if you frequently use the `getAll` operation on the store.
     * Since `getAll` loads every key-value pair into memory, storing substantial amounts of data in
     * model properties can significantly increase memory usage. This will spike in memory and impact
     * performance. To mitigate this, consider segregating large content into a separate object store.
     * Use the same identification scheme (such as the same ID) for both the model and its corresponding
     * large content in the separate store. This approach helps in efficient data management and retrieval,
     * while keeping memory usage optimized.
     *
     * Usage Example:
     *   localStorageService.getAll<MyDataType>(db, 'myStore')
     *       .then(allDataItems => {
     *           console.log('All data items retrieved successfully', allDataItems);
     *       })
     *       .catch(error => console.error('Error fetching all data:', error));
     */
    protected async getAll<T>(
        db: IDBDatabase,
        storeName: string
    ): Promise<T[]> {
        try {
            const transaction = db.transaction(storeName, 'readonly');
            const objectStore = transaction.objectStore(storeName);
            const request = objectStore.getAll(); // Using the getAll() method of the object store

            return new Promise((resolve, reject) => {
                request.onsuccess = () => {
                    resolve(request.result); // Resolve with all the entries
                };
                request.onerror = () => {
                    console.error(
                        `Error fetching all data from store '${storeName}':`,
                        request.error
                    );
                    reject(request.error);
                };
            });
        } catch (error) {
            console.error(
                `Error fetching all data from store '${storeName}':`,
                error
            );
            throw error; // Rethrow the error to be handled by the caller
        }
    }

    /**
     * Retrieves all keys from a specified object store in an IndexedDB database.
     *
     * This method is useful when you need a list of all keys in an object store without
     * loading the corresponding data. It leverages the `getAllKeys` method of the IndexedDB API,
     * which efficiently fetches just the keys, thereby reducing memory usage and improving
     * performance compared to retrieving full data items.
     *
     * @param {IDBDatabase} db The IndexedDB database instance from which to retrieve the keys.
     * @param {string} storeName The name of the object store from which to fetch all keys.
     * @returns {Promise<IDBValidKey[]>} A promise that resolves with an array of keys from the
     *                                   specified object store. The promise rejects with an error
     *                                   if the operation fails.
     *
     * This method is particularly beneficial in scenarios where you need to know the existence
     * of records or to iterate over them without the need for the actual data, such as for
     * generating indexes, references, or summaries.
     *
     * Usage Example:
     *   localStorageService.getAllKeys(db, 'myStore')
     *       .then(allKeys => {
     *           console.log('All keys retrieved successfully', allKeys);
     *       })
     *       .catch(error => console.error('Error fetching all keys:', error));
     */
    protected async getAllKeys(
        db: IDBDatabase,
        storeName: string
    ): Promise<IDBValidKey[]> {
        try {
            const transaction = db.transaction(storeName, 'readonly');
            const store = transaction.objectStore(storeName);

            return new Promise((resolve, reject) => {
                const request = store.getAllKeys();

                request.onsuccess = function () {
                    resolve(request.result); // Use IDBValidKey[] as the return type
                };

                request.onerror = function () {
                    reject(request.error); // Rejects on error
                };
            });
        } catch (error) {
            console.error(
                `Error fetching all keys from store '${storeName}':`,
                error
            );
            throw error; // Rethrow the error to be handled by the caller
        }
    }
}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';
 
import { GetAllMeetingsRequestModel } from '../Models/GetAllMeetingsRequestModel'
import { MeetingModel } from '../Models/MeetingModel' 
import { GetMeetingsRequestModel } from '../Models/GetMeetingsRequestModel' 
import { IdRequestModel } from '../Models/IdRequestModel'
import { MeetingPackageModel } from '../Models/MeetingPackageModel'
import { MeetingWizardModel } from '../Models/MeetingWizardModel'
import { MeetingParticipantModel } from '../Models/MeetingParticipantModel' 
import { AddMeetingRequestModel } from '../Models/AddMeetingRequestModel' 
import { UpdateMeetingRequestModel } from '../Models/UpdateMeetingRequestModel'
import { IdResponseModel } from '../Models/IdResponseModel' 
import { ExtendExpiryDateRequestModel } from '../Models/ExtendExpiryDateRequestModel'
import { MeetingAgendaAsMenuResponseModel } from '../Models/MeetingAgendaAsMenuResponseModel'
import { ExpiredMeetingModel } from '../Models/ExpiredMeetingModel'

@Injectable({
     providedIn: 'root'
 })
export class MeetingsService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'meetings');
    } 

  //get
  public getAdminMeetings(model: GetAllMeetingsRequestModel) : Promise<ServiceResult<MeetingModel[]>> {
      return this.getWithQuery<ServiceResult<MeetingModel[]>,GetAllMeetingsRequestModel>('GetAdminMeetings',model);
  };
  
  //get
  public getViewerMeetings(model: GetMeetingsRequestModel) : Promise<ServiceResult<MeetingModel[]>> {
      return this.getWithQuery<ServiceResult<MeetingModel[]>,GetMeetingsRequestModel>('GetViewerMeetings',model);
  };
  
  
  public getPreviewMeeting() : Promise<ServiceResult<MeetingModel>> {
      return this.get<ServiceResult<MeetingModel>>('GetPreviewMeeting');
  };
  
  public getAdminPreviewMeeting() : Promise<ServiceResult<MeetingModel>> {
      return this.get<ServiceResult<MeetingModel>>('GetAdminPreviewMeeting');
  };
  //get
  public getViewerOfflineMeetingJsonByMeetingId(model: IdRequestModel) : Promise<ServiceResult<MeetingPackageModel>> {
      return this.getWithQuery<ServiceResult<MeetingPackageModel>,IdRequestModel>('GetViewerOfflineMeetingJsonByMeetingId',model);
  };
  
  //get
  public getMeetingById(model: IdRequestModel) : Promise<ServiceResult<MeetingModel>> {
      return this.getWithQuery<ServiceResult<MeetingModel>,IdRequestModel>('GetMeetingById',model);
  };
  
  //get
  public getWizardMeetingById(model: IdRequestModel) : Promise<ServiceResult<MeetingWizardModel>> {
      return this.getWithQuery<ServiceResult<MeetingWizardModel>,IdRequestModel>('GetWizardMeetingById',model);
  };
  
  //get
  public getMeetingParticipants(model: IdRequestModel) : Promise<ServiceResult<MeetingParticipantModel[]>> {
      return this.getWithQuery<ServiceResult<MeetingParticipantModel[]>,IdRequestModel>('GetMeetingParticipants',model);
  };
  
  
  //post
  public addMeeting(model: AddMeetingRequestModel) : Promise<ServiceResult<MeetingModel>> {
      return this.post<ServiceResult<MeetingModel>,AddMeetingRequestModel>('AddMeeting',model);
  };
  
  //post
  public updateMeeting(model: UpdateMeetingRequestModel) : Promise<ServiceResult<MeetingModel>> {
      return this.post<ServiceResult<MeetingModel>,UpdateMeetingRequestModel>('UpdateMeeting',model);
  };
  
  //post
  public publishMeeting(model: IdRequestModel) : Promise<ServiceResult<MeetingModel>> {
      return this.post<ServiceResult<MeetingModel>,IdRequestModel>('PublishMeeting',model);
  };
  
  //post
  public unpublishMeeting(model: IdRequestModel) : Promise<ServiceResult<MeetingModel>> {
      return this.post<ServiceResult<MeetingModel>,IdRequestModel>('UnpublishMeeting',model);
  };
  
  //post
  public deleteMeeting(model: IdRequestModel) : Promise<ServiceResult<IdResponseModel>> {
      return this.post<ServiceResult<IdResponseModel>,IdRequestModel>('DeleteMeeting',model);
  };
  
  //post
  public extendMeetingExpiryDate(model: ExtendExpiryDateRequestModel) : Promise<ServiceResult<IdResponseModel>> {
      return this.post<ServiceResult<IdResponseModel>,ExtendExpiryDateRequestModel>('ExtendMeetingExpiryDate',model);
  };
  
  //post
  public addMeetingFromIts(model: IdRequestModel) : Promise<ServiceResult<MeetingModel>> {
      return this.post<ServiceResult<MeetingModel>,IdRequestModel>('AddMeetingFromIts',model);
  };
  //get
  public getMeetingAgendaAsMenu(model: IdRequestModel) : Promise<ServiceResult<MeetingAgendaAsMenuResponseModel>> {
      return this.getWithQuery<ServiceResult<MeetingAgendaAsMenuResponseModel>,IdRequestModel>('GetMeetingAgendaAsMenu',model);
  };
  
  //get
  public getExpiredMeetingById(model: IdRequestModel) : Promise<ServiceResult<ExpiredMeetingModel>> {
      return this.getWithQuery<ServiceResult<ExpiredMeetingModel>,IdRequestModel>('GetExpiredMeetingById',model);
  };
  
}
<div uk-grid>
    <div class="uk-width-expand@m">
        <app-layout-header headerType="secure"></app-layout-header>
        <div class="uk-padding">
            <div>
                <a [routerLink]="['/help']">Back to Help Center</a>
                <br /><br />
                <h2>Frequently Asked Questions</h2>
                <!-- Help Center Paragraph -->
                <div>
                    <p>
                        Please see below for answers to frequently asked
                        questions about DMX. If you need further assistance,
                        please reach out to
                        <a href="mailto: dmx-support&#64;gov.ab.ca"
                            >dmx-support&#64;gov.ab.ca.</a
                        >
                    </p>
                </div>
                <div class="help-center">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Why do I see 'No permissions assigned' error
                                    when viewing my meetings?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                The 'No permissions assigned' error typically
                                indicates an access issue within the DMX system.
                                It's possible that you have not been granted the
                                necessary permissions to view certain meetings
                                or content. If you believe you are indeed
                                authorized and should have access, please don't
                                hesitate to reach out for assistance. Contact
                                our support team at
                                <a href="mailto:dmx-support&#64;gov.ab.ca"
                                    >dmx-support&#64;gov.ab.ca</a
                                >
                                for troubleshooting and resolution. We're here
                                to help ensure you have the access you need.
                            </p>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Why do I not see any documents in my
                                    meeting?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                DMX operates with a background service that
                                syncs documents every 30 seconds. If you've just
                                logged in, it's possible that the system is in
                                the process of syncing documents to your local
                                device. To expedite access to specific
                                documents, DMX offers a "priority download"
                                feature. Within a meeting, you can indicate
                                which document you'd like to download
                                immediately and view. However, if you've waited
                                a reasonable amount of time and still don't see
                                the expected documents, or if you suspect
                                there's an error, please reach out to our
                                support team for technical troubleshooting.
                                Contact our support team at
                                <a href="mailto:dmx-support&#64;gov.ab.ca"
                                    >dmx-support&#64;gov.ab.ca</a
                                >
                                for troubleshooting and resolution.
                            </p>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Why am I receiving an error message when
                                    uploading a file to an agenda item?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                Ensure that the file name does not have any
                                unnecessary characters or symbols which will
                                render the file incompatible with DMX. This can
                                include symbols or extra periods in the name of
                                the file.
                            </p>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Why can I not access DMX?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                Ensure you are typing in the correct name in the
                                browser address bar. The correct address is
                                dmx.gov.ab.ca. There is no “www” at the
                                beginning of the address.
                            </p>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    After uploading a file to DMX the file name
                                    was changed, why is this happening?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                This is an indication that your source file path
                                is too long. If a file is saved under several
                                sub-folders in your file source (e.g. desktop or
                                SharePoint) this can create a lengthy file path.
                                This will result in an automatic shortening or
                                renaming of a file to allow the file to be
                                uploaded to DMX. If you move that file several
                                folder layers up, this will shorten the path and
                                eliminates a file renaming.
                            </p>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Why can I not upload file types other than
                                    .PDF?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                DMX only allows the uploading of PDF files. It
                                will not accept other file types like .PPT or
                                .DOCX. If you require those files to be
                                uploaded, you will need to convert them to PDF
                                format.
                            </p>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    On the ‘Users’ page there is no search
                                    field, how do I quickly find a user?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                Currently the ‘Users’ page does not support a
                                dedicated search function. The work around is to
                                use ‘Ctrl F’ which will allow you to use the
                                native browsers search capability to search the
                                user you are looking for.
                            </p>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    I selected a document from the bottom of the
                                    edit meeting page to assign to an agenda
                                    item. I clicked the ‘Assign Documents’
                                    button but the document wasn’t assigned.
                                    What am I doing wrong?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                After you have selected the agenda item that you
                                wish to have your document attached to and you
                                click the ‘Assign Documents’ button this will
                                only close the drop-down menu. You will need to
                                click the same button once again to assign. To
                                verify assignment, you can view the document
                                below and in the ‘Items Linked’ column, the
                                number will be updated to reflect how many
                                agenda items that particular document has been
                                assigned to.
                            </p>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    I have changed my position within the
                                    Government and have moved Ministries however
                                    still require access to DMX but cannot
                                    access any longer.
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                You will need to contact the DMX support team
                                and explain your situation to them. Request that
                                your government user id (xxx.yyy&#64;gov.ab.ca)
                                be removed from the DMX/KeyCloak master realm.
                            </p>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    I have created a meeting however it is
                                    currently not viewable as a user with only
                                    view permissions.
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                Check the meeting that you have created on the
                                administration side of DMX and ensure that you
                                have published the meeting. If not, publish the
                                meeting and users will now be able to view the
                                newly published meeting.
                            </p>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    I have added several documents to an agenda
                                    item however I need to sort some of those
                                    documents further. How do I do this?
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p>
                                After opening an agenda item you will be
                                presented with a button that will allow you to
                                turn on the ability to sort files or turn off.
                            </p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </div>
</div>

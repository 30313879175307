import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-viewers-meetings-skeleton-loader',
    templateUrl: './viewers-meetings-skeleton-loader.component.html',
    styleUrls: ['./viewers-meetings-skeleton-loader.component.scss'],
})
export class ViewersMeetingsSkeletonLoaderComponent implements OnInit {
    @Input() rows: number = 3;
    @Input() rowHeight: number = 150;

    constructor() {}

    ngOnInit(): void {}
}




//class generated by TypeWriter in Core API project by ModelGenerator.nt template
export class IdRequestModel {
    public id: string;

    constructor(init? : Partial<IdRequestModel>) {
        
        Object.assign(this, init);
    }
}
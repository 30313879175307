import { NgModule } from '@angular/core';
import { AppComponent } from '@ecom/app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';

import { BrowserModule } from '@angular/platform-browser';
import { DmxCoreModule } from './modules/dmx-core/dmx-core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileViewerRoutingModule } from './file-viewer-routing.module';
import { BearerTokenInterceptor } from './common/interceptors/bearer-token-interceptor.interceptor';

@NgModule({
    imports: [
        BrowserModule,
        FileViewerRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        DmxCoreModule,
    ],
    providers: [
        KeycloakService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BearerTokenInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class FileViewerModule {}

<div class="calendarContainer">
	<div class="calendarBadge">
		<div class="calendarHeader">
			{{ meeting?.startDate | mstDate: 'MMM' }}
		</div>
		<div class="calendarBody">
			<div class="calendarDay">
				{{ meeting?.startDate | mstDate: 'D' }}
			</div>
			<div class="calendarYear">
				{{ meeting?.startDate | mstDate: 'YYYY' }}
			</div>
		</div>
	</div>
	<div *ngIf="showDaysBadge" class="calendarCountdown" [innerHTML]="meeting?.startDate | dateCountdown"></div>
</div>

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { ConfigService } from '@ecom/common/services/config.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { KeycloakUserModel } from '../models/keycloak-user-model';
import { AuthenticationService } from '../services/authentication.service';
import { filter, firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
/**
 * @summary AuthGuard extends KeycloakAuthGuard to provide authorization services
 * for the application. It checks if a user is logged in and, if not, redirects
 * the user to Keycloak for authentication before granting access to the desired
 * route.
 */
export class AuthGuard extends KeycloakAuthGuard {
    user: KeycloakUserModel;

    constructor(
        protected override router: Router,
        protected keycloakService: KeycloakService,
        protected authenticationService: AuthenticationService,
        protected configService: ConfigService
    ) {
        super(router, keycloakService);
    }

    /*
     * Roles are passed as an array of strings in the `data` attribute of the route where routing is specified for each module:
     *
     * Here's how it works:
     *
     * 1. When a route is requested, Angular checks if it has a `canActivate` attribute.
     *    If it does, it runs the specified guard (in this case `AuthGuard`) before deciding whether to activate the route.
     *
     * 2. Inside the `AuthGuard`, the `isAccessAllowed` function is called. This function receives the requested route
     *    and its state as parameters.
     *
     * 3. The `AuthGuard` checks if the user is logged in by calling `this.keycloakService.isLoggedIn()`.
     *
     * 4. If the user is logged in, it fetches the roles from the `data` attribute of the requested route
     *    (i.e., `route.data['roles']`). This array of roles represents the roles needed to access the route.
     *
     * 5. It then fetches the roles of the currently logged-in user by calling `this.keycloakService.getUserRoles()`.
     *    This function communicates with Keycloak and retrieves the roles assigned to the user upon login.
     *
     * 6. If no roles are specified on the route data, the `AuthGuard` checks if the user has at least the 'dmx-user' role.
     *    If the user has this role, they are granted access.
     *
     * 7. If roles are specified on the route data, the `AuthGuard` checks if the user has at least one of these roles.
     *    If the user has one of these roles, they are granted access.
     *
     * 8. If the user doesn't have the required roles, they are redirected to the 'access-denied' route.
     *
     * The roles specified in the route configuration (e.g., 'dmx-admin') are used to control access to specific routes.
     * For example, the 'meetings' route can only be accessed by users with the 'dmx-admin' role.
     */
    async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        if (await this.keycloakService.isLoggedIn()) {
            const requiredRoles = route.data['roles'] as string[]; // get roles from route
            const userRoles = this.keycloakService.getUserRoles();

            //1. If no roles defined in route data, allow access only if user has dmx-user role (as there is no public access if authguard is specified)
            if (!requiredRoles) {
                var isDmxUser = this.userHasRole(['dmx-user'], userRoles);
                return isDmxUser ? true : false;
            }

            //2. If roles are specified on route, check if user required roles,
            if (requiredRoles.some(role => userRoles.includes(role))) {
                //1. If '/' root path we redirect to admin or viewer meetings
                //2. Otherwise, we allow access to the route
                this.rootNavigation(state.url, userRoles);

                return true;
            }

            //3. Check for virtual roles
            const user = await firstValueFrom(
                this.authenticationService
                    .currentUser()
                    .pipe(filter(user => !!user))
            )!;
            if (requiredRoles.some(role => user?.userRoles.includes(role))) {
                this.rootNavigation(state.url, userRoles);
                return true;
            }

            //4. Access denied: If user does not have required roles, navigate to access-denied page
            this.router.navigate(['/access-denied']);
            return false;
        } else {
            this.redirectToKeycloak(state.url);
            return false;
        }
    }

    /**
     * redirectToKeycloak method forms the redirect URL and redirects the user to Keycloak login page.
     * It uses the base URL from ConfigService and the requested URL path to form the redirect URL.
     * After forming the URL, it calls KeycloakService's login method with the formed URL and identity provider hint "core".
     *
     * @param url - The requested URL path
     */
    private redirectToKeycloak(url: string) {
        var baseUrl = this.configService.get('url'); // Get base URL from ConfigService
        var redirectUrl = `${baseUrl}${url}`; // Form the redirect URL
        this.keycloakService.login({
            redirectUri: redirectUrl,
            idpHint: 'core',
        }); // Redirect to Keycloak login
    }

    /**
     * userHasRole method checks if a user has at least one of the required roles.
     * It uses Array.some() method to check if the user's roles includes any of the required roles.
     *
     * @param roles - The user's roles
     * @param rolesToCheck - The roles to check
     * @returns boolean - Returns true if the user has at least one of the required roles, otherwise false
     */
    private userHasRole(roles: string[], rolesToCheck: string[]): boolean {
        return rolesToCheck.some(roleToCheck => roles.includes(roleToCheck));
    }

    /**
     * rootNavigation method handles the navigation when root ("/") is requested.
     * If an admin user requests the root, it navigates to '/admin/home'. If a viewer user requests the root, it navigates to '/viewer/meetings'.
     * It uses userHasRole method to check if the user is an admin or viewer.
     *
     * @param url - The requested URL
     * @param roles - The user's roles
     */
    private rootNavigation(url: string, roles: string[]) {
        if (url == '/') {
            var isAdmin = this.userHasRole(roles, ['dmx-admin']);
            var isViewer = this.userHasRole(roles, ['dmx-viewer']);

            isAdmin
                ? this.router.navigate(['/admin/home'])
                : this.router.navigate(['/viewer/meetings']);
        }
    }
}

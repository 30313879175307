export class KeycloakUserModel {
    firstName: string;
    lastName: string;
    keycloakUserId: string;
    //userId: string;
    userName: string;
    userRoles: string[];
    email: string;
    token: string;
    key: string;
}

import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MeetingModel } from '@ecom/ecom-app-generated/Models/MeetingModel';

@Component({
	selector: 'app-calendar-badge',
	templateUrl: './calendar-badge.component.html',
	styleUrls: ['./calendar-badge.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CalendarBadgeComponent implements OnInit {
	//the meeting that we want to get the details about
	@Input() meeting!: MeetingModel | undefined;

	//flag to hide the "days until badge"
	@Input() showDaysBadge: boolean = true;

	constructor() {}

	ngOnInit(): void {}
}

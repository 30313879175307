// TaskPipeline.ts
import { ITask, TaskResult } from './task-interface';

export class TaskPipeline {
    private tasks: ITask[] = [];
    private context: Map<string, any> = new Map<string, any>();
    //private logs: TaskResult[] = [];
    private onTaskResultCallback: (result: TaskResult) => void;

    addTask(task: ITask): TaskPipeline {
        this.tasks.push(task);
        return this;
    }

    onTaskCompleted(callback: (result: TaskResult) => void): void {
        this.onTaskResultCallback = callback;
    }

    async execute(
        runId: number,
        onPartialResult?: (result: TaskResult) => void
    ): Promise<void> {
        const numberOfTasks = this.tasks.length;

        for (let i = 0; i < numberOfTasks; i++) {
            const task = this.tasks[i];
            const startTime = performance.now();
            // Most tasks just return a single TaskResult; exception is the Download documents task
            const result = await task.execute(this.context, partialResult => {
                partialResult.createDate = new Date();
                partialResult.isWorkflowComplete = false;
                partialResult.isPartial = true;
                partialResult.runId = runId;
                // Some tasks have a partial TaskResult; Example: The download document contents task
                // can have to download 50 documents; we want to know about each and not wait until all 50 are downloaded;
                // thus, for each document download within this task we return a TaskResult
                this.onTaskResultCallback?.(partialResult);
            });
            const duration = Math.round(performance.now() - startTime);
            result.duration = duration;
            result.createDate = new Date();
            result.runId = runId;
            // Set isWorkflowComplete to true only for the last task
            result.isWorkflowComplete = i === numberOfTasks - 1;
            //this.logs.push(result);
            this.onTaskResultCallback?.(result);

            if (!result.success) {
                break;
            }
        }

        //clear context once tasks are finished executing in pipeline
        this.context.clear();
    }
}

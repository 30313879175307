export class Constants {
	public static FileUpload = {
		MaxFileSize: 100, //max file upload size in MB
	};

	public static Codes = {
		AdminBannerCode: '6C96CCBE-A6A7-460B-8B95-EE8CC53CC345',
		ViewerBannerCode: '086B568A-B95C-4C71-883F-A878984BF3FE',
		DocumentViewMetricsCode: '406CA5EB-4949-43B3-A180-6219FA743035',
	};

	public static QueryParams = {
		FileView: 'fileView',
		Notes: 'notes',
	};

	public static Config = {
		// Console-driven settings that dynamically apply business rules in one or both of the submission / clerk applications
		// Alberta Notify
		ConfigCachedApp: 'config-cached-app', //name of the cookie where the config.json is stored
		ConfigPath: './assets/config/config.json', //path to the config.json
		ConfigApiCoreBaseUrl: 'apiCoreBaseUrl', //baseUrl of the API endpoint (dynamic to the envrionment being run)
		UserLocalStorageKey: 'currentUser',
	};

	public static UserRoles = {
		SuperAdmin: 'dmx-super-admin',
		Admin: 'dmx-admin',
		Viewer: 'dmx-viewer',
		Contributor: 'dmx-contributor',
		AgendaPreviewViewer: 'dmx-agendapreview-viewer',
	};

	public static IndexDb = {
		DocumentsForAdmin: 'documents-admin',
		DocumentsForViewer: 'documents-viewer',
		DocumentsParams: {
			DocumentId: 'documentId',
			DocumentName: 'documentName',
			File: 'file',
			MeetingId: 'meetingId',
		},
	};

	public static LocalStorage = {
		DocumentsForAdmin: 'documents-admin-v2',
		DocumentsForViewer: 'documents-viewer-v2',
	};

	public static MeetingDatabase = {
		DatabaseName: 'e-committee-meetings',
		DatabaseTableName: 'viewer-meetings',
		MeetingTableFieldNames: {
			MeetingId: 'meetingId',
			MeetingPackage: 'meetingPackage',
		},
	};

	public static Keycloak = {
		//list of keycloak roles we wwant to exclude
		ExcludeRoles: [
			'view-realm',
			'view-identity-providers',
			'manage-identity-providers',
			'impersonation',
			'realm-admin',
			'create-client',
			'manage-users',
			'query-realms',
			'view-authorization',
			'query-clients',
			'query-users',
			'manage-events',
			'manage-realm',
			'view-events',
			'view-users',
			'view-clients',
			'manage-authorization',
			'manage-clients',
			'query-groups',
			'manage-account',
			'manage-account-links',
			'view-profile',
			'offline_access',
			'uma_authorization',
		],
	};

	public static AgendaSectionMessages = {
		GetAgendaSectionsByMeetingError: 'An error occurred retrieving agenda sections for this meeting',

		AgendaSectionsAddSuccessful: 'Section added successfully',
		AgendaSectionsAddError: 'An error occured adding an agenda section',

		AgendaSectionsUpdateSuccessful: 'Section updated successfully',
		AgendaSectionsUpdateError: 'An error occured updating an agenda section',

		AgendaSectionsDeleteSuccessful: 'Section deleted successfully',
		AgendaSectionsDeleteError: 'An error occured deleting an agenda section',

		AgendaSectionsSortSuccessful: 'Agenda sections reordered successfully',
		AgendaSectionsSortError: 'An error occured re-ordering agenda sections',

		ToggleSpecialAccessOn: "Special access turned on ('Update' to save)",
		ToggleSpecialAccessOff: "Special access turned off ('Update' to save)",
	};

	public static AgendaItemsMessages = {
		GetAgendaItemsByMeetingError: 'An error occurred retrieving agenda items for this meeting',
		ManageAgendaItemsError: 'An error occured managing your agenda items',
		AgendaItemsSortError: 'An error occured re-ordering agenda items',
	};

	public static AuditLogMessages = {
		GetAuditLogsError: 'An error occurred retrieving audit logs',
	};

	public static CommitteeMessages = {
		GetCommitteeByIdError: 'An error occurred retrieving the committee',
		GetCommitteesError: 'An error occurred retrieving committees',

		CommitteeAddSuccess: 'Committee added successfully',
		CommitteeAddError: 'An error occurred adding a new committee',

		CommitteeDeletedSuccess: 'Committee deleted successfully',
		CommitteeDeletedError: 'An error occurred deleting a committee',
	};

	public static DocumentAssignmentMessages = {
		GetDocumentAssignmentsByMeetingError: 'An error occurred retrieving document assignments for this meeting',

		AssignDocumentAssignmentsSuccess: 'Document(s) assigned successfully!',
		AssignDocumentAssignmentsError: 'An error occured assigning documents',

		UnassignDocumentAssignmentsSuccess: 'Document un-assigned successfully!',
		UnassignDocumentAssignmentsError: 'An error occured un-assigning documents for this agenda item',

		UnassignAllDocumentAssignmentsSuccess: 'All documents un-assigned successfully!',
		UnassignAllDocumentAssignmentsError: 'An error occurred un-assigning all documents for this agenda item',
	};

	public static DocumentMessages = {
		DocumentSortSuccess: 'Documents re-orderd successfully!',
		DocumentSortError: 'An error occurred re-ordering the documents',
		UploadingDocumentError: 'An error occurred uploading a document',
		GetDocumentsByMeetingError: 'An error occurred retrieving all documents for this meeting',
		GetThumbnailsByMeetingError: 'An error occurred retrieving all thumbnails for this meeting',
	};

	public static UserDocumentNotesMessages = {
		DeleteError: 'An error occurred deleting the document note',
		SaveError: 'An error occurred saving the document note',
	};

	public static MeetingMessages = {
		GetMeetingError: 'An error occured retrieving meeting.',

		MeetingAddSuccessful: 'Meeting was added successfully',
		MeetingAddError: 'An error occured adding a meeting',

		MeetingAddFromItsSuccessful: 'Meeting was added from ITS successfully',
		MeetingAddFromItsError: 'An error occured adding a meeting from ITS',

		MeetingUpdateSuccessful: 'Meeting was updated successfully',
		MeetingUpdateError: 'An error occured updated a meeting',

		MeetingDeleteSuccessful: 'Meeting was deleted successfully',
		MeetingDeleteError: 'An error occured deleting a meeting',

		MeetingPublishSuccessful: 'Meeting was published successfully',
		MeetingPublishError: 'An error occured publishing a meeting',

		MeetingUnpublishSuccessful: 'Meeting was unpublished successfully',
		MeetingUnpublishError: 'An error occured unpublishing a meeting',

		MeetingExpireSuccessful: 'Meeting was expired successfully',
		MeetingExpireError: 'An error occured expiring a meeting',

		MeetingExtendExpireSuccessful: 'Meeting expiry date was extended successfully',
		MeetingExtendExpireError: 'An error occured extending the meeting expiry date',
	};

	public static LocationMessages = {
		LocationAddSuccessful: 'Location was added successfully',
		LocationAddError: 'An error occured adding a location',

		LocationDeleteSuccessful: 'Location was deleted successfully',
		LocationDeleteError: 'An error occured deleting a location',
	};

	public static UserMessages = {
		GetUserError: 'There was an error retrieving the DMX user',
		GetKeycloakUserError: 'There was an error retrieving the Keycloak user',
		ManualUserAddSuccessful: 'The user was manually added successfully',
		ManualUserAddError: 'An error occured adding a user manually',
		UserSettingsUpdated: 'User settings updated successfully',
		GuestUserAddSuccessful: 'Guest user added successfully',
		GuestUserAddError: 'An error occurred adding guest user',
		UserDeleteError: 'An error occurred deleting user',
		UserDeleteSuccessful: 'User deleted successfully',
	};

	public static RoleMessages = {
		GetRolesError: 'There was an error retrieving the system roles',
	};

	public static UserRoleMessages = {
		GetAdminUserRolesError: "There was an error retrieving the admin user's roles",
		GetUserRolesError: "There was an error retrieving the current user's roles",
	};

	public static FileMessages = {
		FileNotFound: 'The document you are trying to view is currently unavailable. Please try again later.',
	};

	public static BannerAdminMessages = {
		GetAdminMessageError: "An error occured retrieving the admin's banner message.",
		AdminMessageSaveSuccessful: 'Admin banner message was saved successfully',
		AdminMessageSaveError: "An error occured saving the admin's banner message",

		GetViewerMessageError: "An error occured retrieving the viewer's banner message.",
		ViewerMessageSaveSuccessful: 'Viewer banner message was saved successfully',
		ViewerMessageSaveError: "An error occured saving the viewer's banner message",
	};

	public static MeetingItemAccessTypeCodes = {
		SpecialAccess: 'F0ED1E34-2AB1-4A91-8662-457A8C20B7C2',
		GuestAccess: '2CFECBC3-AFDD-4A2E-82D2-AA1EAB304C4A',
	};
}

export type ApiStatus = 'Created' | 'Running' | 'Ready' | 'Faulted';

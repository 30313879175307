import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { Router } from '@angular/router';
import { AddFeedbackRequestModel } from '@ecom/ecom-app-generated/Models/AddFeedbackRequestModel';
import { FeedbackService } from '@ecom/ecom-app-generated/Services/FeedbackService';
import { AddAuditLogRequestModel } from '@ecom/ecom-app-generated/Models/AddAuditLogRequestModel';
import { AuditLogsService } from '@ecom/ecom-app-generated/Services/AuditLogsService';
import { LoggingService } from '@ecom/common/services/logging-service/logging-service.service';
import UIkit from 'uikit';
import { FileHelper } from '@ecom/common/helpers/file-helper';
import { FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

const LOG_PREFIX = '[Feedback Component]';

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit, AfterViewInit {
	feedbackForm: FormGroup;
	private _dialogId: string;

	@Input()
	set dialogId(value: string | null) {
		if (value) {
			this._dialogId = value;
		} else {
			// If null or undefined is passed, generate a new UUID
			this._dialogId = FileHelper.generateUUID();
		}
	}
	@ViewChild('feedbackModal') feedbackModal: ElementRef;
	@ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

	selectedReaction: any = undefined;

	get dialogId(): string {
		return this._dialogId;
	}

	constructor(
		private router: Router,
		private feedbackService: FeedbackService,
		private auditLogsService: AuditLogsService,
		private loggingService: LoggingService,
		private fb: FormBuilder,
		private cdRef: ChangeDetectorRef,
		private ngZone: NgZone
	) {
		this._dialogId = FileHelper.generateUUID();
	}

	ngAfterViewInit() {
		// todo: figure out if (and why) this is needed - apprears to work without
		if (this.feedbackModal) {
			// if we need to init this and we have it now, then do it outside the angular zone to avoid change detection interference (causing app to hang with no console errors)
			this.ngZone.runOutsideAngular(() => {
				//console.log('ngAfterViewInit init feedbackModal', this.feedbackModal.nativeElement);
				UIkit.modal(this.feedbackModal.nativeElement);
			});
		}
	}

	ngOnInit(): void {
		this.initializeForm();
	}

	initializeForm(): void {
		this.feedbackForm = this.fb.group({
			// Other form controls can be initialized here
			feedback_sentiment: [undefined, Validators.required], // Make sure this control is required
			feedback_comment: new FormControl(''),
		});
	}

	open() {
		this.initializeForm();
		//this.resetForm()
		UIkit.modal(this.feedbackModal.nativeElement).show();
	}

	onCancel(): void {
		this.resetForm();
	}

	onPositiveSentimentClick() {
		this.feedbackForm.get('feedback_comment')?.setErrors(null);
	}

	async onSubmit() {
		// validation: if user selects "NO" then we require more text, otherwise "YES" can be submitted without any text
		if (this.feedbackForm.valid) {
			const sentimentValue = this.feedbackForm.get('feedback_sentiment')?.value;
			const sentiment = coerceBooleanProperty(sentimentValue);
			const message = this.feedbackForm.get('feedback_comment')?.value;

			// If sentiment is false ("NO") and message is empty, show error
			if (sentiment === false && message === '') {
				this.feedbackForm.get('feedback_comment')?.setErrors({ required: 'Please fill in this field before submitting.' });
				return;
			}
			const feedback = new AddFeedbackRequestModel({
				isThumbUp: sentiment,
				details: message,
				pageUrl: this.router.url,
			});

			UIkit.modal(this.feedbackModal.nativeElement).hide();

			try {
				// Handle form submission, e.g., sending data to a server
				console.log(`${LOG_PREFIX}: Submitting feedback:`, feedback);
				const result = await this.feedbackService.addFeedback(feedback);

				if (!result.payload) {
					console.warn(`${LOG_PREFIX}: Server did not respond with 'success'. Captured errors:`, result.errors);
					this.loggingService.addLog(`Server did not respond with 'success'. Captured errors: ${result.errors}`);
				} else {
					await this.auditFeedback(feedback);
				}
				UIkit.modal('#feedbackConfirmationModal').show();
			} catch (err: any) {
				console.error(`${LOG_PREFIX}: Server call has encountered an error:`, err);
				this.loggingService.addLog(`Server call has encountered an error: ${err.message}`);
			} finally {
				this.resetForm();
			}
		} else {
			// Optionally, trigger validation for all form controls

			this.feedbackForm.markAllAsTouched();
			//console.log('markAllAsTouched', this.feedbackForm.value);
			//this.cdRef.detectChanges();
		}
	}
	resetForm() {
		this.feedbackForm.reset();
		if (this.formDirective) {
			this.formDirective.resetForm();
		}

		this.cdRef.detectChanges();
	}

	closeModal(event: Event) {
		event.stopPropagation(); // Prevents event from propagating further
		UIkit.modal('#feedbackModal').hide();
		// Code to close the modal
	}

	async auditFeedback(value: AddFeedbackRequestModel) {
		await this.auditLogsService.addAuditLog(
			new AddAuditLogRequestModel({
				objectType: 'Feedback',
				objectId: this._dialogId,
				objectAction: 'New',
				message: JSON.stringify({ value }),
				eventType: 'Feedback',
			})
		);
	}
}










//class generated by TypeWriter in Core API project by ModelGenerator.nt template
export class AddUserRequestModel {
    public keycloakId: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public role: string;
    public accessRequestComment: string;
    public autoActivate: boolean;

    constructor(init? : Partial<AddUserRequestModel>) {
        
        Object.assign(this, init);
    }
}
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { KeycloakUserModel } from '@ecom/common/authentication/models/keycloak-user-model';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';
import { ConfigService } from '@ecom/common/services/config.service';
import { AddUserRequestModel } from '@ecom/ecom-app-generated/Models/AddUserRequestModel';
import { GetUserByEmailRequestModel } from '@ecom/ecom-app-generated/Models/GetUserByEmailRequestModel';
import { UsersService } from '@ecom/ecom-app-generated/Services/UsersService';
import { InteractionsService } from '@ecom/services/interactions.service';
import { KeycloakService } from 'keycloak-angular';
import { filter, map, timer } from 'rxjs';

@Component({
    selector: 'app-access-requested',
    templateUrl: './access-requested.component.html',
    styleUrls: ['./access-requested.component.scss'],
})
export class AccessRequestedComponent implements OnInit {
    public keycloakUser: KeycloakUserModel | undefined;
    userStatus:
        | 'Unknown'
        | 'New'
        | 'Approved'
        | 'Pending'
        | 'Invited'
        | 'Denied' = 'Unknown';
    form: FormGroup;
    timerValue: number = 3;

    constructor(
        private usersService: UsersService,
        private authenticationService: AuthenticationService,
        private keycloakService: KeycloakService,
        private configService: ConfigService,
        private interactionService: InteractionsService,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        //initialize access request form
        this.form = new FormGroup({
            inputValue: new FormControl(''),
        });

        //once we've got all the information about our user let's setup the page
        this.authenticationService
            .currentUser()
            .pipe(filter(user => user !== undefined))
            .subscribe(async user => {
                this.keycloakUser = user;
                var dmxUserResult = await this.usersService.getUserByEmail(
                    new GetUserByEmailRequestModel({ email: user?.email })
                );

                if (user && dmxUserResult.isSuccess) {
                    // var dmxUser = dmxUserResult.payload;
                    // //Approved access - Access is already approved, the user doesn't need to be on this page
                    // if (!isEmptyGuid(dmxUser.keycloakId) && dmxUser.approveAccessRequestDate != null && dmxUser.denyAccessRequestDate == null) {
                    //      this.userStatus = 'Approved';
                    //      console.log('approved');
                    //      this.startRedirectForApprovedUserCountdown();
                    //      return;
                    // }
                    // //Pending access - Pending review by an admin
                    // if (!isEmptyGuid(dmxUser.keycloakId) && dmxUser.approveAccessRequestDate == null && dmxUser.denyAccessRequestDate == null) {
                    //      this.userStatus = 'Pending';
                    //      console.log('pending');
                    //      return;
                    // }
                    // console.log(dmxUser);
                } else {
                    //New accces - User is brand shiny new and while they have successfully logged into the app, they are not approved, and need to request access
                    this.userStatus = 'New';
                    console.log('new');
                    return;
                }
            });
    }

    onSubmit() {
        var accessRequestComment = this.form.controls['inputValue'].value;

        //Get current user from keycloak so that we can use keycloakId to add a new user to our system
        //KeycloakUserId primary, but we also want to add firstname,lastname, and email from keycloak token so that our database has this meta data
        if (this.keycloakUser) {
            var model = new AddUserRequestModel({
                keycloakId: this.keycloakUser!.keycloakUserId,
                firstName: this.keycloakUser!.firstName,
                lastName: this.keycloakUser!.lastName,
                email: this.keycloakUser!.email,
                accessRequestComment: accessRequestComment,
            });

            //adds user to our database if first time hitting access-requested page
            //this.store.dispatch(new UsersActions.AddUser(model));

            this.addUser(model);
            this.userStatus = 'Pending';
        } else {
            throw Error('An unknown error has occurred.');
        }
    }

    async addUser(newUser: AddUserRequestModel) {
        let response = await this.usersService.addUser(newUser);

        if (response.isSuccess) {
            this.interactionService.showSuccess('User added successfully.');
        } else {
            this.interactionService.showErrorMessage(
                'Error adding a new user',
                response.errors
            );
        }
    }

    /**
     * @summary If an approved user happens to nagivate to the access-requested page (maybe they bookmark it) we want to redirect them back to root / of application
     */
    startRedirectForApprovedUserCountdown() {
        const countdown$ = timer(0, 1000).pipe(
            map(() => --this.timerValue),
            filter(value => value === 0)
        );

        countdown$.subscribe(() => {
            this.router.navigate(['/']);
        });
    }

    /**
     * @summary Admins have ability to add a user / invite a user without the user having to go through the Access-request enrolement
     * @param userId
     * @param keycloakUserId
     */
    // async applyFirstLoginSetup(userId: string, keycloakUserId: string) {
    //      await this.usersService.applyFirstLoginSetup(new ApplyFirstLoginSetupRequestModel({ userId: userId, keycloakUserId: keycloakUserId }));
    // }

    /**
     * @summary Redirect to Keycloak
     */
    redirectToKeycloak(url: string) {
        var baseUrl = this.configService.get('url'); //check happens for 'url' variable in the ConfigService itself
        var redirectUrl = `${baseUrl}${url}`;
        this.keycloakService.login({
            redirectUri: redirectUrl,
            idpHint: 'core',
        });
    }
}

function isEmptyGuid(guid: string): boolean {
    const emptyGuid = '00000000-0000-0000-0000-000000000000';
    return guid === emptyGuid;
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    public logs: string[] = [];

    addLog(message: string) {
        this.logs.push(message);
    }

    getLogs(): string[] {
        return this.logs;
    }
}

<ng-container *ngIf="document.file; else noFile">
    <button
        *ngIf="user"
        class="hyperlink clickable uk-button uk-button-link"
        type="button"
        (click)="view(document.documentId, user.enableTabbedViewing)">
        View
    </button>
</ng-container>
<ng-template #noFile>
    <div>Syncing</div>
</ng-template>

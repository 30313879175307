




//class generated by TypeWriter in Core API project by ModelGenerator.nt template
export class AddFeedbackRequestModel {
    public isThumbUp: boolean | null;
    public details: string;
    public pageUrl: string;

    constructor(init? : Partial<AddFeedbackRequestModel>) {
        
        Object.assign(this, init);
    }
}
import { Constants } from '@ecom/common/helpers/constants';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';
import {
    ITask,
    TaskResult,
} from '@ecom/app-workflows/core/task-pipeline/task-interface';
import { DocumentModel } from '@ecom/ecom-app-generated/Models/DocumentModel';

export class AddUpdateViewerDocumentsInIndexDbTask implements ITask {
    constructor(private localDocumentsService: LocalDocumentsService) {}
    async execute(context: Map<string, any>): Promise<TaskResult> {
        try {
            //Ensure we close the connection when removing documents from IndexDb
            var recordCount =
                await this.addUpdateViewerDocumentsInIndexDb(context);

            return {
                success: true,
                message: 'Add/update documents in local storage succeeded.',
                recordCount: recordCount,
            };
        } catch (error: any) {
            return {
                success: false,
                message: `Add/update documents in local storage failed.`,
                data: error.message,
            };
        }
    }

    async addUpdateViewerDocumentsInIndexDb(context: Map<string, any>) {
        let connection: IDBDatabase | null = null;
        var serverDocuments = context.get(
            'GetViewerDocumentsFromServerTaskResult'
        ) as DocumentModel[];
        var indexDbDocuments = context.get(
            'GetViewerDocumentsFromIndexDbTaskResult'
        ) as DocumentModel[];

        let recordsUpdated: number = 0;
        let recordsAdded: number = 0;

        try {
            connection = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForViewer
            );
            // Create a map for quick lookup of IndexDB documents
            const indexDbDocumentMap = new Map(
                indexDbDocuments.map(doc => [doc.documentId, doc])
            );

            for (let serverDocument of serverDocuments) {
                const indexDbDocument = indexDbDocumentMap.get(
                    serverDocument.documentId
                );

                if (
                    !indexDbDocument ||
                    indexDbDocument.updateDate! < serverDocument.updateDate!
                ) {
                    //remove the old document, including removing the content and thumbnail, only then do we add the new document entry
                    await this.localDocumentsService.deleteDocument(
                        connection,
                        serverDocument.documentId
                    );
                    await this.localDocumentsService.addUpdateDocument(
                        connection,
                        serverDocument
                    );
                    !indexDbDocument ? recordsAdded++ : recordsUpdated++;
                }
            }
            return recordsUpdated + recordsAdded;
        } catch (error) {
            throw error;
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection) {
                connection.close();
                connection = null;
            }
        }
    }
}

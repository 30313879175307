import { Component, Input, OnInit } from '@angular/core';
import { KeycloakUserModel } from '@ecom/common/authentication/models/keycloak-user-model';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';
import { UsersService } from '@ecom/ecom-app-generated/Services/UsersService';
import { filter, from, map, switchMap, tap } from 'rxjs';

@Component({
	selector: 'app-update-badge',
	templateUrl: './update-badge.component.html',
	styleUrls: ['./update-badge.component.scss'],
})
export class UpdateBadgeComponent {
	/*
        // DEPRECATED // review alternative component: <app-badge>
    */
	@Input() badgeType: 'info' = 'info';
	@Input() badgeText: string = 'Updated';

	user: KeycloakUserModel;
	checkForUpdates: boolean = false;

	// todo: review the calling component and see if this can be invoked from user service
	// observe current user
	user$ = this.authenticationService.currentUser().pipe(filter(user => user !== undefined));

	// make an observable of whether user has enabled document notifications
	// with current user, observe whether document notifications is enabled
	hasUpdates$ = this.user$.pipe(
		// return an observable of the user's document notifications
		switchMap(user =>
			// return observable from promise
			from(
				// get user information from keycloak id
				this.usersService.getUserByKeycloakId({
					id: user?.keycloakUserId ?? '',
				})
			).pipe(
				//tap(response => console.log(`UpdateBadgeComponent response`, response)),
				// transform (map) the response to return the user's document notifications
				map(response => {
					const notifications = response.payload.enableDocumentNotifications;
					return notifications;
				})
			)
		)
	);

	constructor(
		private authenticationService: AuthenticationService,
		private usersService: UsersService
	) {}
}

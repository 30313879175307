
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';
 
import { AddFeedbackRequestModel } from '../Models/AddFeedbackRequestModel'
import { AddFeedbackResponseModel } from '../Models/AddFeedbackResponseModel'

@Injectable({
     providedIn: 'root'
 })
export class FeedbackService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'feedback');
    } 

  
  //post
  public addFeedback(model: AddFeedbackRequestModel) : Promise<ServiceResult<AddFeedbackResponseModel>> {
      return this.post<ServiceResult<AddFeedbackResponseModel>,AddFeedbackRequestModel>('AddFeedback',model);
  };
}
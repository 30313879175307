


//class generated by TypeWriter in Core API project by ModelGenerator.nt template
export class GetUserByEmailRequestModel {
    public email: string;

    constructor(init? : Partial<GetUserByEmailRequestModel>) {
        
        Object.assign(this, init);
    }
}
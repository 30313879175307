
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';
 
import { IdRequestModel } from '../Models/IdRequestModel'
import { AgendaSectionModel } from '../Models/AgendaSectionModel' 
import { AddAgendaSectionRequestModel } from '../Models/AddAgendaSectionRequestModel' 
import { UpdateAgendaSectionRequestModel } from '../Models/UpdateAgendaSectionRequestModel'
import { IdResponseModel } from '../Models/IdResponseModel' 
import { SortAgendaSectionsRequestModel } from '../Models/SortAgendaSectionsRequestModel'

@Injectable({
     providedIn: 'root'
 })
export class AgendaSectionsService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'agendasections');
    } 

  //get
  public getAgendaSectionsByMeetingId(model: IdRequestModel) : Promise<ServiceResult<AgendaSectionModel[]>> {
      return this.getWithQuery<ServiceResult<AgendaSectionModel[]>,IdRequestModel>('GetAgendaSectionsByMeetingId',model);
  };
  
  //get
  public getAgendaSectionById(model: IdRequestModel) : Promise<ServiceResult<AgendaSectionModel>> {
      return this.getWithQuery<ServiceResult<AgendaSectionModel>,IdRequestModel>('GetAgendaSectionById',model);
  };
  
  
  //post
  public addAgendaSection(model: AddAgendaSectionRequestModel) : Promise<ServiceResult<AgendaSectionModel>> {
      return this.post<ServiceResult<AgendaSectionModel>,AddAgendaSectionRequestModel>('AddAgendaSection',model);
  };
  
  //post
  public updateAgendaSection(model: UpdateAgendaSectionRequestModel) : Promise<ServiceResult<AgendaSectionModel>> {
      return this.post<ServiceResult<AgendaSectionModel>,UpdateAgendaSectionRequestModel>('UpdateAgendaSection',model);
  };
  
  //post
  public deleteAgendaSection(model: IdRequestModel) : Promise<ServiceResult<IdResponseModel>> {
      return this.post<ServiceResult<IdResponseModel>,IdRequestModel>('DeleteAgendaSection',model);
  };
  
  //post
  public sortAgendaSections(model: SortAgendaSectionsRequestModel) : Promise<ServiceResult<AgendaSectionModel[]>> {
      return this.post<ServiceResult<AgendaSectionModel[]>,SortAgendaSectionsRequestModel>('SortAgendaSections',model);
  };
}
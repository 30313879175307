import { Injectable } from '@angular/core';
import UIkit from 'uikit';

@Injectable({
    providedIn: 'root',
})
export class InteractionsService {
    notifications: UIkit.UIkitNotificationElement[] = [];

    constructor() {}

    closeAll() {
        this.notifications.forEach(n => n.close(true));
        this.notifications = [];
    }

    /**
     *
     * @param content
     * This is the main message that will be displayed in the snackbar
     * Snackbar automatically closes after 2 sec.
     */
    showSuccess(content: string) {
        // Close all notifications
        this.closeAll();

        let html = `<div data-cy="action-message">${content}</div>`;

        let notification = UIkit.notification({
            message: html,
            timeout: 2000,
            pos: 'bottom-center',
        });

        this.notifications.push(notification);
    }

    showErrorMessage(title: string, errors: string[], timeout?: number) {
        //close all Notifications
        this.closeAll();

        //build un-ordered list of errors
        let html = `<div data-cy="action-message" style='font-size:1.25em !important;'>${title}</div><ul style='margin-top: 5px;'>`;
        let i = 0;
        errors.forEach(err => {
            html += `<li data-cy=error-${i} style='font-size:1em !important;'>${err}</li>`;
            i = i + 1;
        });
        html += '</ul>';
        let notification = UIkit.notification({
            message: html,
            status: 'danger',
            timeout: timeout,
            pos: 'bottom-center',
        });

        this.notifications.push(notification);
    }
}

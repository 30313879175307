import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';
import { Constants } from '@ecom/common/helpers/constants';
import { BrowserService } from '@ecom/services/browser.service';
import { combineLatest, map } from 'rxjs';

@Component({
	selector: 'app-help-centre',
	templateUrl: './help-centre.component.html',
	styleUrls: ['./help-centre.component.scss'],
	providers: [BrowserService],
})
export class HelpCentreComponent {
	route$ = this.browserService.route$;
	user$ = this.authenticationService.getUserInfo$();
	vm$ = combineLatest([this.user$]).pipe(
		map(([{ user, isAdmin }]) => {
			// default (non-admin) nav context
			const navBackContext = { link: ['/viewer/meetings'], title: 'Back to Meetings' };
			if (isAdmin) {
				// update to admin nav context
				navBackContext.link = ['/admin/home'];
				navBackContext.title = 'Back to Dashboard';
			}
			return { user, isAdmin, navBackContext };
		})
	);
	constructor(
		private browserService: BrowserService,
		private authenticationService: AuthenticationService
	) {}
}

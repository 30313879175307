import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
    name: 'mstDate',
})
export class MstDatePipe implements PipeTransform {
    transform(
        theDate: Date | null | undefined,
        format?: string,
        isUtc: boolean = false
    ): string {
        if (!theDate) {
            return '';
        }

        let retVal = '';

        if (isUtc) {
            //convert UtcDateTime to MST
            if (format) {
                retVal = moment
                    .utc(theDate)
                    .tz('America/Denver')
                    .format(format);
            } else {
                retVal = moment.utc(theDate).tz('America/Denver').format();
            }
        } else {
            //all dates are STORE in MST so we will just display them in the proper format
            if (format) {
                const mdtDate = moment.tz(theDate, 'America/Denver');
                retVal = mdtDate.format(format);
            } else {
                retVal = moment(theDate).format();
            }
        }

        return retVal;
    }
}

import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@ecom/common/services/config.service';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';

@Component({
    selector: 'app-sign-out',
    templateUrl: './sign-out.component.html',
    styleUrls: ['./sign-out.component.scss'],
})
export class SignOutComponent implements OnInit {
    constructor(
        private keycloakService: KeycloakService,
        private authenticationService: AuthenticationService,
        private configService: ConfigService,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        var baseUrl = this.configService.get('url');
        var signOutRoute = 'signout';
        var redirectUri = baseUrl + signOutRoute;

        const isLoggedIn = await this.keycloakService.isLoggedIn();

        if (isLoggedIn) {
            await this.logoutAndClearCache(this.keycloakService, redirectUri);
        } else {
            // do nothing
        }
    }

    async logoutAndClearCache(keycloakService: KeycloakService, redirectUri: string) {

        this.authenticationService.logout(redirectUri);

        // // Clear local storage
        // localStorage.clear();

        // // Clear session storage
        // sessionStorage.clear();

        // // Manually clear cookies
        // document.cookie.split(";").forEach((c) => {
        //     document.cookie = c.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
        // });
    }


}

<ng-container *ngIf="vm$ | async as vm">
	<div uk-grid>
		<div class="uk-width-expand@m">
			<app-layout-header headerType="secure"></app-layout-header>
			<div class="uk-padding">
				<!-- <ng-container *ngTemplateOutlet="breadcrumbNavLink; context: { link: ['/admin/home'], title: 'Back to Dashboard' }"></ng-container> -->
				<ng-container *ngTemplateOutlet="breadcrumbNavLink; context: vm.navBackContext"> </ng-container>
				<h2>Help Centre</h2>
				<!-- Help Center Paragraph -->
				<div class="help-center">
					<p>
						Welcome to the Help Center! If you have common questions, make sure to check out our
						<a [routerLink]="['/faq']">FAQ</a> page for quick answers.
					</p>
				</div>
				<br />
				<br />
				<!-- Troubleshooting Technical Options -->
				<div class="troubleshooting-section">
					<h2>Troubleshooting</h2>
					<ul class="enterprise-ui-list">
						<li><a [routerLink]="['/logs']">Logs</a> - View detailed logs to diagnose issues.</li>
						<li><a [routerLink]="['/workflows']">Workflows</a> - View background services that keep your device in sync.</li>
						<li>
							<a [routerLink]="['/local-storage']">Local Storage</a>
							- Manage your application's local storage data.
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<ng-template #breadcrumbNavLink let-link="link" let-title="title">
		<div class="uk-margin-bottom">
			<a [routerLink]="link">{{ title }}</a>
		</div>
	</ng-template>
</ng-container>

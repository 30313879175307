import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';
import { Constants } from '@ecom/common/helpers/constants';
import { UserModel } from '@ecom/ecom-app-generated/Models/UserModel';
import { UsersService } from '@ecom/ecom-app-generated/Services/UsersService';
import { BrowserService } from '@ecom/services/browser.service';
import { InteractionsService } from '@ecom/services/interactions.service';
import { combineLatest, firstValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
	selector: 'app-user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.scss'],
	providers: [BrowserService],
})
export class UserSettingsComponent implements OnInit {
	//ngxs not in viewer, so need event for admin app
	@Output() updated: EventEmitter<void> = new EventEmitter<void>();
	settings: UserModel;
	isAdminRoute: boolean;

	route$ = this.browserService.route$;
	user$ = this.authenticationService.getUserInfo$();
	vm$ = combineLatest([this.user$, this.route$]).pipe(
		map(([{ isAdmin }, route]) => {
			// default (non-admin) nav context
			const navBackContext = { link: ['/viewer/meetings'], title: 'Back to Meetings' };
			// if user is admin and not on viewer route
			console.log(`UserSettingsComponent (isAdmin, route.isViewerRoute)`, isAdmin, route.isViewerRoute);
			if (isAdmin && route.isViewerRoute !== true) {
				// update to admin nav context
				navBackContext.link = ['/admin/home'];
				navBackContext.title = 'Back to Dashboard';
			}
			return { isAdmin, navBackContext };
		})
	);

	constructor(
		private usersService: UsersService,
		private authenticationService: AuthenticationService,
		private interactionsService: InteractionsService,
		private router: Router,
		private browserService: BrowserService
	) {}

	async ngOnInit() {
		this.isAdminRoute = this.router?.url?.includes('/admin/') ?? false;
		const user = await firstValueFrom(this.authenticationService.currentUser().pipe(filter(user => !!user)));

		var { isSuccess, payload } = await this.usersService.getUserByKeycloakId({
			id: user?.keycloakUserId!,
		});
		if (isSuccess) {
			this.settings = payload;
		}
	}

	async updateTabbedViewing($event: MatCheckboxChange) {
		const { checked } = $event;
		await this.usersService.updateUserSettings({
			...this.settings,
			enableTabbedViewing: checked,
		});
		this.interactionsService.showSuccess(Constants.UserMessages.UserSettingsUpdated);
		this.updated.emit();
	}

	async updateDocumentNotifications($event: MatCheckboxChange) {
		const { checked } = $event;
		await this.usersService.updateUserSettings({
			...this.settings,
			enableDocumentNotifications: checked,
		});
		this.interactionsService.showSuccess(Constants.UserMessages.UserSettingsUpdated);
		this.updated.emit();
	}

	async deleteIndexDb() {
		alert('Delete indexDb from developer tools');
	}
}

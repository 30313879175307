import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { KeycloakUserModel } from '@ecom/common/authentication/models/keycloak-user-model';
import { Navigation } from '@ecom/common/helpers/navigation';
import { Router } from '@angular/router';

import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';
import { filter } from 'rxjs';
declare var UIkit: any;

@Component({
    selector: 'app-user-profile-menu',
    templateUrl: './user-profile-menu.component.html',
    styleUrls: ['./user-profile-menu.component.scss'],
})
export class UserProfileMenuComponent implements OnInit {
    isAgendaPreview = false;

    @Input() activeMenu: string;

    @Input() isAdmin = false;

    @Input() user: KeycloakUserModel | undefined;

    @Output() onOpenFeedbackModal: EventEmitter<void> = new EventEmitter();

    emitEventToOpenModal() {
        this.onOpenFeedbackModal.emit();
    }

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        //get the current user
        this.authenticationService
            .currentUser()
            .pipe(filter(user => user !== undefined))
            .subscribe(currentUser => {
                if (currentUser) {
                    this.user = currentUser;

                    if (
                        currentUser?.userRoles.includes(
                            'dmx-agendapreview-viewer'
                        )
                    ) {
                        this.isAgendaPreview = true;
                    }
                }
            });
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        document
            .querySelectorAll('.uk-navbar-dropdown [role="menuitem"]')
            .forEach(item => {
                item.addEventListener('click', () => {
                    // Close the dropdown
                    let dropdown = UIkit.dropdown(
                        item.closest('.uk-navbar-dropdown')
                    );
                    dropdown.hide();
                });
            });
    }

    reloadIfSameUrl(target: string) {
        Navigation.reloadIfSameUrl(this.router.url, target);
    }
}

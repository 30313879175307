import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';
import { AgendaItemModel } from '@ecom/ecom-app-generated/Models/AgendaItemModel';
import { AgendaSectionModel } from '@ecom/ecom-app-generated/Models/AgendaSectionModel';
import { DocumentAssignmentsModel } from '@ecom/ecom-app-generated/Models/DocumentAssignmentsModel';
import { DocumentModel } from '@ecom/ecom-app-generated/Models/DocumentModel';
import { GetAllAgendaItemsRequestModel } from '@ecom/ecom-app-generated/Models/GetAllAgendaItemsRequestModel';
import { IdRequestModel } from '@ecom/ecom-app-generated/Models/IdRequestModel';
import { MeetingLocationModel } from '@ecom/ecom-app-generated/Models/MeetingLocationModel';
import { MeetingModel } from '@ecom/ecom-app-generated/Models/MeetingModel';
import { MeetingWizardModel } from '@ecom/ecom-app-generated/Models/MeetingWizardModel';

import { UserModel } from '@ecom/ecom-app-generated/Models/UserModel';
import { AgendaItemsService } from '@ecom/ecom-app-generated/Services/AgendaItemsService';
import { AgendaSectionsService } from '@ecom/ecom-app-generated/Services/AgendaSectionsService';
import { DocumentAssignmentsService } from '@ecom/ecom-app-generated/Services/DocumentAssignmentsService';
import { DocumentsService } from '@ecom/ecom-app-generated/Services/DocumentsService';
import { MeetingLocationsService } from '@ecom/ecom-app-generated/Services/MeetingLocationsService';
import { MeetingsService } from '@ecom/ecom-app-generated/Services/MeetingsService';
import { UsersService } from '@ecom/ecom-app-generated/Services/UsersService';
import { BehaviorSubject, ReplaySubject, filter, from, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SharedMeetingHelperService implements OnDestroy {
	public meetingId: string | undefined;
	public meeting: MeetingModel;
	public committeeId: string | undefined;

	private isReadySource = new BehaviorSubject<boolean>(false);
	isReady$ = this.isReadySource.asObservable();

	private onChangeSource = new BehaviorSubject<string>('');
	onChange$ = this.onChangeSource.asObservable();

	private hasSpecialAccess = new BehaviorSubject<boolean>(false);
	hasSpecialAccess$ = this.hasSpecialAccess.asObservable();

	private _destroyed$ = new ReplaySubject<void>(undefined);

	constructor(
		private meetingService: MeetingsService,
		private meetingLocationsService: MeetingLocationsService,
		private agendaSectionService: AgendaSectionsService,
		private agendaItemService: AgendaItemsService,
		private documentService: DocumentsService,
		private usersService: UsersService,
		private documentAssignmentService: DocumentAssignmentsService,
		private authenticationService: AuthenticationService
	) {}

	ngOnDestroy(): void {
		this._destroyed$.next();
		this._destroyed$.complete();
	}

	/* Optimized Meeting loading events */
	private meetingSource = new BehaviorSubject<MeetingModel | null>(null);
	meetingReady$ = this.meetingSource.asObservable();

	async refreshMeeting(meetingId: string) {
		if (meetingId != this.meetingId) throw new Error('Cannot refresh on a meeting that has not been initialized with SharedMeetingService.initializeMeeting()');

		let response = await this.meetingService.getMeetingById(
			new IdRequestModel({
				id: meetingId,
			})
		);

		if (response.isSuccess) {
			this.hydrateMeeting(response.payload);
		} else {
			throw new Error(response.message); //??
		}
	}

	hydrateMeeting(model: MeetingModel | null) {
		this.meetingSource.next(model);
		this.onChangeSource.next('meeting');
		//this.validateMeetingReady(model);
	}

	initializeMeeting(wizardModel: MeetingWizardModel) {
		this.meetingId = wizardModel.activeMeeting?.meetingId;
		this.meeting = wizardModel.activeMeeting;
		this.committeeId = wizardModel.activeMeeting?.committeeId;
		//console.log("committeeId set from initializeMeeting()", this.committeeId);
		this.hydrateMeeting(wizardModel.activeMeeting);
		this.hydrateMeetingLocations(wizardModel.meetingLocations);
		this.hydrateAgendaSections(wizardModel.agendaSections);
		this.hydrateAgendaItems(wizardModel.agendaItems);
		this.hydrateDocuments(wizardModel.documents);
		this.hydrateDocumentAssignments(wizardModel.documentAssignments);
		this.refreshCommitteeMembers();
		//If this is the entry point
		this.validateMeetingReady(wizardModel.activeMeeting);
	}

	validateMeetingReady(model: MeetingModel | null) {
		try {
			// Guarantee that meeting is ready
			// Check if the model, meetingId, and committeeId are not null or undefined
			const isModelValid = model !== null && model !== undefined;
			const isMeetingIdValid = this.meetingId !== null && this.meetingId !== undefined && this.meetingId !== '';
			const isCommitteeIdValid = this.committeeId !== null && this.committeeId !== undefined && this.committeeId !== '';

			// Additionally, check if the meetingSource actually has a meeting object
			const isMeetingSourceValid = this.meetingSource.getValue() !== null && this.meetingSource.getValue() !== undefined;

			// Only emit true for isReady if all conditions are met
			if (isModelValid && isMeetingIdValid && isCommitteeIdValid && isMeetingSourceValid) {
				this.isReadySource.next(true);
			} else {
				// Optionally, you could emit false if the conditions are not met, or handle this case differently
				this.isReadySource.next(false);
			}
		} catch (error) {}
	}

	private meetingExpirySource = new BehaviorSubject<Date | null>(null);
	meetingExpiryRefresh$ = this.meetingExpirySource.asObservable();

	async refreshMeetingExpiry(expiryDate: Date) {
		this.hydrateMeetingExpiry(expiryDate);
	}

	hydrateMeetingExpiry(model: Date | null) {
		this.meetingExpirySource.next(model);
		//this.onChangeSource.next(true);
	}

	private meetingLocationsSource = new BehaviorSubject<MeetingLocationModel[] | null>(null);
	meetingLocationsReady$ = this.meetingLocationsSource.asObservable();

	async refreshMeetingLocations(meetingId: string) {
		let response = await this.meetingLocationsService.getByMeetingId(
			new IdRequestModel({
				id: meetingId,
			})
		);

		if (response.isSuccess) {
			this.hydrateMeetingLocations(response.payload);
		} else {
			throw new Error(response.message); //??
		}
	}

	hydrateMeetingLocations(model: MeetingLocationModel[]) {
		this.meetingLocationsSource.next(model);
		this.onChangeSource.next('locations');
	}

	private sectionsSource = new BehaviorSubject<AgendaSectionModel[] | null>(null);
	sectionsReady$ = this.sectionsSource.asObservable();

	async refreshSections(meetingId: string) {
		let response = await this.agendaSectionService.getAgendaSectionsByMeetingId(
			new IdRequestModel({
				id: meetingId,
			})
		);

		if (response.isSuccess) {
			this.hydrateAgendaSections(response.payload);
		} else {
			throw new Error(response.message);
		}
	}

	hydrateAgendaSections(model: AgendaSectionModel[]) {
		this.sectionsSource.next(model);
		this.onChangeSource.next('agendaSections');
	}

	/**
    CommitteeMembers: We're going to have to use this for both special access and guest access so good to have here
    */
	private committeeMembers = new BehaviorSubject<UserModel[] | null>(null);
	committeeMembersReady$ = this.committeeMembers.asObservable();

	async refreshCommitteeMembers() {
		if (!this.committeeId) throw new Error('Committee Id has not been set');

		let response = await this.usersService.getCommitteeUsers(new IdRequestModel({ id: this.committeeId! }));

		if (response.isSuccess) {
			this.hydrateCommitteeMembers(response.payload);
		} else {
			throw new Error(response.message);
		}
	}
	hydrateCommitteeMembers(model: UserModel[]) {
		this.committeeMembers.next(model);
		this.onChangeSource.next('committeeMembers');
	}

	private agendaItemsSource = new BehaviorSubject<AgendaItemModel[] | null>(null);
	agendaItemsReady$ = this.agendaItemsSource.asObservable();

	async refreshAgendaItems(meetingId: string) {
		let response = await this.agendaItemService.getAllAgendaItemsByMeetingId(
			new GetAllAgendaItemsRequestModel({
				meetingId: meetingId,
			})
		);

		if (response.isSuccess) {
			this.hydrateAgendaItems(response.payload);
		} else {
			throw new Error(response.message); //??
		}
	}

	hydrateAgendaItems(model: AgendaItemModel[]) {
		this.agendaItemsSource.next(model);
		this.onChangeSource.next('agendaItems');
	}

	private documentsSource = new BehaviorSubject<DocumentModel[] | null>(null);
	documentsReady$ = this.documentsSource.asObservable();

	async refreshDocuments(meetingId: string) {
		let response = await this.documentService.getDocumentsByMeetingId(
			new IdRequestModel({
				id: meetingId,
			})
		);

		if (response.isSuccess) {
			this.hydrateDocuments(response.payload);
		} else {
			throw new Error(response.message); //??
		}
	}

	hydrateDocuments(model: DocumentModel[]) {
		this.documentsSource.next(model);
		this.onChangeSource.next('documents');
	}

	async getDocumentAssignments(meetingId: string, documentId: string): Promise<DocumentAssignmentsModel[]> {
		await this.refreshDocumentAssignments(meetingId);
		return this.documentAssignmentsSource.getValue() ?? [];
	}

	private documentAssignmentsSource = new BehaviorSubject<DocumentAssignmentsModel[] | null>(null);
	documentAssignmentsReady$ = this.documentAssignmentsSource.asObservable();

	async refreshDocumentAssignments(meetingId: string) {
		let response = await this.documentAssignmentService.getDocumentAssignmentsByMeetingId(
			new IdRequestModel({
				id: meetingId,
			})
		);

		if (response.isSuccess) {
			this.hydrateDocumentAssignments(response.payload);
		} else {
			throw new Error(response.message); //??
		}
	}

	hydrateDocumentAssignments(model: DocumentAssignmentsModel[]) {
		this.documentAssignmentsSource.next(model);
		this.onChangeSource.next('documentAssignments');
	}

	private documentViewedOnAgendaItemSource = new BehaviorSubject<string | null>(null);
	documentViewedOnAgendaItem$ = this.documentViewedOnAgendaItemSource.asObservable();

	documentViewedOnAgendaItem(model: string | null) {
		this.documentViewedOnAgendaItemSource.next(model);
	}

	//#region Viewed Documents tracking
	// 1. Depends on isUpdate being set by SetUpdateFlags of GetViewerDocumentsProcessor in API solution
	// 2. An existing record in table DocumentMetrics means the document was once viewed, which is
	// a prerequisite to being a candidate for marking "as updated" since it was last viewed.
	private viewedDocuments = new BehaviorSubject<Set<string | null>>(new Set());
	viewedDocuments$ = this.viewedDocuments.asObservable();
	setViewedDocument(model: string | null) {
		const currentItems = new Set(this.viewedDocuments.value);
		currentItems.add(model);
		this.viewedDocuments.next(currentItems);
	}
	clearViewedDocument(model: string | null) {
		const currentItems = new Set(this.viewedDocuments.value);
		currentItems.delete(model);
		this.viewedDocuments.next(currentItems);
	}
	clearViewedDocuments() {
		this.viewedDocuments.next(new Set());
	}

	// showUpdated
	// observe current user
	user$ = this.authenticationService.currentUser().pipe(filter(user => user !== undefined));

	// make an observable of whether user has enabled document notifications
	// with current user, observe whether document notifications is enabled
	documentNotificationEnabled$ = this.user$.pipe(
		// return an observable of the user's document notifications
		takeUntil(this._destroyed$),
		switchMap(user =>
			// return observable from promise
			from(
				// get user information from keycloak id
				this.usersService.getUserByKeycloakId({
					id: user?.keycloakUserId ?? '',
				})
			).pipe(
				//tap(response => console.log(`UpdateBadgeComponent response`, response)),
				// transform (map) the response to return the user's document notifications
				map(response => {
					const notifications = response?.isSuccess === true ? response.payload.enableDocumentNotifications : false;
					return notifications;
				})
			)
		)
	);
	//#endregion Viewed Documents

	/*

    DEPRECATED EVENTS

    */

	private sectionEditModeChangedSource = new BehaviorSubject<boolean>(false);
	editModeChanged$ = this.sectionEditModeChangedSource.asObservable();

	announceSectionEditModeChanged(isInEditMode: boolean) {
		this.sectionEditModeChangedSource.next(isInEditMode);
	}
}

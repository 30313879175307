import { DocumentsService } from '@ecom/ecom-app-generated/Services/DocumentsService';
import {
    ITask,
    TaskResult,
} from '@ecom/app-workflows/core/task-pipeline/task-interface';

export class GetViewerDocumentsFromServerTask implements ITask {
    constructor(private documentsService: DocumentsService) {}
    async execute(context: Map<string, any>): Promise<TaskResult> {
        try {
            const result = await this.documentsService.getViewerDocuments();
            if (!result.isSuccess)
                throw new Error(
                    `An error has occurred while getting documents from server.`
                );

            //save the documents for other tasks in pipeline
            var documents = result.payload;
            context.set('GetViewerDocumentsFromServerTaskResult', documents);

            return {
                success: true,
                message: 'Get viewer documents from server succeeded.',
                recordCount: documents?.length ?? 0,
            };
        } catch (error: any) {
            return {
                success: false,
                message: `Get viewer documents from server failed.`,
                recordCount: 0,
                data: error.message,
            };
        }
    }
}

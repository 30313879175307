import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppWorkflowsService } from '@ecom/app-workflows/core/app-workflow-service';
import { TaskResult } from '@ecom/app-workflows/core/task-pipeline/task-interface';

interface WorkflowRun {
    runId: number;
    workflowName?: string;
    taskResults: TaskResult[];
    startDate?: Date;
    duration?: number;
    visible: boolean;
    status: 'Running' | 'Complete' | 'Error';
}

@Component({
    selector: 'app-workflows',
    templateUrl: './workflows.component.html',
    providers: [DatePipe], // Provide DatePipe here
    styleUrls: ['./workflows.component.scss'],
})
export class WorkflowsComponent implements OnInit {
    workflowRuns: Map<number, WorkflowRun> = new Map<number, WorkflowRun>();
    // Add visibility state tracking
    detailsVisibility: Map<number, boolean> = new Map<number, boolean>();

    constructor(private workflowService: AppWorkflowsService) {}

    async ngOnInit(): Promise<void> {
        this.workflowService.onTaskCompleted.subscribe((result: TaskResult) => {
            // Check if the workflowRun already exists for the runId
            if (!this.workflowRuns.has(result.runId!)) {
                this.workflowRuns.set(result.runId!, {
                    status: result.isWorkflowComplete ? 'Complete' : 'Running',
                    runId: result.runId!,
                    workflowName: result.workflow,
                    taskResults: [result],
                    startDate: result.createDate,
                    duration: result.duration,
                    visible: false,
                });
            } else {
                // Existing WorkflowRun, add new result
                const workflowRun = this.workflowRuns.get(result.runId!);
                if (workflowRun) {
                    workflowRun.taskResults.push(result);

                    // Simply add the current task's duration to the total duration
                    workflowRun.duration! += result.duration || 0;

                    if (result.isWorkflowComplete) {
                        workflowRun.status = 'Complete';
                    }
                }
            }

            // Initialize visibility state to false for each new workflowRun
            if (!this.detailsVisibility.has(result.runId!)) {
                this.detailsVisibility.set(result.runId!, false); // Start with details hidden
            }
        });
    }

    // Function to toggle visibility
    toggleDetails(runId: number): void {
        const currentState = this.detailsVisibility.get(runId);
        this.detailsVisibility.set(runId, !currentState);
    }

    toggleDetailVisibility(event: any) {
        const target = event.currentTarget;
        const detailDiv = target.querySelector('.task-details');
        if (detailDiv) {
            detailDiv.classList.toggle('show-details');
        }
    }

    countDownloadingTasks(run: WorkflowRun): number {
        return run.taskResults.filter(task =>
            task.message.includes('Downloaded')
        ).length;
    }
}

import { Injectable } from '@angular/core';
import { CodeModel } from '@ecom/ecom-app-generated/Models/CodeModel';
import { CodesService } from '@ecom/ecom-app-generated/Services/CodesService';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BannerNotificationService {
    /* Optimized Banner notification loading events */
    private adminNotificationSource = new BehaviorSubject<CodeModel | null>(
        null
    );
    adminNotificationReady$ = this.adminNotificationSource.asObservable();

    private viewerNotificationSource = new BehaviorSubject<CodeModel | null>(
        null
    );
    viewerNotificationReady$ = this.viewerNotificationSource.asObservable();

    constructor(private codeService: CodesService) {
        this.refreshAdminNotification();
        this.refreshViewerNotification();
    }

    public async refreshAdminNotification() {
        let response = await this.codeService.getAdminBannerCode();

        if (response.isSuccess) {
            this.hydrateAdminBanner(response.payload);
        } else {
            console.log(response.errors);
        }
    }

    private hydrateAdminBanner(model: CodeModel | null) {
        this.adminNotificationSource.next(model);
    }

    //viewer
    public async refreshViewerNotification() {
        let response = await this.codeService.getViewerBannerCode();

        if (response.isSuccess) {
            this.hydrateViewerBanner(response.payload);
        } else {
            console.log(response.errors);
        }
    }

    private hydrateViewerBanner(model: CodeModel | null) {
        this.viewerNotificationSource.next(model);
    }
}

import {
    HttpClientModule,
    HttpClient,
    HttpBackend,
    HttpXhrBackend,
} from '@angular/common/http';
import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ConfigService } from '@ecom/common/services/config.service';
import { KeycloakService } from 'keycloak-angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { FileViewerModule } from '@ecom/file-viewer.module';
import { Constants } from '@ecom/common/helpers/constants';

if (environment.production) {
    enableProdMode();
}

/**
 * @summary Moved the keycloakService into main.ts as prior to this, the entire app would have to bootstrap only to find out the user didn't have a token, and have to send user off to Keycloak - not performant
 * Now the app initializes KeycloakService and inits it here, so that before we do anything (aka Bootstrap and standup app) we already have a token (what we need) from Keycloak and Azure (M365)
 */
async function highPerformanceBootstrap() {
    try {
        const params = new URLSearchParams(window.location.search);

        if (params.get(Constants.QueryParams.FileView)) {
            document.getElementById('loader')?.remove();
            document.getElementById('loadingStatus')?.remove();
            document.getElementById('loadingStatus2')?.remove();

            console.log('Loading file module');
            platformBrowserDynamic()
                .bootstrapModule(FileViewerModule)
                .catch(error => {
                    console.error('Bootstrap failed within FileModule', error);
                });

            return;
        }
        //Injector is required as you can't do dependancy injection on main.ts given it's not a class, it is an entry point of the application
        const tempInjector = Injector.create({
            providers: [
                {
                    provide: HttpClientModule,
                    useClass: HttpClientModule,
                    deps: [],
                },
                {
                    provide: HttpClient,
                    useClass: HttpClient,
                    deps: [HttpBackend],
                },
                { provide: HttpBackend, useClass: HttpXhrBackend, deps: [] },
                {
                    provide: ConfigService,
                    useClass: ConfigService,
                    deps: [HttpClient],
                },
                {
                    provide: KeycloakService,
                    useClass: KeycloakService,
                    deps: [],
                },
            ],
        });

        const configService = tempInjector.get(ConfigService);
        const keycloakService = tempInjector.get(KeycloakService);

        // Initialize Keycloak
        await appInitializerForKeycloak(configService, keycloakService);

        const isLoggedIn = await keycloakService.isLoggedIn();
        console.log('User is logged in', isLoggedIn);
        const currentRoute = window.location.pathname;

        if (!isLoggedIn && currentRoute.toLocaleLowerCase() !== '/signout') {
            // Redirect to Keycloak
            redirectToKeycloak(tempInjector, configService, keycloakService);
        } else {
            // Bootstrap the AppModule and pass the ConfigService and KeycloakService to the bootstrap AppModule as recreating them is not as performant, given they already exist here
            platformBrowserDynamic([
                { provide: ConfigService, useValue: configService }, // Add this line
                { provide: KeycloakService, useValue: keycloakService },
            ])
                .bootstrapModule(AppModule)
                .then(() => {
                    setTimeout(removeProgressLoader, 500);
                })
                .catch(error => {
                    console.error('Bootstrap failed within AppModule', error);
                });
        }
    } catch (error) {
        if (error === 'Redirected to Keycloak') {
            // Do nothing, execution was intentionally halted
        } else {
            console.error('highPerformanceBootstrap() failed', error);
        }
    }
}

async function removeProgressLoader() {
    // Remove the spinner and status updates after app is bootstrapped
    const loader = document.getElementById('loader');
    if (loader) {
        loader.style.display = 'none';
    }
    const loadingStatus = document.getElementById('loadingStatus2');
    if (loadingStatus) {
        loadingStatus.style.display = 'none';
    }
}

/**
 * @summary Initialize Keycloak with realm and other keycloak details
 * EnableBearerToken:true ensures that once a user has a token from keycloak the token is now included in every outgoing call that we make GET/POST/PATCH/DELETE in the header without having to write a custom httpInterceptor
 * @param configService
 * @param keycloakService
 * @returns
 */
async function appInitializerForKeycloak(
    configService: ConfigService,
    keycloakService: KeycloakService
): Promise<Boolean> {
    try {
        const result = await keycloakService.init({
            config: {
                url: environment.config.keycloakSettings.url,
                realm: environment.config.keycloakSettings.realm,
                clientId: environment.config.keycloakSettings.clientId,
            },
            initOptions: {
                checkLoginIframe: false,
            },
            enableBearerInterceptor: true,
            bearerExcludedUrls: ['/assets'],
        });
        return result;
    } catch (error) {
        console.error('Failed to initialize Keycloak:', error);
        return false;
    }
}

/**
 * @summary If user is not LoggedIn() (isLoggedIn() returns false) then we redirect to Keycloak to auth the user
 * idpHint of "core" means that instead of using the default Keycloak Login, we actually skip this and get keycloak to push user to the IdentityProvider workflow (M365 login)
 * @param tempInjector
 * @param configService
 * @param keycloakService
 */
async function redirectToKeycloak(
    tempInjector: Injector,
    configService: ConfigService,
    keycloakService: KeycloakService
) {
    let redirectUrl = window.location.href;
    let ignoreString = 'ignoreHint=true';

    if (redirectUrl.includes(ignoreString)) {
        //strip the ignore from the redirect
        redirectUrl = redirectUrl.replace(/[?&]ignoreHint=true/, '');
        console.log('redirecting to ', redirectUrl);
        await keycloakService.login({ redirectUri: redirectUrl });
        localStorage.setItem('ignoreHint', JSON.stringify(true));
    } else {
        await keycloakService.login({
            redirectUri: redirectUrl,
            idpHint: 'core',
        });
    }

    // throw new Error("Redirected to Keycloak"); // Halt execution after redirect
}

highPerformanceBootstrap();

<ng-container *ngIf="headerType === 'secure'">
	<nav class="uk-navbar-container uk-navbar-transparent">
		<div class="uk-container uk-container-expand" style="padding: 5px 10px">
			<div uk-navbar="" class="uk-navbar">
				<div class="uk-navbar-left">
					<div class="logo">
						<div class="uk-flex" style="padding-left: 10px; margin-bottom: 0px; vertical-align: middle">
							<div class="uk-flex uk-flex-middle uk-width-auto" style="margin-right: 10px">
								<a
									*ngIf="isAdmin == true"
									aria-label="Digital Meeting Experience"
									title="Version API:{{ version?.apiVersionNumber }} / WEB:{{ version?.webVersionNumber }}"
									[routerLink]="['/admin/home']">
									<app-logo></app-logo>
								</a>
								<a
									*ngIf="isAdmin == false"
									aria-label="Digital Meeting Experience"
									title="Version API:{{ version?.apiVersionNumber }} / WEB:{{ version?.webVersionNumber }}"
									[routerLink]="['/viewer/meetings']">
									<app-logo></app-logo>
								</a>
							</div>
							<div
								class="title uk-width-expand uk-flex uk-flex-middle"
								[ngStyle]="(browserService.isMobile$ | async) ? null : { whiteSpace: 'nowrap' }">
								<p>Digital Meeting Experience</p>
							</div>
						</div>
					</div>
				</div>
				<div class="uk-navbar-right">
					<ul class="uk-navbar-nav menu-nav">
						<li class="uk-visible@m">
							<a
								[routerLink]="['/viewer/meetings']"
								routerLinkActive="active"
								aria-haspopup="false"
								role="menuitem"
								(click)="reloadIfSameUrl('/viewer/meetings')"
								>View Meetings</a
							>
						</li>
						<li class="uk-visible@m" *ngIf="isAgendaPreview">
							<a [routerLink]="['/viewer/agendapreview']" aria-haspopup="false" routerLinkActive="active" role="menuitem" class="agenda-preview"
								>CPC Materials</a
							>
						</li>
						<li class="uk-hidden@s">
							<a
								href=""
								role="button"
								aria-expanded="false"
								aria-haspopup="true"
								aria-controls="mobile-nav"
								(click)="toggle_MobileNav($event)"
								aria-label="Open mobile menu"
								>Menu<mat-icon>expand_more</mat-icon></a
							>
						</li>
						<li class="uk-visible@s">
							<a href="" role="button" aria-haspopup="true" aria-controls="dropMenu" aria-label="Open menu"
								>Menu<mat-icon>expand_more</mat-icon></a
							>
						</li>
						<div
							id="dropMenu"
							class="uk-navbar-dropdown uk-drop uk-navbar-dropdown-width-2 desktop-ddn uk-visible@s"
							uk-drop="mode: click; cls-drop: uk-navbar-dropdown; boundary: !.uk-navbar; flip: false">
							<ul class="uk-nav uk-navbar-dropdown-nav" style="position: relative; display: flex; flex-direction: column; width: 100%">
								<ul style="position: relative; display: flex; flex-direction: column; width: 100%">
									<app-user-profile-menu [isAdmin]="false" [user]="user" (onOpenFeedbackModal)="openFeedbackModal()"></app-user-profile-menu>
								</ul>
							</ul>
						</div>
					</ul>
				</div>
			</div>
		</div>
		<div class="uk-width-1-1">
			<hr class="uk-margin-remove" />
		</div>
		<app-banner-notications></app-banner-notications>
	</nav>

	<div [class]="'menu-nav-mobile ' + (isMobileNavVisible ? 'animate-open' : 'uk-hidden')" id="mobile-nav">
		<ul class="uk-list uk-list-divider">
			<ul style="position: relative; display: flex; flex-direction: column; width: 100%">
				<app-user-profile-menu [isAdmin]="false" [user]="user" (onOpenFeedbackModal)="openFeedbackModal()"></app-user-profile-menu>
			</ul>
		</ul>
	</div>
</ng-container>

<app-feedback #feedbackComp [dialogId]="generatedDialogId"></app-feedback>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
    transform(fileSize: number): string {
        if (fileSize > 0) {
            let mb = fileSize / 1e6;

            return mb > 1
                ? mb.toFixed(2) + ' MB'
                : Math.round(mb * 1000) + ' KB';
        } else {
            return '';
        }
    }
}

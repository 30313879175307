<div class="uk-flex uk-flex-center uk-flex-middle" uk-height-viewport>
    <div>
        <div>
            <h2>No permissions assigned</h2>
        </div>
        <div class="uk-grid">
            <app-goa-callout inputType="info">
                <div data-cy="info-AccessDenied">
                    You have not been granted the appropriate access in Keycloak
                    to view this page. If you believe you are indeed authorized,
                    please contact
                    <a href="mailto: dmx-support&#64;gov.ab.ca"
                        >dmx-support&#64;gov.ab.ca</a
                    >
                    for troubleshooting. <br /><br />
                    If you need to seek authorization, please contact the
                    appropriate secretariat:
                    <ul>
                        <li>
                            Cabinet, Emergency Management Cabinet Committee,
                            Pre-brief:
                            <a href="mailto: CCO&#64;gov.ab.ca"
                                >CCO&#64;gov.ab.ca</a
                            >
                        </li>
                        <li>
                            Cabinet Policy Committees:
                            <strong
                                >Please contact the PCO business area
                                directly</strong
                            >
                        </li>
                        <li>
                            Treasury Board Secretariat:
                            <a href="mailto: goa.tbs&#64;gov.ab.ca"
                                >goa.tbs&#64;gov.ab.ca</a
                            >
                        </li>
                    </ul>
                </div>
            </app-goa-callout>
        </div>
    </div>
</div>

import { Constants } from '@ecom/common/helpers/constants';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';
import {
    ITask,
    TaskResult,
} from '@ecom/app-workflows/core/task-pipeline/task-interface';
import { IdRequestModel } from '@ecom/ecom-app-generated/Models/IdRequestModel';
import { DocumentsService } from '@ecom/ecom-app-generated/Services/DocumentsService';

export class GetDocumentThumbnailsTask implements ITask {
    totalThumbnailsDownloads = 0;
    maxThumbnailsAtAnyOneTime = 5;
    constructor(
        private documentsService: DocumentsService,
        private localDocumentsService: LocalDocumentsService
    ) {}
    async execute(
        context: Map<string, any>,
        onPartialResult?: (result: TaskResult) => void
    ): Promise<TaskResult> {
        try {
            //onPartialResult allows a task to return many TaskResults that are partials
            //Currentlly this is not used as we decided we didn't want to know about every thumbnail download but it is here if required

            this.totalThumbnailsDownloads = 0;

            //Ensure we close the connection
            var detailedMessageResult =
                await this.getDocumentThumbnailsTask(context);

            return {
                success: true,
                message: `Get document thumbnails succeeded.`,
                data: detailedMessageResult,
                recordCount: this.totalThumbnailsDownloads,
            };
        } catch (error: any) {
            return {
                success: false,
                message: `Get document thumbnails failed`,
                data: error.message,
            };
        }
    }

    async getDocumentThumbnailsTask(
        context: Map<string, any>
    ): Promise<string> {
        let connection: IDBDatabase | null = null;

        try {
            const startTime = performance.now();

            //open connection and map
            connection = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForViewer
            );
            const documentModels =
                await this.localDocumentsService.getAllDocumentsNotSyncedWithThumbnail(
                    connection
                );
            const documentIds = documentModels.map(
                documentModel => documentModel.documentId
            );
            const limitedDocumentIds = documentIds;

            if (limitedDocumentIds.length > 0) {
                await this.asyncPool(
                    this.maxThumbnailsAtAnyOneTime,
                    limitedDocumentIds,
                    documentId => this.fetchThumbnail(documentId)
                );

                // Calculate the total duration
                const endTime = performance.now();
                const totalDuration = Math.round(endTime - startTime); // Duration in milliseconds, rounded

                var detailedMessage = `Downloaded ${this.totalThumbnailsDownloads} of ${documentIds.length} in ${totalDuration} ms`;
                return detailedMessage;
            } else {
                // Calculate the total duration
                const endTime = performance.now();
                const totalDuration = Math.round(endTime - startTime); // Duration in milliseconds, rounded

                var detailedMessage = `All document thumbnails in sync`;
                return detailedMessage;
            }
        } catch (error) {
            throw error;
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection) {
                connection.close();
                connection = null;
            }
        }
    }

    private async fetchThumbnail(documentId: string): Promise<void> {
        let connection: IDBDatabase | null = null;

        try {
            var thumbnailResult =
                await this.documentsService.getDocumentThumbnail(
                    new IdRequestModel({ id: documentId })
                );

            if (thumbnailResult.isSuccess) {
                connection = await this.localDocumentsService.openConnection(
                    Constants.LocalStorage.DocumentsForViewer
                );
                var document = await this.localDocumentsService.getDocument(
                    connection,
                    documentId
                ); //returns null if not found
                if (document) {
                    await this.localDocumentsService.addUpdateThumbnail(
                        connection,
                        documentId,
                        thumbnailResult.payload.thumbnail
                    );
                    this.totalThumbnailsDownloads++;
                }

                document = null;
            }
        } catch (error) {
            console.error(
                `Failed to get thumbnail for document ${documentId}:`,
                error
            );
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection) {
                connection.close();
                connection = null;
            }
        }
    }

    private async asyncPool(
        poolLimit: number,
        array: any[],
        iteratorFn: (value: any) => Promise<void>
    ): Promise<void[]> {
        const ret = [];
        const executing: any[] = [];
        for (const item of array) {
            const p = Promise.resolve().then(() => iteratorFn(item));
            ret.push(p);

            if (poolLimit <= array.length) {
                const e = p.then(() =>
                    executing.splice(executing.indexOf(e), 1)
                ) as Promise<void>;
                executing.push(e);
                if (executing.length >= poolLimit) {
                    await Promise.race(executing);
                }
            }
        }
        return Promise.all(ret);
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from '@ecom/app.component';
import { AuthGuard } from '@ecom/common/authentication/guards/auth.guard';
import { BannerNoticationsComponent } from '@ecom/common/components/banner-notications/banner-notications.component';
import { FeedbackComponent } from '@ecom/common/components/feedback/feedback.component';
import { GoaCalloutComponent } from '@ecom/common/components/goa-callout/goa-callout.component';
import { GoaErrorsComponent } from '@ecom/common/components/goa-errors/goa-errors.component';
import { UpdateBadgeComponent } from '@ecom/common/components/update-badge/update-badge.component';
import { BadgeComponent } from '@ecom/common/components/badge/badge.component';
import { VersionComponent } from '@ecom/common/components/version/version.component';
import { AppBannerComponent } from '@ecom/common/layout/app-banner/app-banner.component';
import { AppLogoComponent } from '@ecom/common/layout/app-logo/app-logo.component';
import { BetaCalloutComponent } from '@ecom/common/layout/beta-callout/beta-callout.component';
import { FileViewerFloatingActionButtonComponent } from '@ecom/common/layout/file-viewer-floating-action-button/file-viewer-floating-action-button.component';
import { LayoutFooterComponent } from '@ecom/common/layout/layout-footer/layout-footer.component';
import { LayoutHeaderNewComponent } from '@ecom/common/layout/layout-header-admin/layout-header-admin.component';
import { LayoutHeaderComponent } from '@ecom/common/layout/layout-header/layout-header.component';
import { UserProfileMenuComponent } from '@ecom/common/layout/user-profile-menu/user-profile-menu.component';
import { DeviceDatePipe } from '@ecom/common/pipes/device-date/device-date.pipe';
import { FileExtensionPipe } from '@ecom/common/pipes/documents/file-extension.pipe';
import { FileSizePipe } from '@ecom/common/pipes/documents/file-size.pipe';
import { FileWithoutExtensionPipe } from '@ecom/common/pipes/documents/file-without-extension.pipe';
import { MstDatePipe } from '@ecom/common/pipes/mst-date/mst-date.pipe';
import { TimestampPipe } from '@ecom/common/pipes/timestamp.pipe';
import { MaterialModule } from '@ecom/modules/material/material.module';
import { AccessDeniedComponent } from '@ecom/pages/access-denied/access-denied.component';
import { AccessRequestedComponent } from '@ecom/pages/access-requested/access-requested.component';
import { CalendarBadgeComponent } from '@ecom/pages/admin/meetings/_components/calendar-badge/calendar-badge.component';
import { DateCountdownPipe } from '@ecom/pages/admin/meetings/_components/date-countdown/date-countdown.pipe';
import { MeetingAgendaReportComponent } from '@ecom/pages/admin/reports/meeting-agenda-report/meeting-agenda-report.component';
import { AgendaDocumentLinkComponent } from '@ecom/pages/agenda-preview/_components/agenda-document-link/agenda-document-link.component';
import { AgendaPreviewListComponent } from '@ecom/pages/agenda-preview/agenda-preview-list/agenda-preview-list.component';
import { ConfigComponent } from '@ecom/pages/console/config/config.component';
import { FaqComponent } from '@ecom/pages/faq/faq.component';
import { HealthcheckComponent } from '@ecom/pages/healthcheck/healthcheck.component';
import { HelpCentreComponent } from '@ecom/pages/help-centre/help-centre.component';
import { LocalStorageComponent } from '@ecom/pages/local-storage/local-storage.component';
import { ViewersMeetingsSkeletonLoaderComponent } from '@ecom/pages/meetings/meetings-list/_components/viewers-meetings-skeleton-loader/viewers-meetings-skeleton-loader.component';
import { PageNotFoundComponent } from '@ecom/pages/page-not-found/page-not-found.component';
import { SignInComponent } from '@ecom/pages/sign-in/sign-in.component';
import { SignOutComponent } from '@ecom/pages/sign-out/sign-out.component';
import { UserSettingsComponent } from '@ecom/pages/user-settings/user-settings.component';
import { WorkflowsComponent } from '@ecom/pages/workflows/workflows/workflows.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { AutoEventDirective } from '../../common/directives/auto-event';

const routes: Routes = [
	/*
	 * In the routing configuration below, where roles are passed as an array of strings in the `data` attribute of the route object.
	 *
	 * Here's how it works:
	 *
	 * 1. When a route is requested, Angular checks if it has a `canActivate` attribute.
	 *    If it does, it runs the specified guard (in this case `AuthGuard`) before deciding whether to activate the route.
	 *
	 * 2. Inside the `AuthGuard`, the `isAccessAllowed` function is called. This function receives the requested route
	 *    and its state as parameters.
	 *
	 * 3. The `AuthGuard` checks if the user is logged in by calling `this.keycloakService.isLoggedIn()`.
	 *
	 * 4. If the user is logged in, it fetches the roles from the `data` attribute of the requested route
	 *    (i.e., `route.data['roles']`). This array of roles represents the roles needed to access the route.
	 *
	 * 5. It then fetches the roles of the currently logged-in user by calling `this.keycloakService.getUserRoles()`.
	 *    This function communicates with Keycloak and retrieves the roles assigned to the user upon login.
	 *
	 * 6. If no roles are specified on the route data, the `AuthGuard` checks if the user has at least the 'dmx-user' role.
	 *    If the user has this role, they are granted access.
	 *
	 * 7. If roles are specified on the route data, the `AuthGuard` checks if the user has at least one of these roles.
	 *    If the user has one of these roles, they are granted access.
	 *
	 * 8. If the user doesn't have the required roles, they are redirected to the 'access-denied' route.
	 *
	 * The roles specified in the route configuration (e.g., 'dmx-admin') are used to control access to specific routes.
	 * For example, the 'meetings' route can only be accessed by users with the 'dmx-admin' role.
	 */
	{
		path: '',
		component: SignInComponent,
		canActivate: [AuthGuard],
		data: { roles: ['dmx-admin', 'dmx-user'] },
	},
	{ path: 'access-requested', component: AccessRequestedComponent },
	{
		path: 'config',
		component: ConfigComponent,
		canActivate: [AuthGuard],
		data: { roles: ['dmx-user'] },
	},
	{
		path: 'signin',
		component: SignInComponent,
		canActivate: [AuthGuard],
		data: { roles: ['dmx-admin', 'dmx-user'] },
	},
	{ path: 'signout', component: SignOutComponent },
	{ path: 'access-denied', component: AccessDeniedComponent },

	{
		path: 'workflows',
		component: WorkflowsComponent,
		canActivate: [AuthGuard],
		data: { roles: ['dmx-user'] },
	},
	{
		path: 'help',
		component: HelpCentreComponent,
		canActivate: [AuthGuard],
		data: { roles: ['dmx-user'] },
	},
	{
		path: 'faq',
		component: FaqComponent,
		canActivate: [AuthGuard],
		data: { roles: ['dmx-user'] },
	},
	{
		path: 'local-storage',
		component: LocalStorageComponent,
		canActivate: [AuthGuard],
		data: { roles: ['dmx-user'] },
	},
	{
		path: 'logs',
		component: HealthcheckComponent,
		canActivate: [AuthGuard],
		data: { roles: ['dmx-user'] },
	},
];

const MODULES = [
	AppComponent,
	PageNotFoundComponent,
	LayoutHeaderComponent,
	LayoutFooterComponent,
	AppBannerComponent,
	SignInComponent,
	SignOutComponent,
	AccessRequestedComponent,
	LayoutHeaderNewComponent,
	CalendarBadgeComponent,
	DateCountdownPipe,
	MstDatePipe,
	DeviceDatePipe,
	FileExtensionPipe,
	FileWithoutExtensionPipe,
	FileSizePipe,
	DeviceDatePipe,
	AccessDeniedComponent,
	BetaCalloutComponent,
	GoaErrorsComponent,
	GoaCalloutComponent,
	FileViewerFloatingActionButtonComponent,
	FeedbackComponent,
	VersionComponent,
	MeetingAgendaReportComponent,
	ViewersMeetingsSkeletonLoaderComponent,
	UserSettingsComponent,
	AgendaPreviewListComponent,
	HelpCentreComponent,
	HealthcheckComponent,
	FaqComponent,
	LocalStorageComponent,
	AppLogoComponent,
	UserProfileMenuComponent,
	WorkflowsComponent,
	AgendaDocumentLinkComponent, //for preview agenda
	TimestampPipe,
	BannerNoticationsComponent, //for admin / viewer banners
	UpdateBadgeComponent,
	BadgeComponent,
	DateCountdownPipe,
	AutoEventDirective,
];

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, MaterialModule, PDFExportModule, RouterModule.forChild(routes)],
	declarations: MODULES,
	exports: MODULES,
})
export class DmxCoreModule {}

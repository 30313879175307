import { Pipe, PipeTransform } from '@angular/core';
import { getDaysUntilMSTDate } from '@ecom/common/helpers/dateHelper';
import moment from 'moment';

@Pipe({
	name: 'dateCountdown',
})
export class DateCountdownPipe implements PipeTransform {
	transform(value?: any, ...args: any[]): any {
		//let [format] = args;
		if (value !== null) {
			const days = getDaysUntilMSTDate(value);

			let retVal = '';

			switch (true) {
				case days < 0:
					// past
					retVal = "<div class='badge-past'>Past</div>";
					break;
				case days == 0:
					// today
					retVal = "<div class='badge-today'>Today</div>";
					break;
				case days == 1:
					// in one day
					retVal = `<div class='badge-future'>in <b> ${days} </b> day</div>`;
					break;
				case days > 0:
					// return number of days
					retVal = `<div class='badge-future'>in <b> ${days} </b> days</div>`;
					break;
				default:
					//
					break;
			}

			return retVal;
		}
		return null;
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { getFileWithoutExtension } from '@ecom/common/helpers/file-helper';

@Pipe({
    name: 'fileWithoutExtension',
})
export class FileWithoutExtensionPipe implements PipeTransform {
    transform(documentName: string): string {
        return getFileWithoutExtension(documentName);
    }
}

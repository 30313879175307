<div uk-grid>
    <div class="uk-width-expand@m">
        <app-layout-header headerType="secure"></app-layout-header>
        <div class="uk-padding">
            <div>
                <a [routerLink]="['/help']" role="menuitem" data-cy="lnkSettings">Back to Help Center</a>
                <br /><br />
                <h2>Logs</h2>
                <div *ngIf="logs.length > 0">
                    <div *ngFor="let log of logs">
                        {{ log }}
                        <hr />
                    </div>
                </div>
                <div *ngIf="logs.length === 0">
                    <p>No logs exist.</p>
                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { CommitteeEmailModel } from '@ecom/ecom-app-generated/Models/CommitteeEmailModel';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class AgendaPreviewService {
    private getFieldArray(fileName: string) {
        return fileName.split('.')[0].split('-');
    }

    private getDateField(fileName: string) {
        return this.getFieldArray(fileName)[1];
    }

    private getMomentDate(fileName: string) {
        return moment.utc(new Date(this.getDateField(fileName))).startOf('day');
    }

    public validateDate(fileName: string) {
        let fileDate = this.getDateField(fileName);

        if (!fileDate) {
            return false;
        }
        //determine whether the date field is a valid date
        let retVal = moment(fileDate.trim()).isValid();

        return retVal;
    }

    getDate(fileName: string) {
        return this.getMomentDate(fileName).toDate();
    }

    getDateFormatted(fileName: string) {
        return this.getMomentDate(fileName).format('MMMM DD, YYYY');
    }

    getFileName(fileName: string) {
        return this.getFieldArray(fileName)[0];
    }

    getEmail(fileName: string, committeeEmails: CommitteeEmailModel[]) {
        const meeting = this.getFileName(fileName);
        return committeeEmails.find(x =>
            meeting
                .toLocaleLowerCase()
                .startsWith(x.committeeName.toLocaleLowerCase())
        )?.email;
    }
}

<app-layout-header-admin headerType="default"></app-layout-header-admin>

<div class="uk-padding">
	<ng-container *ngTemplateOutlet="breadcrumbNavLink; context: { link: ['/admin/home'], title: 'Back to Dashboard' }"></ng-container>
	<h2>Configuration</h2>
	<div>
		<app-goa-callout inputType="info">
			Angular configuration settings currently using environment file {{ getEnvironment() }}. Values cached in localstorage['{{ getCacheKey() }}']
		</app-goa-callout>
		<pre>{{ configJson | json }}</pre>
	</div>
	<div>
		<button (click)="refreshCache()">Clear Config Cache</button>
	</div>
</div>

<ng-template #breadcrumbNavLink let-link="link" let-title="title">
	<div class="uk-margin-bottom">
		<a [routerLink]="link">{{ title }}</a>
	</div>
</ng-template>

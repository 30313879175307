
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';
 
import { AddDocumentViewMetricModel } from '../Models/AddDocumentViewMetricModel'
import { DocumentMetricModel } from '../Models/DocumentMetricModel' 
import { IdRequestModel } from '../Models/IdRequestModel'
import { AgendaItemDocumentMetricsModel } from '../Models/AgendaItemDocumentMetricsModel'

@Injectable({
     providedIn: 'root'
 })
export class DocumentMetricsService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'documentmetrics');
    } 

  
  //post
  public addDocumentViewMetric(model: AddDocumentViewMetricModel) : Promise<ServiceResult<DocumentMetricModel>> {
      return this.post<ServiceResult<DocumentMetricModel>,AddDocumentViewMetricModel>('AddDocumentViewMetric',model);
  };
  //get
  public getMeetingDocumentMetrics(model: IdRequestModel) : Promise<ServiceResult<AgendaItemDocumentMetricsModel[]>> {
      return this.getWithQuery<ServiceResult<AgendaItemDocumentMetricsModel[]>,IdRequestModel>('GetMeetingDocumentMetrics',model);
  };
  
}
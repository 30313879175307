import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Constants } from '@ecom/common/helpers/constants';
import { BannerNotificationService } from '@ecom/common/services/banner-notification.service';
import { CodeModel } from '@ecom/ecom-app-generated/Models/CodeModel';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-banner-notications',
    templateUrl: './banner-notications.component.html',
    styleUrls: ['./banner-notications.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BannerNoticationsComponent implements OnInit, OnDestroy {
    @Input() notificationType: 'admin' | 'viewer' = 'viewer';

    adminNotification$: Subscription;
    viewerNotification$: Subscription;
    activeBanner: CodeModel | null = null;

    constructor(private bannerService: BannerNotificationService) {}

    ngOnInit(): void {
        if (this.notificationType === 'admin') {
            this.adminNotification$ =
                this.bannerService.adminNotificationReady$.subscribe(banner => {
                    if (
                        banner &&
                        banner.codeId ==
                            Constants.Codes.AdminBannerCode.toLocaleLowerCase()
                    ) {
                        this.activeBanner = banner;
                    } else this.activeBanner = null;
                });
        } else {
            this.viewerNotification$ =
                this.bannerService.viewerNotificationReady$.subscribe(
                    banner => {
                        if (
                            banner &&
                            banner.codeId ==
                                Constants.Codes.ViewerBannerCode.toLowerCase()
                        ) {
                            this.activeBanner = banner;
                        } else this.activeBanner = null;
                    }
                );
        }
    }

    ngOnDestroy(): void {
        if (this.adminNotification$) {
            this.adminNotification$.unsubscribe();
        }

        if (this.viewerNotification$) {
            this.viewerNotification$.unsubscribe();
        }
    }

    bannerHasValue() {
        return this.activeBanner && this.activeBanner?.value.trim() !== '';
    }
}

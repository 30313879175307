import { Constants } from '@ecom/common/helpers/constants';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';
import {
    ITask,
    TaskResult,
} from '@ecom/app-workflows/core/task-pipeline/task-interface';
import { Injectable } from '@angular/core';

export class VerifyAdminIndexDbExistsTask implements ITask {
    constructor(private localDocumentsService: LocalDocumentsService) {}
    async execute(context: Map<string, any>): Promise<TaskResult> {
        try {
            await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForAdmin
            );
            //context.set('indexDbExists', true);//leaving here so it's known you can add a result to context for other tasks
            return {
                success: true,
                message: 'Admin IndexDB verification succeeded.',
            };
        } catch (error: any) {
            return {
                success: false,
                message: `Admin IndexDB verification failed.`,
                data: error.message,
            };
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@ecom/common/services/config.service';

@Component({
    selector: 'app-config',
    templateUrl: './config.component.html',
    styleUrls: ['./config.component.scss'],
})
export class ConfigComponent implements OnInit {
    constructor(public configService: ConfigService) {}
    public allConfigEntries: any;

    public configJson: string;
    ngOnInit(): void {
        this.allConfigEntries = this.configService.getAll();

        this.configJson = JSON.parse(this.allConfigEntries);
    }

    public refreshCache() {
        this.configService.loadConfig(this.configService.getKey());
    }

    public getEnvironment(): string {
        return this.configService.getEnvironment();
    }

    public getCacheKey(): string {
        return this.configService.getKey();
    }
}

<!-- For desktop viewing, implement like:

    <app-goa-callout inputType="info">
        Documents attached to this meeting have been
        updated since your last login.
    </app-goa-callout>
-->
<div class="desktop">
    <div
        class="uk-grid-collapse uk-width-1-1 uk-grid-match goa-callout"
        uk-grid>
        <div
            class="uk-text-center uk-width-auto goa-callout-icon"
            [ngClass]="this.calloutType">
            <span class="material-icons" *ngIf="this.calloutType === 'info'"
                >info_outline</span
            >
            <span class="material-icons" *ngIf="this.calloutType === 'offline'"
                >wifi_off</span
            >
            <span class="material-icons" *ngIf="this.calloutType === 'event'"
                >calendar_month</span
            >
            <span
                class="material-icons"
                *ngIf="this.calloutType === 'emergency'"
                >warning_amber</span
            >
            <span class="material-icons" *ngIf="this.calloutType === 'warning'"
                >info_outline</span
            >
            <span class="material-icons" *ngIf="this.calloutType === 'success'"
                >check_circle_outline</span
            >
        </div>
        <div class="uk-width-expand goa-callout-content">
            <ng-content></ng-content>
        </div>
    </div>
</div>
<!-- For mobile viewing, implement like (note required ng-template):

    <app-goa-callout inputType="info">
        <ng-template>
            Documents attached to this meeting have been
            updated since your last login.
        </ng-template>
    </app-goa-callout>
-->
<div class="mobile">
    <div class="dmx-callout">
        <div class="callout-icon" [ngClass]="this.calloutType">
            <mat-icon>{{ icon }}</mat-icon>
        </div>
        <div class="callout-content">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
    </div>
</div>

export const environment = {
    production: false,
    configName: 'uat',
    version: '2.9.0.0',
    debug: false,
    config: {
        apiCoreBaseUrl: 'https://dmx-api-uat.azurewebsites.net',
        environment: 'User Acceptance Testing (UAT)',
        url: 'https://dmx-uat.gov.ab.ca/',
        keycloakSettings: {
            url: 'https://access-uat.alberta.ca/auth/',
            realm: 'Dd69b41e-1ae6-4684-9d49-8bd8559f298d',
            clientId: 'ecom-app',
        },
    },
};

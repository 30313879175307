import { Injectable } from '@angular/core';
import { Constants } from '@ecom/common/helpers/constants';
import { ConfigService } from '@ecom/common/services/config.service';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import { KeycloakUserModel } from '../models/keycloak-user-model';
import { UsersService } from '@ecom/ecom-app-generated/Services/UsersService';
import { LoggingService } from '@ecom/common/services/logging-service/logging-service.service';

@Injectable({
	providedIn: 'root',
})
/**
 * @summary Hand rolled authentication service to work with KeycloakService. Will try best to describe:
 *
 * This class is called during the APP_INITIALIZER
 *
 * What is APP_INITIALIZER?
 * - It is a feature in Angular that allows you to run code during the application's bootstrapping process.
 * - This can be useful for performing initial setup or configuration before the application starts running.
 *
 * How does the authentication service work with KeycloakService?
 * 1. If a user doesn't have a token, they are sent to Keycloak using KeycloakService.login() in main.ts; this was moved here because it's more performant, than standing up the app, then realizing the user doesn't have a token
 * 2. Once they are back from Keycloak, the application goes through the bootstrapping process in app.module.ts.
 * 3. The AuthenticationService is initialized using APP_INITIALIZER in main.ts
 * 5. This KeycloakUserModel is emitted to subscribers, primarily the Authentication NGXS store, for use throughout the application.
 */
export class AuthenticationService {
	//Behaviour subject was required because you can't create a new Observable without losing subscribers
	private userSubject = new BehaviorSubject<KeycloakUserModel | undefined>(undefined);
	private user$ = this.userSubject.asObservable();

	constructor(
		protected configService: ConfigService,
		private usersService: UsersService,
		private keycloakService: KeycloakService,
		private loggingService: LoggingService
	) {
		//make the auth service check for token refresh every 1 min
		//NOTE: This does not mean it refreshes every 1 min, it does a simple check to see if we are within 5 mins of expiration
		setInterval(() => {
			this.refreshToken();
		}, 60000); // Check every 1 min
	}

	async refreshToken() {
		if (this.keycloakService.isTokenExpired()) {
			try {
				await this.keycloakService.updateToken(300000); // Update if we are within 5 mins of expiry
				console.log('Token refreshed');
			} catch (error) {
				console.error('Failed to refresh the token', error);
			}
		}
	}

	/**
	 * @summary Handles the initialization of the AuthenticationService
	 * Retrieves the user's profile information, including name, roles, and the keycloak user ID, from Keycloak
	 * @returns {Promise<boolean>} - Returns a Promise that resolves to a boolean value indicating the success of the initialization
	 */
	public async initialize(): Promise<boolean> {
		try {
			// Check if the user is logged in to Keycloak
			const isLoggedIn = await this.keycloakService.isLoggedIn();

			if (!isLoggedIn) {
				return false;
			}

			// Create a new instance of KeycloakUserModel
			const user = new KeycloakUserModel();

			// Load the user profile from Keycloak
			const profile = await this.keycloakService.loadUserProfile(true);

			// Retrieve the user roles, excluding the default Keycloak roles
			const allRoles = this.keycloakService.getUserRoles();
			const roles = allRoles.filter(role => !Constants.Keycloak.ExcludeRoles.includes(role));

			// Populate the user model with profile information
			user.userRoles = roles;
			user.firstName = profile.firstName || '';
			user.lastName = profile.lastName || '';
			user.email = profile.email || '';
			user.keycloakUserId = profile.id || '';

			user.token = await this.keycloakService.getToken();

			const { payload, isSuccess } = await this.usersService.userAuthTasks();

			if (isSuccess) {
				const { hasAgendaPreviewViewer, hasAgendaPreviewAdmin } = payload;
				if (hasAgendaPreviewViewer) {
					user.userRoles.push('dmx-agendapreview-viewer');
				}
				if (hasAgendaPreviewAdmin) {
					user.userRoles.push('dmx-agendapreview-admin');
				}
			}

			localStorage.removeItem(Constants.Config.UserLocalStorageKey);
			localStorage.setItem(Constants.Config.UserLocalStorageKey, JSON.stringify(user));

			// Emit the user information to subscribers
			this.loggingService.addLog(`User authenticated: ${user.firstName}, ${user.lastName}, ${user.email}`);
			this.loggingService.addLog(`User roles: ${user.userRoles.filter(x => x.includes('dmx')).join('; ')}`);

			this.userSubject.next(user);

			return true;
		} catch (error) {
			console.error('An error occurred in class AuthenticationService, method initialize()', error);
			return false;
		}
	}

	/**
	 * @summary Gets current user last emitted from the initialize;
	 * NOTE: Worthy of mentioning this is used by our NGXS AuthenticationActions @Selector currentUser only really,
	 * however, all of the other state/stores make use of this @Selector instead of using this service directly
	 * @parameters none
	 */
	public currentUser(): Observable<KeycloakUserModel | undefined> {
		return this.user$;
	}

	/**
	 * @summary Gets current user filtering undefined, and maps to a new object with isAdmin boolean
	 * @parameters none
	 */
	public getUserInfo$(): Observable<{ user: KeycloakUserModel; isAdmin: boolean }> {
		return this.currentUser().pipe(
			filter(user => !!user),
			map(user => ({ user: user as KeycloakUserModel, isAdmin: user?.userRoles?.includes(Constants.UserRoles.Admin) ?? false }))
		);
	}

	currentUserCached(): KeycloakUserModel | undefined {
		const user = localStorage.getItem(Constants.Config.UserLocalStorageKey);
		if (!user) {
			return;
		}
		return JSON.parse(user) as KeycloakUserModel;
	}

	///<summary>
	///Logout kills identity provider token and uses redirectUri to come back to app
	///Depricated: 2024-06-10 does not kill the azure token so sign out is not quite right; solution is to manually call keycloak logout and set the frontend signout url in the keycloak settings in keycloak console
	///</summary>
	// public logout() {
	//     var redirectUri = this.configService.get('url') + 'home';
	//     this.keycloakService.clearToken();
	//     return this.keycloakService.logout(redirectUri);
	// }

	async logout(redirectUri: string) {
		const keycloakInstance = this.keycloakService.getKeycloakInstance();
		const idToken = keycloakInstance.idToken;

		if (idToken) {
			const realm = keycloakInstance.realm?.toLowerCase();
			const logoutUrl = `${keycloakInstance.authServerUrl}realms/${realm}/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(redirectUri)}`;
			window.location.href = logoutUrl;
		} else {
			console.error('ID token is undefined. Logout process may not work correctly.');
		}

		// Clear local storage
		localStorage.clear();

		// Clear session storage
		sessionStorage.clear();

		// Clear Keycloak tokens
		this.keycloakService.clearToken();
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '@ecom/common/helpers/constants';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';
import { DocumentModel } from '@ecom/ecom-app-generated/Models/DocumentModel';
import { UserModel } from '@ecom/ecom-app-generated/Models/UserModel';
import { FileViewerService } from '@ecom/services/file-viewer.service';
import { Subscription, interval } from 'rxjs';

@Component({
    selector: 'app-agenda-document-link',
    templateUrl: './agenda-document-link.component.html',
    styleUrls: ['./agenda-document-link.component.scss'],
})
export class AgendaDocumentLinkComponent implements OnInit {
    @Input() documentId: string;
    @Input() user: UserModel;

    document: DocumentModel = new DocumentModel();
    pollingSubscription: Subscription;
    priorityDownload: boolean = false;

    constructor(
        private fileViewerService: FileViewerService,
        private localDocumentsService: LocalDocumentsService
    ) {}

    async ngOnInit() {
        // Check if file exists every 3 seconds
        this.checkFileForContent();
        this.pollingSubscription = interval(3000).subscribe(() =>
            this.checkFileForContent()
        );
    }
    async checkFileForContent() {
        let connection: IDBDatabase | null = null;

        try {
            connection = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForViewer
            );
            var document = await this.localDocumentsService.getDocument(
                connection,
                this.documentId,
                true,
                true
            );
            if (document) {
                this.document = document;

                if (this.document.file) {
                    this.pollingSubscription.unsubscribe();
                }
            }
        } catch (error: any) {
            throw error;
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection) {
                connection.close();
                connection = null;
            }
        }
    }

    /**
     * View the document in the viewer by navigating to pdf viewer
     */
    view(documentId: string, newTab = false) {
        this.fileViewerService.viewDocument(documentId, newTab);
    }

    ngOnDestroy() {
        // Unsubscribe when the component is destroyed
        if (this.pollingSubscription) {
            this.pollingSubscription.unsubscribe();
        }
    }
}

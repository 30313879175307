<div uk-grid>
    <div class="uk-width-expand@m">
        <div>
            <app-layout-header></app-layout-header>
        </div>
        <div class="uk-padding-small">
            <ng-container *ngIf="userStatus === 'New'">
                <div>
                    <h2>Access Requested</h2>
                </div>
                <div class="uk-grid uk-flex uk-flex-middle">
                    <app-goa-callout inputType="info"
                        >Welcome {{ keycloakUser?.firstName }} to request
                        access, let us know what you need and an admin will
                        review your access request.</app-goa-callout
                    >
                </div>
                <div class="uk-width-1-2@m">
                    <label for="comment">Access Request Comments:</label>
                    <form [formGroup]="form">
                        <div>
                            <textarea
                                rows="4"
                                formControlName="inputValue"
                                style="width: 100%"></textarea>
                        </div>
                        <div class="uk-margin-top">
                            <button
                                mat-raised-button
                                color="primary"
                                (click)="onSubmit()">
                                Request Access
                            </button>
                        </div>
                    </form>
                </div>
            </ng-container>

            <ng-container *ngIf="userStatus === 'Pending'">
                <div>
                    <h2>Access Pending</h2>
                </div>
                <div
                    class="uk-grid uk-flex uk-flex-middle"
                    data-cy="info-AccessPending">
                    <app-goa-callout inputType="info"
                        >Welcome {{ keycloakUser?.firstName }} access is still
                        pending, if you believe this is in an error please
                        contact support
                        dmx-support&#64;gov.ab.ca</app-goa-callout
                    >
                </div>
            </ng-container>

            <ng-container *ngIf="userStatus === 'Denied'">
                <div>
                    <h2>Access Denied</h2>
                </div>
                <div
                    class="uk-grid uk-flex uk-flex-middle"
                    data-cy="info-AccessDenied">
                    <app-goa-callout inputType="info"
                        >Access has been denied. If you believe this is in an
                        error please contact support
                        dmx-support&#64;gov.ab.ca</app-goa-callout
                    >
                </div>
            </ng-container>

            <ng-container *ngIf="userStatus === 'Invited'">
                <div>
                    <h2>Access setup</h2>
                </div>
                <div
                    class="uk-grid uk-flex uk-flex-middle"
                    data-cy="info-AccessSetup">
                    <app-goa-callout inputType="info"
                        >Welcome, {{ keycloakUser?.firstName }}! We're setting
                        up your account access. Please wait while we configure
                        your committees and roles.</app-goa-callout
                    >
                </div>
            </ng-container>

            <ng-container *ngIf="userStatus === 'Approved'">
                <div>
                    <h2>Access granted</h2>
                </div>
                <div
                    class="uk-grid uk-flex uk-flex-middle"
                    data-cy="info-AccessGranted">
                    <app-goa-callout inputType="info"
                        >{{ keycloakUser?.firstName }} you already have access
                        and will be redirected in {{ timerValue
                        }}</app-goa-callout
                    >
                </div>
            </ng-container>
        </div>
    </div>
</div>

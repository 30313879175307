




















//class generated by TypeWriter in Core API project by ModelGenerator.nt template
export class DocumentModel {
    public documentId: string;
    public sharePointId: string;
    public documentName: string;
    public meetingId: string;
    public meetingEndTime: Date | null;
    public thumbnail: string;
    public versionExpiredOn: Date | null;
    public createDate: Date;
    public createBy: string;
    public updateDate: Date | null;
    public updateBy: string;
    public archiveDate: Date | null;
    public archiveBy: string;
    public file: string;
    public fileKey: string;
    public fileSize: number;
    public expiryDate: Date;
    public isPublished: boolean;
    public isUpdated: boolean;

    constructor(init? : Partial<DocumentModel>) {
        
        Object.assign(this, init);
    }
}
import { Constants } from '@ecom/common/helpers/constants';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';
import {
    ITask,
    TaskResult,
} from '@ecom/app-workflows/core/task-pipeline/task-interface';
import { DocumentModel } from '@ecom/ecom-app-generated/Models/DocumentModel';

export class RemoveOldAdminDocumentsFromIndexDbTask implements ITask {
    recordCount: number = 0;
    constructor(private localDocumentsService: LocalDocumentsService) {}
    async execute(context: Map<string, any>): Promise<TaskResult> {
        try {
            //Ensure we close the connection when removing documents from IndexDb
            var documents =
                await this.removeOldAdminDocumentsFromIndexDb(context);
            return {
                success: true,
                message:
                    'Remove old admin documents from local storage succeeded.',
                recordCount: this.recordCount,
            };
        } catch (error: any) {
            return {
                success: false,
                message: `Remove old admin documents from local storage failed.`,
                data: error.message,
            };
        }
    }

    async removeOldAdminDocumentsFromIndexDb(context: Map<string, any>) {
        this.recordCount = 0;
        let connection: IDBDatabase | null = null;
        var serverDocuments = context.get(
            'GetAdminDocumentsFromServerTaskResult'
        ) as DocumentModel[];
        var indexDbDocuments = context.get(
            'GetAdminDocumentsFromIndexDbTaskResult'
        ) as DocumentModel[];

        try {
            connection = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForAdmin
            );
            for (let indexDbDocument of indexDbDocuments) {
                const serverDocument = serverDocuments.find(
                    x => x.documentId == indexDbDocument.documentId
                );
                if (serverDocument == null) {
                    await this.localDocumentsService.deleteDocument(
                        connection,
                        indexDbDocument.documentId
                    );
                    this.recordCount++;
                }
            }
        } catch (error) {
            throw error;
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection) {
                connection.close();
                connection = null;
            }
        }
    }
}

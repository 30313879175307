import {
    Component,
    ContentChild,
    Input,
    OnInit,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'app-goa-callout',
    templateUrl: './goa-callout.component.html',
    styleUrls: ['./goa-callout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GoaCalloutComponent implements OnInit {
    @Input() inputType: string = 'info';
    @ContentChild(TemplateRef) contentTemplate: TemplateRef<any>;

    public calloutType: string = '';
    icon: string = this.getIcon(this.inputType);

    constructor() {}

    ngOnInit(): void {
        this.calloutType = this.inputType;
    }

    getIcon(type: string): string {
        switch (type) {
            case 'info':
                return 'info_outline';
            case 'offline':
                return 'wifi_off';
            case 'event':
                return 'calendar_month';
            case 'emergency':
                return 'warning_amber';
            case 'warning':
                return 'info_outline';
            case 'success':
                return 'check_circle_outline';
            default:
                return 'info_outline';
        }
    }
}

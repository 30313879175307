




//class generated by TypeWriter in Core API project by ModelGenerator.nt template
export class AddDocumentViewMetricModel {
    public documentId: string;
    public createDate: Date;
    public codeId: string;

    constructor(init? : Partial<AddDocumentViewMetricModel>) {
        
        Object.assign(this, init);
    }
}
// app-workflows.service.ts
import { EventEmitter, Injectable } from '@angular/core';
import { IWorkflow } from '../core/workflow-interface';
import { TaskResult } from './task-pipeline/task-interface';

@Injectable({
    providedIn: 'root',
})
export class AppWorkflowsService {
    private workflows: IWorkflow[] = [];
    private isRunning: boolean = false;
    public onTaskCompleted: EventEmitter<TaskResult> =
        new EventEmitter<TaskResult>();

    constructor() {}

    addWorkflow(workflow: IWorkflow) {
        this.workflows.push(workflow);

        // Subscribe to the workflow's onTaskCompleted event
        workflow.onTaskCompleted.subscribe((result: TaskResult) => {
            this.onTaskCompleted.next(result);
        });
    }

    run() {
        if (!this.isRunning) {
            this.isRunning = true;
            //don't await tryWorkflowExecution as we want the workflows to run in parallel
            //However we do want each workflow to not fire again until completed, so that is done below
            this.workflows.forEach(workflow =>
                this.tryWorkflowExecution(workflow)
            );
        }
    }

    private async tryWorkflowExecution(workflow: IWorkflow) {
        // Initial immediate execution if needed
        if (workflow.status !== 'Running') {
            await this.executeWorkflow(workflow);
        }
        // Schedule further executions
        setInterval(async () => {
            if (workflow.status !== 'Running') {
                await this.executeWorkflow(workflow);
            }
        }, workflow.repeatInterval);
    }

    private async executeWorkflow(workflow: IWorkflow) {
        try {
            await workflow.run();
        } catch (error) {
            console.error('Error executing workflow:', error);
        }
    }

    private workflowTaskCompleted(result: TaskResult, workflow: IWorkflow) {}

    public static generateRunId(): number {
        // Use the current timestamp and a random factor to generate a unique ID
        return Number(new Date()) + Math.floor(Math.random() * 10000);
    }
}

import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakUserModel } from '@ecom/common/authentication/models/keycloak-user-model';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';
import { Constants } from '@ecom/common/helpers/constants';
import { Navigation } from '@ecom/common/helpers/navigation';

import { VersionModel } from '@ecom/ecom-app-generated/Models/VersionModel';
import { VersionService } from '@ecom/ecom-app-generated/Services/VersionService';
import { InteractionsService } from '@ecom/services/interactions.service';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

import { FeedbackComponent } from '@ecom/common/components/feedback/feedback.component';
import { UserProfileMenuComponent } from '../user-profile-menu/user-profile-menu.component';
import { FileHelper } from '@ecom/common/helpers/file-helper';
import { BrowserService } from '@ecom/services/browser.service';

@Component({
	selector: 'app-layout-header',
	templateUrl: './layout-header.component.html',
	styleUrls: ['./layout-header.component.scss'],
	providers: [BrowserService],
})
export class LayoutHeaderComponent {
	isAgendaPreview = false;
	isAdmin = false;
	generatedDialogId: string = FileHelper.generateUUID();
	@Input() headerType: string = 'public';

	version: VersionModel | undefined;

	user: KeycloakUserModel;

	@ViewChild('feedbackComp') feedbackComp: FeedbackComponent;
	@ViewChild('userMenuComp') userMenuComp: UserProfileMenuComponent;

	openFeedbackModal() {
		this.feedbackComp.open();
	}

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private interactionsService: InteractionsService,
		private versionService: VersionService,
		public browserService: BrowserService
	) {
		//once we've got all the information about our user let's setup the page
		this.authenticationService
			.currentUser()
			.pipe(filter(user => user !== undefined))
			.subscribe(currentUser => {
				if (currentUser) {
					this.user = currentUser;
					if (currentUser.userRoles.includes(Constants.UserRoles.AgendaPreviewViewer)) {
						this.isAgendaPreview = true;
					}

					if (currentUser.userRoles.includes(Constants.UserRoles.Admin)) {
						this.isAdmin = true;
					}
				}
			});

		this.initVersion();
	}

	async initVersion() {
		let result = await this.versionService.getVersion();

		if (result.isSuccess) {
			this.version = result.payload;

			//get the web version from the environment file
			this.version.webVersionNumber = environment.version;
		} else {
			this.interactionsService.showErrorMessage('Error getting version number', result.errors);
		}
	}

	dashboard() {
		//authguard will handle where we redirect user whether /admin/meetings or just /meetings depending on roles
		this.router.navigate(['/']);
	}

	reloadIfSameUrl(target: string) {
		Navigation.reloadIfSameUrl(this.router.url, target);
	}

	private userHasRole(roles: string[], rolesToCheck: string[]): boolean {
		return rolesToCheck.some(roleToCheck => roles.includes(roleToCheck));
	}

	isMobileNavVisible: boolean = false;

	toggle_MobileNav(event: Event) {
		event.preventDefault();

		this.isMobileNavVisible = !this.isMobileNavVisible;
		const toggleElement = document.querySelector('.mobile-nav-toggle');

		if (toggleElement) {
			toggleElement.setAttribute('aria-expanded', String(this.isMobileNavVisible));
		}
	}
}

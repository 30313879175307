import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Constants } from '@ecom/common/helpers/constants';
import { Observable, firstValueFrom } from 'rxjs';
import { ConfigService } from '../../common/services/config.service';
import { IServiceResult } from './IServiceResult';

export abstract class EndPointService {
    readonly headerDict = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
    };

    protected constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private serviceName: string
    ) {}

    public async getWithQuery<TOutput extends IServiceResult, TInput>(
        action: string,
        model: TInput
    ): Promise<TOutput> {
        return this.performHttpRequest('GET', action, model);
    }

    public async get<TOutput extends IServiceResult>(
        action: string
    ): Promise<TOutput> {
        return this.performHttpRequest('GET', action);
    }

    public async post<TOutput extends IServiceResult, TInput>(
        action: string,
        model: TInput
    ): Promise<TOutput> {
        return this.performHttpRequest('POST', action, model);
    }

    private async performHttpRequest<TOutput extends IServiceResult, TInput>(
        method: 'GET' | 'POST',
        action: string,
        model?: TInput
    ): Promise<TOutput> {
        return new Promise((resolve, reject) => {
            const startTime = performance.now();
            const baseUrl = this.configService.get(
                Constants.Config.ConfigApiCoreBaseUrl
            );
            let url = `/api/${this.serviceName}/${action}`;
            let headers = new HttpHeaders(this.headerDict);
            let options: { headers?: HttpHeaders; params?: HttpParams } = {};
            let request: Observable<TOutput>; // Declare the request variable

            if (method === 'GET') {
                const params = this.setHeaders(model);
                options.params = params;
                // Append query string to the action if params exist
                if (params.toString()) {
                    url += `?${params.toString()}`;
                }
                request = this.http.get<TOutput>(`${baseUrl}${url}`, options); // Initialize request for GET
            } else if (method === 'POST') {
                const body = JSON.stringify(model);
                options.headers = headers;
                request = this.http.post<TOutput>(
                    `${baseUrl}${url}`,
                    body,
                    options
                ); // Initialize request for POST
            } else {
                throw new Error('HTTP method not supported');
            }

            firstValueFrom(request)
                .then((data: any) => {
                    //const endTime = performance.now();
                    //const apiResponseTime = (endTime - startTime).toFixed(2); // Round to two decimal places
                    //console.log(`API response time: ${apiResponseTime} ms; details: ${url}`);
                    if (data.isSuccess) {
                        resolve(data);
                    } else {
                        console.log(data);
                        resolve(data);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    //All GET use the [FromQuery] attribute in the C#/.NET end-points that are used with it; so we need to take a TypeScript model and convert it
    //to a useable query string that has the same fields and values;
    //Prepare Query Parameters (HttpParams) and Header (TBD) for a request GET
    private setHeaders<TInput>(model: TInput): HttpParams {
        let params = new HttpParams();
        if (model) {
            Object.entries(model).forEach(([fieldName, value]) => {
                if (value !== null && value !== undefined) {
                    // Handle undefined as well
                    if (
                        typeof value === 'string' ||
                        typeof value === 'boolean'
                    ) {
                        // String and boolean
                        params = params.set(fieldName, value.toString());
                    } else if (typeof value === 'number') {
                        // Number
                        params = params.set(fieldName, value.toString());
                    } else if (value instanceof Array) {
                        // Array
                        value.forEach((c, i) => {
                            const keyIndex = fieldName + '[' + i + ']';
                            params = params.append(keyIndex, c.toString());
                        });
                    } else if (value instanceof Date) {
                        // Date
                        params = params.append(fieldName, value.toISOString());
                    } else {
                        // Fallback for any other type, convert to string
                        params = params.set(fieldName, JSON.stringify(value));
                    }
                }
            });
        }
        return params;
    }
}

<div uk-grid>
    <div class="uk-width-expand@m">
        <app-layout-header headerType="secure"></app-layout-header>
        <div class="uk-padding">
            <div>
                <a
                    [routerLink]="['/help']"
                    role="menuitem"
                    data-cy="lnkSettings"
                    >Back to Help Center</a
                >
                <h2>Local storage</h2>
                <p>
                    This page allows you to view the documents that have been
                    synced to your device's local storage from DMX. It provides
                    a convenient way to troubleshoot and view the documents
                    currently downloaded to your device.
                </p>

                <!-- Material Table for Documents -->
                <div
                    *ngIf="
                        slimViewerDocuments && slimViewerDocuments.length > 0
                    ">
                    <table mat-table [dataSource]="slimViewerDocuments">
                        <!-- Document Name Column -->
                        <ng-container matColumnDef="documentName">
                            <th mat-header-cell *matHeaderCellDef>
                                Document Name
                            </th>
                            <td mat-cell *matCellDef="let doc">
                                {{ doc.documentName }}
                            </td>
                        </ng-container>

                        <!-- Document ID Column -->
                        <ng-container matColumnDef="documentId">
                            <th mat-header-cell *matHeaderCellDef>
                                Document ID
                            </th>
                            <td mat-cell *matCellDef="let doc">
                                {{ doc.documentId }}
                            </td>
                        </ng-container>

                        <!-- File Size Column -->
                        <ng-container matColumnDef="fileSize">
                            <th mat-header-cell *matHeaderCellDef>
                                File Size (MB)
                            </th>
                            <td mat-cell *matCellDef="let doc">
                                {{ doc.fileSize }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="createDate">
                            <th mat-header-cell *matHeaderCellDef>
                                Create Date
                            </th>
                            <td mat-cell *matCellDef="let doc">
                                {{ doc.createDate }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="updateDate">
                            <th mat-header-cell *matHeaderCellDef>
                                Update Date
                            </th>
                            <td mat-cell *matCellDef="let doc">
                                {{ doc.updateDate }}
                            </td>
                        </ng-container>

                        <!-- Define the header and row setup -->
                        <tr
                            mat-header-row
                            *matHeaderRowDef="[
                                'documentName',
                                'documentId',
                                'fileSize',
                                'createDate',
                                'updateDate'
                            ]"></tr>
                        <tr
                            mat-row
                            *matRowDef="
                                let row;
                                columns: [
                                    'documentName',
                                    'documentId',
                                    'fileSize',
                                    'createDate',
                                    'updateDate'
                                ]
                            "></tr>
                    </table>
                </div>
                <!-- Template to display when there are no documents -->
                <div
                    *ngIf="
                        slimViewerDocuments && slimViewerDocuments.length == 0
                    ">
                    <p>
                        Currently there are 0 documents in your local storage.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';

import { UserModel } from '../Models/UserModel'
import { CommitteeUserModel } from '../Models/CommitteeUserModel' 
import { IdRequestModel } from '../Models/IdRequestModel' 
import { GetUserByEmailRequestModel } from '../Models/GetUserByEmailRequestModel' 
import { AddUserRequestModel } from '../Models/AddUserRequestModel' 
import { AddGuestUserRequestModel } from '../Models/AddGuestUserRequestModel' 
import { UpdateUserRequestModel } from '../Models/UpdateUserRequestModel' 
import { UpdateUserSettingsRequestModel } from '../Models/UpdateUserSettingsRequestModel'
import { IdResponseModel } from '../Models/IdResponseModel'
import { UserKeyResponseModel } from '../Models/UserKeyResponseModel'

@Injectable({
     providedIn: 'root'
 })
export class UsersService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'users');
    } 

  
  public getAllUsers() : Promise<ServiceResult<UserModel[]>> {
      return this.get<ServiceResult<UserModel[]>>('GetAllUsers');
  };
  
  public getGrantedUsers() : Promise<ServiceResult<CommitteeUserModel[]>> {
      return this.get<ServiceResult<CommitteeUserModel[]>>('GetGrantedUsers');
  };
  //get
  public getGuestUsersByCommitteeId(model: IdRequestModel) : Promise<ServiceResult<UserModel[]>> {
      return this.getWithQuery<ServiceResult<UserModel[]>,IdRequestModel>('GetGuestUsersByCommitteeId',model);
  };
  
  //get
  public getUserById(model: IdRequestModel) : Promise<ServiceResult<UserModel>> {
      return this.getWithQuery<ServiceResult<UserModel>,IdRequestModel>('GetUserById',model);
  };
  
  //get
  public getUserByKeycloakId(model: IdRequestModel) : Promise<ServiceResult<UserModel>> {
      return this.getWithQuery<ServiceResult<UserModel>,IdRequestModel>('GetUserByKeycloakId',model);
  };
  
  //get
  public getUserByEmail(model: GetUserByEmailRequestModel) : Promise<ServiceResult<UserModel>> {
      return this.getWithQuery<ServiceResult<UserModel>,GetUserByEmailRequestModel>('GetUserByEmail',model);
  };
  
  
  public userAuthTasks() : Promise<ServiceResult<UserModel>> {
      return this.get<ServiceResult<UserModel>>('UserAuthTasks');
  };
  
  //post
  public addUser(model: AddUserRequestModel) : Promise<ServiceResult<UserModel>> {
      return this.post<ServiceResult<UserModel>,AddUserRequestModel>('AddUser',model);
  };
  
  //post
  public addGuestUser(model: AddGuestUserRequestModel) : Promise<ServiceResult<UserModel>> {
      return this.post<ServiceResult<UserModel>,AddGuestUserRequestModel>('AddGuestUser',model);
  };
  
  //post
  public updateUser(model: UpdateUserRequestModel) : Promise<ServiceResult<UserModel>> {
      return this.post<ServiceResult<UserModel>,UpdateUserRequestModel>('UpdateUser',model);
  };
  
  //post
  public updateUserSettings(model: UpdateUserSettingsRequestModel) : Promise<ServiceResult<UserModel>> {
      return this.post<ServiceResult<UserModel>,UpdateUserSettingsRequestModel>('UpdateUserSettings',model);
  };
  
  //post
  public deleteUser(model: IdRequestModel) : Promise<ServiceResult<IdResponseModel>> {
      return this.post<ServiceResult<IdResponseModel>,IdRequestModel>('DeleteUser',model);
  };
  //get
  public getUserKey(model: IdRequestModel) : Promise<ServiceResult<UserKeyResponseModel>> {
      return this.getWithQuery<ServiceResult<UserKeyResponseModel>,IdRequestModel>('GetUserKey',model);
  };
  
  //get
  public getCommitteeUsers(model: IdRequestModel) : Promise<ServiceResult<UserModel[]>> {
      return this.getWithQuery<ServiceResult<UserModel[]>,IdRequestModel>('GetCommitteeUsers',model);
  };
  
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';

import { CodeModel } from '../Models/CodeModel' 
import { UpdateCodeRequestModel } from '../Models/UpdateCodeRequestModel'

@Injectable({
     providedIn: 'root'
 })
export class CodesService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'codes');
    } 

  
  public getAdminBannerCode() : Promise<ServiceResult<CodeModel>> {
      return this.get<ServiceResult<CodeModel>>('GetAdminBannerCode');
  };
  
  public getViewerBannerCode() : Promise<ServiceResult<CodeModel>> {
      return this.get<ServiceResult<CodeModel>>('GetViewerBannerCode');
  };
  
  //post
  public updateBannerCode(model: UpdateCodeRequestModel) : Promise<ServiceResult<CodeModel>> {
      return this.post<ServiceResult<CodeModel>,UpdateCodeRequestModel>('UpdateBannerCode',model);
  };
}
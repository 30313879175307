<div #feedbackModal [attr.data-dialog-id]="dialogId" uk-modal>
	<!--<div id={{dialogId}} uk-modal>-->

	<div class="uk-modal-dialog" uk-width-1-2 style="z-index: 1515 !important">
		<h3 class="uk-modal-title">Provide Feedback</h3>
		<div class="uk-modal-body">
			<button class="uk-modal-close-default" type="button" uk-close></button>
			<div>
				<form [formGroup]="feedbackForm" #formDirective="ngForm" (ngSubmit)="onSubmit()">
					<p><strong>Does this page meet your expectations?</strong></p>
					<mat-button-toggle-group #group="matButtonToggleGroup" formControlName="feedback_sentiment">
						<mat-button-toggle
							(click)="onPositiveSentimentClick()"
							value="true"
							class="feedback-button secondary-button mat-flat-button mat-button-base ng-star-inserted uk-margin-small-right"
							type="button">
							<mat-icon class="feedback-icon">thumb_up</mat-icon><span>Yes</span>
						</mat-button-toggle>
						<mat-button-toggle
							value="false"
							uk-modal-submit
							class="feedback-button mat-focus-indicator secondary-button mat-flat-button mat-button-base ng-star-inserted"
							type="button">
							<mat-icon class="feedback-icon">thumb_down</mat-icon><span>No</span>
						</mat-button-toggle>
					</mat-button-toggle-group>
					<mat-error *ngIf="feedbackForm.get('feedback_sentiment')?.errors?.['required'] && feedbackForm.get('feedback_sentiment')?.touched">
						Please select Yes or No before submitting.
					</mat-error>

					<p class="feedback-more"><strong>Tell us more:</strong></p>

					<mat-form-field [style.fontSize]="20" appearance="outline">
						<textarea
							id="feedback_comment"
							matInput
							cdkTextareaAutosize
							#autosize="cdkTextareaAutosize"
							formControlName="feedback_comment"
							cdkAutosizeMinRows="6"
							cdkAutosizeMaxRows="10"
							placeholder="Enter additional feedback here..."></textarea>
					</mat-form-field>

					<ng-container *ngIf="feedbackForm.get('feedback_comment') as feedbackCommentCtrl">
						<mat-error *ngIf="feedbackCommentCtrl?.hasError('required')">
							<div>{{ feedbackCommentCtrl?.getError('required') }}</div>
						</mat-error>
					</ng-container>

					<div class="uk-text-right uk-margin-xlarge-top">
						<button
							type="button"
							mat-flat-button
							color="secondary"
							aria-label="Close"
							(click)="onCancel()"
							class="secondary-button uk-modal-close-default uk-margin-small-right"
							style="top: 0px">
							Cancel
						</button>
						<button type="submit" mat-flat-button color="primary">Submit</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<div id="feedbackConfirmationModal" uk-modal>
	<div class="uk-modal-dialog" style="z-index: 90000 !important">
		<button class="uk-modal-close-default" type="button" uk-close></button>
		<div class="uk-modal-title">
			<h3>Thank you for your valuable feedback!</h3>
		</div>
		<div class="uk-modal-body">
			<div>
				<p>Please note that although we cannot respond to feedback, our team will use your comments to improve the experience.</p>
			</div>
		</div>
	</div>
</div>

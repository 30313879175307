// Angular core imports
import { Component, EventEmitter, Input, Output, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';

// Models
import { KeycloakUserModel } from '@ecom/common/authentication/models/keycloak-user-model';
import { MeetingModel } from '@ecom/ecom-app-generated/Models/MeetingModel';
import { VersionModel } from '@ecom/ecom-app-generated/Models/VersionModel';
import { NotificationModel } from '@ecom/models/NotificationModel';

// Services
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';
import { VersionService } from '@ecom/ecom-app-generated/Services/VersionService';
import { InteractionsService } from '@ecom/services/interactions.service';

// Helpers and utilities
import { Navigation } from '@ecom/common/helpers/navigation';
import { FileHelper } from '@ecom/common/helpers/file-helper';

// Components
import { FeedbackComponent } from '@ecom/common/components/feedback/feedback.component';
import { UserProfileMenuComponent } from '../user-profile-menu/user-profile-menu.component';

// Third-party imports
import { filter } from 'rxjs';

// Environment
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-layout-header-admin',
	templateUrl: './layout-header-admin.component.html',
	styleUrls: ['./layout-header-admin.component.scss'],
})
export class LayoutHeaderNewComponent {
	// Inputs
	@Input() activeMeeting: MeetingModel;
	@Input() showMeetingButtons: boolean = true;
	@Input() user: KeycloakUserModel | undefined;
	@Input() meetingAction: string;
	@Input() headerType: string = '';
	@Input() activeMenu: string = 'Dashboard';

	// Outputs
	@Output() readonly saveDraftClick = new EventEmitter();
	@Output() readonly publishUnpublishClick = new EventEmitter();
	@Output() readonly deleteClick = new EventEmitter();
	@Output() readonly onMenuActionEvent = new EventEmitter<string>();

	// View children
	@ViewChild('feedbackComp') feedbackComp: FeedbackComponent;
	@ViewChild('userProfileMenuComp')
	userProfileMenuComp: UserProfileMenuComponent;

	// Local variables
	notifications: NotificationModel[] = [
		new NotificationModel(1, 'Notification 1', '/admin/home'),
		new NotificationModel(2, 'Notification 2', '/admin/home'),
		new NotificationModel(3, 'Notification 3', '/admin/home'),
	];
	isAgendaPreview = false;
	generatedDialogId: string = FileHelper.generateUUID();
	version: VersionModel | undefined;
	meetingId: string = '';
	isMobileNavVisible: boolean = false;

	// Constructor with injected services
	constructor(
		private versionService: VersionService,
		private authenticationService: AuthenticationService,
		private interactionsService: InteractionsService,
		private router: Router
	) {
		//get the current user
		this.authenticationService
			.currentUser()
			.pipe(filter(user => user !== undefined))
			.subscribe(currentUser => {
				if (currentUser) {
					this.user = currentUser;
					if (
						currentUser?.userRoles.includes(
							'dmx-agendapreview-admin'
						)
					) {
						this.isAgendaPreview = true;
					}
				}
			});

		this.initVersion();
	}

	async initVersion() {
		let result = await this.versionService.getVersion();

		if (result.isSuccess) {
			this.version = result.payload;

			//get the web version from the environment file
			this.version.webVersionNumber = environment.version;
		} else {
			this.interactionsService.showErrorMessage(
				'Error getting version number',
				result.errors
			);
		}
	}

	onActiveMenuClick(action: string) {
		this.onMenuActionEvent.emit(action);
	}

	reloadIfSameUrl(target: string) {
		Navigation.reloadIfSameUrl(this.router.url, target);
	}

	openFeedbackModal() {
		this.feedbackComp.open();
	}

	toggle_MobileNav(event: Event) {
		event.preventDefault();

		this.isMobileNavVisible = !this.isMobileNavVisible;

		const toggleElement = document.querySelector('.mobile-nav');

		if (toggleElement) {
			toggleElement.setAttribute(
				'aria-expanded',
				String(this.isMobileNavVisible)
			);
		}
	}
}

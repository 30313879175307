import { Component, OnInit } from '@angular/core';
import { Constants } from '@ecom/common/helpers/constants';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';

interface SlimDocument {
    documentId: string;
    documentName: string;
    fileSize: string;
    createDate: Date | null;
    updateDate: Date | null;
}

@Component({
    selector: 'app-local-storage',
    templateUrl: './local-storage.component.html',
    styleUrls: ['./local-storage.component.scss'],
})
export class LocalStorageComponent implements OnInit {
    slimViewerDocuments: SlimDocument[];
    constructor(private localDocumentsService: LocalDocumentsService) {}

    async ngOnInit(): Promise<void> {
        let connection: IDBDatabase | null = null;

        try {
            connection = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForViewer
            );
            var viewerDocuments =
                await this.localDocumentsService.getAllDocuments(connection);

            this.slimViewerDocuments = viewerDocuments.map(doc => ({
                documentId: doc.documentId,
                documentName: doc.documentName,
                fileSize: (doc.fileSize / 1048576).toFixed(2),
                createDate: doc.createDate,
                updateDate: doc.updateDate,
            }));
        } catch (error) {
            console.error(`Failed to get documents from local storage`, error);
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection) {
                connection.close();
                connection = null;
            }
        }
    }
}

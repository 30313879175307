
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';

import { VersionModel } from '../Models/VersionModel'

@Injectable({
     providedIn: 'root'
 })
export class VersionService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'version');
    } 

  
  public getVersion() : Promise<ServiceResult<VersionModel>> {
      return this.get<ServiceResult<VersionModel>>('GetVersion');
  };
}
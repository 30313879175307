



//class generated by TypeWriter in Core API project by ModelGenerator.nt template
export class GetAllAgendaItemsRequestModel {
    public filterStartsWith: string;
    public meetingId: string;

    constructor(init? : Partial<GetAllAgendaItemsRequestModel>) {
        
        Object.assign(this, init);
    }
}
import { DocumentsService } from '@ecom/ecom-app-generated/Services/DocumentsService';
import {
    ITask,
    TaskResult,
} from '@ecom/app-workflows/core/task-pipeline/task-interface';

export class GetAdminDocumentsFromServerTask implements ITask {
    constructor(private documentsService: DocumentsService) {}

    async execute(context: Map<string, any>): Promise<TaskResult> {
        try {
            const result = await this.documentsService.getAdminDocuments();

            if (!result.isSuccess)
                throw new Error(
                    `An error has occurred while getting admin documents from server.`
                );

            var documents = result.payload;
            context.set(`GetAdminDocumentsFromServerTaskResult`, documents);
            return {
                success: true,
                message: `Get admin documents from server succeeded.`,
                recordCount: documents?.length ?? 0,
            };
        } catch (error: any) {
            return {
                success: false,
                message: `Get admin documents from server failed.`,
                recordCount: 0,
                data: error.message,
            };
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';
import { Constants } from '@ecom/common/helpers/constants';
import { LoggingService } from '@ecom/common/services/logging-service/logging-service.service';
import { filter } from 'rxjs';

@Component({
    selector: 'app-healthcheck',
    templateUrl: './healthcheck.component.html',
    styleUrls: ['./healthcheck.component.scss'],
})
export class HealthcheckComponent implements OnInit {
    public logs: string[] = [];
    isAdmin: boolean = false;
    displayedColumns: string[] = ['logEntry'];

    constructor(
        private loggingService: LoggingService,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService
            .currentUser()
            .pipe(filter(user => user !== undefined))
            .subscribe(currentUser => {
                if (currentUser) {
                    this.isAdmin = currentUser.userRoles.includes('dmx-admin');
                }
            });

        this.logs = this.loggingService.getLogs();
    }

    ngOnInit(): void { }
}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';
 
import { GetAllAgendaItemsRequestModel } from '../Models/GetAllAgendaItemsRequestModel'
import { AgendaItemModel } from '../Models/AgendaItemModel' 
import { IdRequestModel } from '../Models/IdRequestModel' 
import { AddAgendaItemRequestModel } from '../Models/AddAgendaItemRequestModel' 
import { UpdateAgendaItemRequestModel } from '../Models/UpdateAgendaItemRequestModel'
import { IdResponseModel } from '../Models/IdResponseModel' 
import { SortAgendaItemsRequestModel } from '../Models/SortAgendaItemsRequestModel' 
import { MoveAgendaItemRequestModel } from '../Models/MoveAgendaItemRequestModel'

@Injectable({
     providedIn: 'root'
 })
export class AgendaItemsService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'agendaitems');
    } 

  //get
  public getAllAgendaItemsByMeetingId(model: GetAllAgendaItemsRequestModel) : Promise<ServiceResult<AgendaItemModel[]>> {
      return this.getWithQuery<ServiceResult<AgendaItemModel[]>,GetAllAgendaItemsRequestModel>('GetAllAgendaItemsByMeetingId',model);
  };
  
  //get
  public getAgendaItemById(model: IdRequestModel) : Promise<ServiceResult<AgendaItemModel>> {
      return this.getWithQuery<ServiceResult<AgendaItemModel>,IdRequestModel>('GetAgendaItemById',model);
  };
  
  
  //post
  public addAgendaItem(model: AddAgendaItemRequestModel) : Promise<ServiceResult<AgendaItemModel>> {
      return this.post<ServiceResult<AgendaItemModel>,AddAgendaItemRequestModel>('AddAgendaItem',model);
  };
  
  //post
  public updateAgendaItem(model: UpdateAgendaItemRequestModel) : Promise<ServiceResult<AgendaItemModel>> {
      return this.post<ServiceResult<AgendaItemModel>,UpdateAgendaItemRequestModel>('UpdateAgendaItem',model);
  };
  
  //post
  public deleteAgendaItem(model: IdRequestModel) : Promise<ServiceResult<IdResponseModel>> {
      return this.post<ServiceResult<IdResponseModel>,IdRequestModel>('DeleteAgendaItem',model);
  };
  
  //post
  public sortAgendaItems(model: SortAgendaItemsRequestModel) : Promise<ServiceResult<AgendaItemModel[]>> {
      return this.post<ServiceResult<AgendaItemModel[]>,SortAgendaItemsRequestModel>('SortAgendaItems',model);
  };
  
  //post
  public moveAgendaItem(model: MoveAgendaItemRequestModel) : Promise<ServiceResult<AgendaItemModel>> {
      return this.post<ServiceResult<AgendaItemModel>,MoveAgendaItemRequestModel>('MoveAgendaItem',model);
  };
}
import { Constants } from '@ecom/common/helpers/constants';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';
import {
    ITask,
    TaskResult,
} from '@ecom/app-workflows/core/task-pipeline/task-interface';

export class GetViewerDocumentsFromIndexDbTask implements ITask {
    constructor(private localDocumentsService: LocalDocumentsService) {}
    async execute(context: Map<string, any>): Promise<TaskResult> {
        try {
            //Get the viewer documents from IndexDb
            var documents = await this.getViewerDocumentsFromIndexDb(
                Constants.LocalStorage.DocumentsForViewer
            );
            context.set('GetViewerDocumentsFromIndexDbTaskResult', documents);

            return {
                success: true,
                message: 'Get viewer documents from local storage succeeded.',
                recordCount: documents?.length,
            };
        } catch (error: any) {
            return {
                success: false,
                message: `Get viewer documents from local storage failed.`,
                recordCount: 0,
                data: error.message,
            };
        }
    }

    async getViewerDocumentsFromIndexDb(databaseName: string) {
        let connection: IDBDatabase | null = null;
        try {
            connection =
                await this.localDocumentsService.openConnection(databaseName);
            const documents =
                await this.localDocumentsService.getAllDocuments(connection);
            return documents;
        } catch (error: any) {
            throw error;
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection) {
                connection.close();
                connection = null;
            }
        }
    }
}

<app-layout-header headerType="secure"></app-layout-header>
<div class="uk-padding-small">
    <div class="uk-padding-small">
        <a [routerLink]="['/help']" role="menuitem" data-cy="lnkSettings"
            >Back to Help Center</a
        >
        <br /><br />
        <h2>Workflows</h2>

        <!-- Callout that is toggled -->
        <app-goa-callout inputType="info">
            These workflows operate as background services to ensure your device
            always has the latest documents you need for your meetings.
        </app-goa-callout>
        <br />
        <div
            *ngFor="let workflowRun of workflowRuns | keyvalue"
            style="width: 100% !important">
            <!-- Make the entire div clickable and call toggleDetails on click -->
            <div
                class="workflow-run-row clickable"
                (click)="toggleDetails(workflowRun.key)">
                <div class="workflow-run-title">
                    <span
                        >{{ workflowRun.value.startDate | date: 'MM/dd/yy,
                        h:mm:ss a' }}</span
                    >
                    &nbsp;&nbsp;
                    <span style="color: black !important"
                        ><b>{{ workflowRun.value.workflowName }}</b>
                        <span
                            *ngIf="countDownloadingTasks(workflowRun.value) > 0"
                            class="custom-badge">
                            {{ countDownloadingTasks(workflowRun.value) }} new
                        </span>
                    </span>
                    <!-- Inline status and icon display -->
                    <span class="status-container">
                        <span class="status-text">
                            {{ workflowRun.value.status === 'Complete' ?
                            'Completed in ' + workflowRun.value.duration + 'ms'
                            : workflowRun.value.status }}
                        </span>
                        <mat-icon *ngIf="workflowRun.value.status === 'Error'"
                            >error</mat-icon
                        >
                        <mat-icon
                            *ngIf="workflowRun.value.status === 'Complete'"
                            >check_circle</mat-icon
                        >
                        <mat-icon
                            *ngIf="workflowRun.value.status === 'Running'"
                            class="rotate-icon"
                            >sync</mat-icon
                        >
                    </span>
                </div>
            </div>
            <div *ngIf="detailsVisibility.get(workflowRun.key)">
                <div class="task-results-container">
                    <div class="task-result-row header-row">
                        <div class="message">Task name</div>
                        <div class="success">Records</div>
                        <div class="success">Duration</div>
                        <div class="success">Status</div>
                    </div>
                    <div
                        class="task-result-row"
                        *ngFor="
                            let taskResult of workflowRun.value.taskResults
                        ">
                        <div
                            class="message"
                            (click)="toggleDetailVisibility($event)">
                            <div>
                                <mat-icon
                                    *ngIf="
                                        taskResult.message.startsWith(
                                            'Downloaded'
                                        )
                                    "
                                    >file_download</mat-icon
                                >
                                {{ taskResult.message }}
                                <mat-icon
                                    *ngIf="taskResult.data"
                                    class="info-icon"
                                    >info</mat-icon
                                >
                            </div>
                            <div
                                class="task-details hidden-details"
                                *ngIf="taskResult.data">
                                <pre>
                                             {{ taskResult.data }}
                                        </pre
                                >
                            </div>
                        </div>
                        <div class="success">{{ taskResult.recordCount }}</div>
                        <div class="success">{{ taskResult.duration }}ms</div>
                        <div class="success">
                            {{ taskResult.success ? 'Success' : 'Failure' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

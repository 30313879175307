<ng-container *ngIf="vm$ | async as vm">
	<div uk-grid>
		<div class="uk-width-expand@m">
			<app-layout-header-admin *ngIf="isAdminRoute; else viewer" headerType="default"></app-layout-header-admin>
			<ng-template #viewer><app-layout-header headerType="secure"></app-layout-header></ng-template>
			<div class="uk-padding">
				<ng-container *ngTemplateOutlet="breadcrumbNavLink; context: vm.navBackContext"> </ng-container>
				<div>
					<h2>Settings</h2>
				</div>
				<mat-divider></mat-divider>
				<br />
				<div *ngIf="settings" class="uk-grid uk-flex uk-flex-middle">
					<mat-checkbox [labelPosition]="'before'" (change)="updateTabbedViewing($event)" [checked]="settings.enableTabbedViewing"
						>Open files in a new tab</mat-checkbox
					>
				</div>
				<div *ngIf="settings" class="uk-grid uk-flex uk-flex-middle">
					<mat-checkbox [labelPosition]="'before'" (change)="updateDocumentNotifications($event)" [checked]="settings.enableDocumentNotifications"
						>Enable document notifications</mat-checkbox
					>
				</div>
				<br />

				<mat-divider></mat-divider>
				<br /><br />
				<div>
					<h2>Technical</h2>
				</div>
				<mat-divider></mat-divider>
				<br />
				<div class="uk-grid uk-flex uk-flex-middle">
					<div>Delete local storage and all files</div>
					&nbsp;&nbsp;
					<button mat-raised-button color="primary" (click)="deleteIndexDb()">Delete</button>
				</div>
				<br />
				<mat-divider></mat-divider>
			</div>
		</div>
	</div>
</ng-container>
<ng-template #breadcrumbNavLink let-link="link" let-title="title">
	<div class="uk-margin-bottom">
		<a [routerLink]="link">{{ title }}</a>
	</div>
</ng-template>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakUserModel } from '@ecom/common/authentication/models/keycloak-user-model';
import { Constants } from '@ecom/common/helpers/constants';
import { AddDocumentViewMetricModel } from '@ecom/ecom-app-generated/Models/AddDocumentViewMetricModel';
import { DocumentMetricsService } from '@ecom/ecom-app-generated/Services/DocumentMetricsService';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
	providedIn: 'root',
})
export class FileViewerService {
	constructor(
		private router: Router,
		private keycloakService: KeycloakService,
		private documentMetricsService: DocumentMetricsService
	) {}

	//passing user setting as parameter is more reliable than knowing user service has recent setting
	async viewDocument(docId: string, newTab: boolean = false, isAdmin = false, withNotes = false) {
		//log a document view for this user/document don't wait for a response
		this.documentMetricsService.addDocumentViewMetric(
			new AddDocumentViewMetricModel({
				documentId: docId,
				codeId: Constants.Codes.DocumentViewMetricsCode,
			})
		);

		const path = `files/secure?target=${docId}${isAdmin ? '&useAdmin=true' : ''}${withNotes ? `&${Constants.QueryParams.Notes}=true` : ''}`;
		if (newTab) {
			if (this.keycloakService.isTokenExpired()) {
				await this.keycloakService.updateToken();
			}
			const user = JSON.parse(localStorage.getItem(Constants.Config.UserLocalStorageKey)!) as KeycloakUserModel;
			const token = await this.keycloakService.getToken();
			localStorage.setItem(Constants.Config.UserLocalStorageKey, JSON.stringify({ ...user, token }));

			window.open(`${path}&${Constants.QueryParams.FileView}=true`, '_blank');
			return;
		}
		this.router.navigateByUrl(path);
	}
}

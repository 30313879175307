import { DocumentModel } from '@ecom/ecom-app-generated/Models/DocumentModel';
import { LocalStorageService, IndexDbConfig } from '../local-storage-service/local-storage.service';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalDocumentsService extends LocalStorageService {
	//the 3 object stores
	static get DOCUMENTS_METADATA_STORE() {
		return 'documents-metadata';
	}
	static get DOCUMENTS_THUMBNAIL_STORE() {
		return 'documents-thumbnail';
	}
	static get DOCUMENTS_CONTENT_STORE() {
		return 'documents-content';
	}
	static get DOCUMENTS_STORE_VERSION() {
		return 5;
	}

	//the schema/config
	static get DATABASE_CONFIG() {
		return {
			objectStores: [
				{
					name: LocalDocumentsService.DOCUMENTS_METADATA_STORE,
					model: DocumentModel,
					keyPath: 'documentId', //inline key configuration because keyPath references a property on the model so keyPath automatically used by just passing in the DocumentModel to the indexDb object store
				},
				{
					name: LocalDocumentsService.DOCUMENTS_THUMBNAIL_STORE,
					model: String, // Content stored as base64 string
					//keyPath: 'documentId' //no keyPath in the model so comment out but keeping here so it's known what key is
				},
				{
					name: LocalDocumentsService.DOCUMENTS_CONTENT_STORE,
					model: String, // Content stored as base64 encrypted string
					//keyPath: 'documentId' //no keyPath in the model so comment out but keeping here so it's known what key is
				},
			],
		};
	}

	constructor() {
		super(); // Call the parent class constructor
	}

	async createDatabase(dbName: string): Promise<void> {
		var config: IndexDbConfig = {
			dbName: dbName,
			version: LocalDocumentsService.DOCUMENTS_STORE_VERSION,
			objectStores: LocalDocumentsService.DATABASE_CONFIG.objectStores,
		};

		return super.create(config);
	}

	async openConnection(dbName: string): Promise<IDBDatabase> {
		return this.open(dbName, LocalDocumentsService.DOCUMENTS_STORE_VERSION)
			.then(db => {
				return db;
			})
			.catch(error => {
				console.error('Error opening connection to the database:', error);
				throw error;
			});
	}

	async getDocument(db: IDBDatabase, documentId: string, includeThumbnail: boolean = false, includeContent: boolean = false): Promise<DocumentModel | null> {
		try {
			const document = await super.get<DocumentModel>(db, LocalDocumentsService.DOCUMENTS_METADATA_STORE, documentId);
			if (!document) return null;

			// Include content if requested
			if (includeContent) {
				const content = await super.get<string>(db, LocalDocumentsService.DOCUMENTS_CONTENT_STORE, documentId);
				if (content !== undefined) {
					document.file = content;
				}
			}

			if (includeThumbnail) {
				const thumbnail = await super.get<string>(db, LocalDocumentsService.DOCUMENTS_THUMBNAIL_STORE, documentId);
				if (thumbnail !== undefined) {
					document.thumbnail = thumbnail;
				}
			}

			return document;
		} catch (error) {
			console.error('Error fetching document:', error);
			throw error;
		}
	}

	async getAllDocuments(db: IDBDatabase) {
		try {
			// Use the inherited getAll method to retrieve all document metadata but never with content
			// IMPORTANT: We never want all content for all documents as it will not be handled by small CPU devices like iPAD / mobile
			return await super.getAll<DocumentModel>(db, LocalDocumentsService.DOCUMENTS_METADATA_STORE);
		} catch (error) {
			console.error('Error fetching all document metadata:', error);
			throw error;
		}
	}

	//** Get all documents in the indexDb that do not have content */
	async getAllDocumentsNotSyncedWithContent(db: IDBDatabase) {
		try {
			// Get all keys (documentIds) from the content store
			const contentKeys = await this.getAllKeys(db, LocalDocumentsService.DOCUMENTS_CONTENT_STORE);

			// Get all document metadata
			const allMetadata = await super.getAll<DocumentModel>(db, LocalDocumentsService.DOCUMENTS_METADATA_STORE);

			// Filter metadata to find documents without corresponding content
			const metadataWithoutContent = allMetadata.filter(metadata => !contentKeys.includes(metadata.documentId));

			return metadataWithoutContent;
		} catch (error) {
			console.error('Error fetching all documents without content:', error);
			throw error;
		}
	}

	async getAllDocumentsNotSyncedWithThumbnail(db: IDBDatabase) {
		try {
			// Get all keys (documentIds) from the thumbnail store
			const thumbnailKeys = await this.getAllKeys(db, LocalDocumentsService.DOCUMENTS_THUMBNAIL_STORE);

			// Get all document metadata
			const allMetadata = await super.getAll<DocumentModel>(db, LocalDocumentsService.DOCUMENTS_METADATA_STORE);

			// Filter metadata to find documents without corresponding thumbnails
			const metadataWithoutThumbnail = allMetadata.filter(metadata => !thumbnailKeys.includes(metadata.documentId));

			return metadataWithoutThumbnail;
		} catch (error) {
			console.error('Error fetching all documents without thumbnails:', error);
			throw error;
		}
	}

	async addUpdateDocument(db: IDBDatabase, document: DocumentModel): Promise<void> {
		try {
			// Start a transaction that covers the object store
			const transaction = db.transaction([LocalDocumentsService.DOCUMENTS_METADATA_STORE], 'readwrite');

			// Set up a promise to handle transaction completion
			const transactionPromise = new Promise<void>((resolve, reject) => {
				transaction.oncomplete = () => {
					//console.log("All operations completed for document:", document.documentId);
					resolve();
				};
				transaction.onerror = event => {
					//console.error("Transaction error for document:", document.documentId, transaction.error, event);
					reject(transaction.error);
				};
				transaction.onabort = event => {
					//console.error("Transaction abort for document:", document.documentId, transaction.error, event);
					reject(transaction.error);
				};
			});

			//Validate that developers understand design pattern of never adding content or thumbnail to the metadata store.  Reason: Having large key,value content in object store where you are required to getAllDocuments at times, will lead to out of memory exceptions and large memory usage on all devices
			if (document.file)
				throw new Error(
					"You've attempted to add a document with content. The object store DOCUMENTS_METADATA_STORE does not allow this. That is why DOCUMENTS_CONTENT_STORE exists. Document content can only be added to the content store."
				);
			if (document.thumbnail)
				throw new Error(
					"You've attempted to add a document with thumbnail data.  The object store DOCUMENTS_METADATA_STORE does not allow this.  This is why DOCUMENTS_THUMBNAIL_STORE exists.  Document thubmanil can only bea dded to the thumbnail store."
				);

			// Attempt to add or update the metadata
			await this.addupdate(db, LocalDocumentsService.DOCUMENTS_METADATA_STORE, null, document); // Assume addupdate is defined elsewhere

			// Wait for the transaction to complete
			await transactionPromise;
		} catch (error) {
			// This will catch errors from the add/update operation and the transaction setup
			console.error('Error in add/update document:', document.documentId, error);
			throw error;
		}
	}

	async addUpdateThumbnail(db: IDBDatabase, documentId: string, thumbnailData: any): Promise<void> {
		try {
			const transaction = db.transaction([LocalDocumentsService.DOCUMENTS_THUMBNAIL_STORE], 'readwrite');

			// Set up a promise to handle transaction completion
			const transactionPromise = new Promise<void>((resolve, reject) => {
				transaction.oncomplete = () => resolve();
				transaction.onerror = () => reject(transaction.error);
				transaction.onabort = () => reject(transaction.error);
			});

			// Perform the add/update operation
			await this.addupdate(db, LocalDocumentsService.DOCUMENTS_THUMBNAIL_STORE, documentId, thumbnailData);

			// Wait for the transaction to complete
			await transactionPromise;
		} catch (error) {
			console.error('Error adding or updating thumbnail:', error);
			throw error;
		}
	}

	async addUpdateContent(db: IDBDatabase, documentId: string, contentData: any): Promise<void> {
		try {
			const transaction = db.transaction([LocalDocumentsService.DOCUMENTS_CONTENT_STORE], 'readwrite');

			// Set up a promise to handle transaction completion
			const transactionPromise = new Promise<void>((resolve, reject) => {
				transaction.oncomplete = () => resolve();
				transaction.onerror = () => reject(transaction.error);
				transaction.onabort = () => reject(transaction.error);
			});

			// Perform the add/update operation
			await this.addupdate(db, LocalDocumentsService.DOCUMENTS_CONTENT_STORE, documentId, contentData);

			// Wait for the transaction to complete
			await transactionPromise;
		} catch (error) {
			console.error('Error adding or updating content:', error);
			throw error;
		}
	}

	async deleteDocument(db: IDBDatabase, documentId: string): Promise<void> {
		try {
			// Start a transaction to cover all deletion operations
			const transaction = db.transaction(
				[
					LocalDocumentsService.DOCUMENTS_METADATA_STORE,
					LocalDocumentsService.DOCUMENTS_CONTENT_STORE,
					LocalDocumentsService.DOCUMENTS_THUMBNAIL_STORE,
				],
				'readwrite'
			);

			// Set up a promise to handle transaction completion
			const transactionPromise = new Promise<void>((resolve, reject) => {
				transaction.oncomplete = () => {
					//console.log("Transaction completed successfully for document:", documentId);
					resolve();
				};
				transaction.onerror = event => {
					//console.error("Transaction error for document:", documentId, transaction.error, event);
					reject(transaction.error);
				};
				transaction.onabort = event => {
					//console.error("Transaction aborted for document:", documentId, transaction.error, event);
					reject(transaction.error);
				};
			});

			// Perform the delete operations
			//console.log(`Deleting metadata for documentId: ${documentId}`);
			await this.delete(db, LocalDocumentsService.DOCUMENTS_METADATA_STORE, documentId);
			//console.log(`Deleting content for documentId: ${documentId}`);
			await this.delete(db, LocalDocumentsService.DOCUMENTS_CONTENT_STORE, documentId);
			//console.log(`Deleting thumbnail for documentId: ${documentId}`);
			await this.delete(db, LocalDocumentsService.DOCUMENTS_THUMBNAIL_STORE, documentId);

			// Wait for the transaction to complete
			await transactionPromise;
		} catch (error) {
			console.error('Error deleting document:', documentId, error);
			throw error;
		}
	}
}

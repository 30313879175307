import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, interval, merge, Subject } from 'rxjs';
import { debounceTime, exhaustMap, filter, map, switchMap, takeUntil, catchError, startWith, retry, tap } from 'rxjs/operators';
import { AutoEventResult } from './auto-event.interface';

@Directive({
	selector: '[appAutoEvent]',
})
export class AutoEventDirective implements OnInit, OnDestroy {
	@Input() processor: (/* event: any */) => Promise<any>;
	@Input() intervalTime = 60000; // Default to 1 minute
	@Input() event = 'click'; // Default event is 'click'
	@Input() debounceTime = 300; // Default debounce time is 300ms
	@Input() retries = 3; // Default max retries is 3

	private unsubscribe$ = new Subject<void>();
	private sourceSubject = new Subject<any>();

	constructor(private el: ElementRef) {}

	ngOnInit(): void {
		// Observable for periodic event interval, reset on source event
		const periodicEvent$ = this.sourceSubject.pipe(
			startWith(0), // Start immediately
			switchMap(() =>
				interval(this.intervalTime).pipe(
					//startWith(0), // Emit immediately when switching
					map(() => null) // Pass null initially for periodic events
				)
			)
		);

		// Observable for host element events
		const source$ = this.sourceSubject.pipe(
			debounceTime(this.debounceTime), // Debounce source events
			map(event => event)
		);

		// Combine both source and periodic event observables with error handling
		merge(source$, periodicEvent$)
			.pipe(
				//tap(data => console.log(`merge data`, data)),
				exhaustMap(async value => {
					//console.log(`exhaust map value`, value);
					try {
						return await this.processEvent();
					} catch (error) {
						console.error('Process event error:', error);
						throw error;
					}
				}),
				retry(this.retries), // Retry failed attempts
				catchError(err => {
					return [{ error: err }];
				}),
				takeUntil(this.unsubscribe$)
			)
			.subscribe();

		// Attach source event to the source subject
		fromEvent(this.el.nativeElement, this.event)
			.pipe(
				//tap(event => console.log(`fromEvent event`, event)),
				takeUntil(this.unsubscribe$),
				tap(event => this.sourceSubject.next(event))
			)
			.subscribe(event => {
				this.sourceSubject.next(event);
			});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async processEvent(/* value: any */): Promise<any> {
		//console.log('Processing event value:', value);
		try {
			const result = await this.processor();
			return { success: result };
		} catch (error) {
			console.error('Process event error:', error);
			throw error;
		}
	}
}

<!-- DEFAULT ADMIN - DASHBOARD VIEW -->
<ng-container>
    <nav class="uk-navbar-container uk-navbar-transparent">
        <div
            class="uk-container uk-container-expand"
            style="padding: 5px 10px; margin-left: 10px">
            <div uk-navbar="" class="uk-navbar">
                <div class="uk-navbar-left" style="min-width: 250px">
                    <div class="logo">
                        <a
                            aria-label="Digital Meeting Experience"
                            title="Version API:{{
                                version?.apiVersionNumber
                            }} / WEB:{{ version?.webVersionNumber }}"
                            [routerLink]="['/admin/home']">
                            <app-logo></app-logo>
                        </a>
                        <p
                            style="
                                padding-left: 10px;
                                margin-bottom: 0px;
                                display: inline-block;
                                vertical-align: middle;
                            ">
                            Digital Meeting Experience
                        </p>
                    </div>
                </div>
                <div class="uk-navbar-right">
                    <ul class="uk-navbar-nav menu-nav">
                        <li class="uk-visible@m">
                            <a
                                [routerLink]="['/admin/home']"
                                routerLinkActive="active"
                                aria-haspopup="false"
                                >Dashboard</a
                            >
                        </li>
                        <li class="uk-visible@m">
                            <a
                                [routerLink]="['/admin/meetings']"
                                routerLinkActive="active"
                                aria-haspopup="false"
                                (click)="reloadIfSameUrl('/admin/meetings')"
                                >View Meetings</a
                            >
                        </li>
                        <li class="uk-visible@m" *ngIf="isAgendaPreview">
                            <a
                                [routerLink]="['/admin/agendapreview']"
                                routerLinkActive="active"
                                role="menuitem"
                                class="agenda-preview"
                                >CPC Materials</a
                            >
                        </li>
                        <li class="uk-hidden@s">
                            <a
                                href=""
                                role="button"
                                aria-expanded="false"
                                aria-haspopup="true"
                                aria-controls="mobile-nav"
                                (click)="toggle_MobileNav($event)"
                                aria-label="Open mobile menu"
                                >Menu
                                <mat-icon>expand_more</mat-icon>
                            </a>
                        </li>
                        <li class="uk-visible@s">
                            <a
                                href=""
                                role="button"
                                aria-haspopup="true"
                                aria-controls="desktop-ddn"
                                aria-label="Open menu"
                                >Menu<mat-icon>expand_more</mat-icon></a
                            >
                            <div
                                id="desktop-ddn"
                                class="uk-navbar-dropdown uk-drop uk-navbar-dropdown-width-2 desktop-ddn uk-visible@s"
                                uk-drop="mode: click; cls-drop: uk-navbar-dropdown; boundary: !.uk-navbar; flip: false">
                                <ul
                                    class="uk-nav uk-navbar-dropdown-nav"
                                    style="
                                        position: relative;
                                        display: flex;
                                        flex-direction: column;
                                        width: 100%;
                                    ">
                                    <ul
                                        style="
                                            position: relative;
                                            display: flex;
                                            flex-direction: column;
                                            width: 100%;
                                        ">
                                        <!-- desktop-ddn -->
                                        <app-user-profile-menu
                                            [isAdmin]="true"
                                            [user]="user"
                                            [activeMenu]="activeMenu"
                                            (onOpenFeedbackModal)="
                                                openFeedbackModal()
                                            "></app-user-profile-menu>
                                    </ul>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="uk-width-1-1">
            <hr class="uk-margin-remove" />
        </div>
        <app-banner-notications
            notificationType="admin"></app-banner-notications>
    </nav>

    <!--MOBILE- full width -->
    <div
        [class]="
            'menu-nav-mobile ' +
            (isMobileNavVisible ? 'animate-open' : 'uk-hidden')
        "
        id="mobile-nav">
        <ul class="uk-list uk-list-divider">
            <ul
                *ngIf="headerType !== 'meeting-add-edit'"
                style="
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                ">
                <app-user-profile-menu
                    [isAdmin]="true"
                    [user]="user"
                    (onOpenFeedbackModal)="
                        openFeedbackModal()
                    "></app-user-profile-menu>
            </ul>
        </ul>
    </div>
</ng-container>

<app-feedback #feedbackComp [dialogId]="generatedDialogId"></app-feedback>


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';
 
import { AuditLogRequestModel } from '../Models/AuditLogRequestModel'
import { AuditLogResponseModel } from '../Models/AuditLogResponseModel' 
import { IdRequestModel } from '../Models/IdRequestModel'
import { AuditLogModel } from '../Models/AuditLogModel' 
import { AuditLogPagingRequestModel } from '../Models/AuditLogPagingRequestModel' 
import { AddAuditLogRequestModel } from '../Models/AddAuditLogRequestModel'

@Injectable({
     providedIn: 'root'
 })
export class AuditLogsService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'auditlogs');
    } 

  //get
  public getAuditLogs(model: AuditLogRequestModel) : Promise<ServiceResult<AuditLogResponseModel>> {
      return this.getWithQuery<ServiceResult<AuditLogResponseModel>,AuditLogRequestModel>('GetAuditLogs',model);
  };
  
  //get
  public getAuditLogByEventId(model: IdRequestModel) : Promise<ServiceResult<AuditLogModel>> {
      return this.getWithQuery<ServiceResult<AuditLogModel>,IdRequestModel>('GetAuditLogByEventId',model);
  };
  
  //get
  public getNextAuditLogs(model: AuditLogPagingRequestModel) : Promise<ServiceResult<AuditLogModel[]>> {
      return this.getWithQuery<ServiceResult<AuditLogModel[]>,AuditLogPagingRequestModel>('GetNextAuditLogs',model);
  };
  
  
  //post
  public addAuditLog(model: AddAuditLogRequestModel) : Promise<ServiceResult<AuditLogModel>> {
      return this.post<ServiceResult<AuditLogModel>,AddAuditLogRequestModel>('AddAuditLog',model);
  };
}
import { Constants } from '@ecom/common/helpers/constants';
import { LocalDocumentsService } from '@ecom/common/services/local-documents-service/local-documents.service';
import {
    ITask,
    TaskResult,
} from '@ecom/app-workflows/core/task-pipeline/task-interface';
import { DocumentsService } from '@ecom/ecom-app-generated/Services/DocumentsService';
import { DocumentModel } from '@ecom/ecom-app-generated/Models/DocumentModel';
import { ConfigService } from '@ecom/common/services/config.service';
import { HttpClient } from '@angular/common/http';
import { KeycloakUserModel } from '@ecom/common/authentication/models/keycloak-user-model';
import { filter, firstValueFrom } from 'rxjs';
import { AuthenticationService } from '@ecom/common/authentication/services/authentication.service';

export class GetAdminDocumentContentsTask implements ITask {
    totalDataDownloaded = 0;
    totalDownloadTime = 0;
    totalDownloads = 0;
    maxDocumentsAtAnyOneTime = 5;
    partialResultCallback?: (result: TaskResult) => void;
    user: KeycloakUserModel | undefined;

    constructor(
        private configService: ConfigService,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private documentsService: DocumentsService,
        private localDocumentsService: LocalDocumentsService
    ) {}
    async execute(
        context: Map<string, any>,
        onPartialResult?: (result: TaskResult) => void
    ): Promise<TaskResult> {
        try {
            this.totalDataDownloaded = 0;
            this.totalDownloadTime = 0;
            this.totalDownloads = 0;

            this.user = await firstValueFrom(
                this.authenticationService
                    .currentUser()
                    .pipe(filter(user => user !== undefined))
            );

            //This task for each document sends a TaskResult (isPartial=true) with the document that it completed
            this.partialResultCallback = onPartialResult;

            //Ensure we close the connection
            var detailedMessageResult =
                await this.getDocumentContentsTask(context);

            return {
                success: true,
                message: `Get admin document contents task completed.`,
                data: detailedMessageResult,
                recordCount: this.totalDownloads,
            };
        } catch (error: any) {
            return {
                success: false,
                message: `Get admin document contents task failed`,
                data: error.message,
            };
        }
    }

    async getDocumentContentsTask(context: Map<string, any>): Promise<string> {
        let connection: IDBDatabase | null = null;
        var detailedMessage = '';

        try {
            const startTime = performance.now();
            connection = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForAdmin
            );
            var documentModels =
                await this.localDocumentsService.getAllDocumentsNotSyncedWithContent(
                    connection
                );

            // Sort documentModels by updateDate in descending order
            // Assuming updateDate is a string in a format that can be converted to a Date object
            documentModels.sort((a, b) => {
                // Convert updateDate to Date objects, handling nulls by setting them to a date far in the past
                const dateA = a.updateDate
                    ? new Date(a.updateDate).getTime()
                    : 0;
                const dateB = b.updateDate
                    ? new Date(b.updateDate).getTime()
                    : 0;

                return dateB - dateA; // Sort in descending order
            });

            //Good for debugging what documents are coming back and sorted
            //console.log("Sorted document models for download", documentModels);

            // Extract the DocumentId from the sorted documentModels
            var documentIds = documentModels.map(
                documentModel => documentModel.documentId
            );

            if (documentIds.length === 0) {
                detailedMessage = `All admin document contents in sync.`;
                return detailedMessage;
            } else {
                await this.downloadDocuments(documentIds);
                const duration = Math.round(performance.now() - startTime); // Duration in milliseconds, rounded

                if (this.totalDownloadTime > 0) {
                    const aggregateSpeedMbps = (
                        this.totalDataDownloaded /
                        (1024 * 1024) /
                        (duration / 1000)
                    ).toFixed(2);
                    detailedMessage = `Downloaded ${this.totalDownloads} files, in ${(duration / 1000).toFixed(2)} seconds, total data: ${(this.totalDataDownloaded / (1024 * 1024)).toFixed(2)} MB, speed: ${aggregateSpeedMbps} Mbps`;
                    return detailedMessage;
                } else {
                    detailedMessage = `Downloaded ${this.totalDownloads} files, in ${(duration / 1000).toFixed(2)} seconds`;
                    return detailedMessage;
                }
            }
        } catch (error) {
            throw error;
        } finally {
            // Ensure we clean up connection always to avoid memory leaks
            if (connection) {
                connection.close();
                connection = null;
            }
        }
    }

    private async downloadContent(documentId: string): Promise<void> {
        let connection: IDBDatabase | null = null;
        let document: DocumentModel | null = null;
        const startTime = performance.now();
        let url = '';

        try {
            connection = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForAdmin
            );
            document = await this.localDocumentsService.getDocument(
                connection,
                documentId,
                false,
                true
            );
            if (!document)
                throw new Error(
                    'Document metadata could not be found in local storage.'
                );

            const foundDocument =
                await this.checkViewerBucketForDocument(documentId);
            if (foundDocument) {
                this.logAndCallbackSuccess(
                    document,
                    startTime,
                    foundDocument.documentName,
                    'Discovered in admin local storage'
                );
                return;
            }

            url = `${this.configService.get(Constants.Config.ConfigApiCoreBaseUrl)}/api/documents/GetFastDocumentBinaryById?id=${documentId}`;
            const response = await this.http
                .get(url, { responseType: 'blob', observe: 'response' })
                .toPromise();

            if (!response || response.status !== 200 || !response.body) {
                throw new Error(
                    `HTTP error: Status code ${response?.status}, Status text: ${response?.statusText}`
                );
            }

            const fileKey = response.headers.get('X-File-Key');
            if (!fileKey)
                throw new Error(
                    'Document key was not retrieved from request header, as expected.'
                );

            const base64Data = await this.readBlobAsBase64(response.body);

            await this.updateLocalStorageWithDocumentData(
                connection,
                document,
                documentId,
                base64Data,
                fileKey,
                startTime
            );
        } catch (error: any) {
            this.handleError(
                error,
                startTime,
                document?.documentName,
                documentId
            );
        } finally {
            this.cleanUp(connection);
        }
    }

    private async readBlobAsBase64(blob: Blob): Promise<string | null> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result) {
                    const base64Data = reader.result.toString().split(',')[1];
                    resolve(base64Data);
                } else {
                    reject(new Error('FileReader did not return result'));
                }
            };

            reader.onerror = () => {
                reject(new Error(`FileReader error: ${reader.error?.message}`));
            };

            reader.onabort = () => {
                reject(new Error('FileReader read aborted'));
            };

            reader.readAsDataURL(blob);
        });
    }

    private logAndCallbackSuccess(
        document: DocumentModel,
        startTime: number,
        documentName: string,
        additionalData: string
    ): void {
        const duration = Math.round(performance.now() - startTime);
        this.totalDownloads++;
        this.partialResultCallback?.({
            success: true,
            message: `Downloaded ${documentName}`,
            data: additionalData,
            duration: duration,
        });
    }

    private handleError(
        error: any,
        startTime: number,
        documentName: string | undefined,
        documentId: string
    ): void {
        const duration = Math.round(performance.now() - startTime);
        console.error(`Error downloading document ID: ${documentId}`, error);
        this.partialResultCallback?.({
            success: false,
            message: `Download of ${documentName || 'document'} unsuccessful`,
            data: `Error: ${error.message}, Document ID: ${documentId}`,
            duration: duration,
        });
    }

    private cleanUp(connection: IDBDatabase | null): void {
        if (connection) {
            connection.close();
        }
    }

    private async updateLocalStorageWithDocumentData(
        connection: IDBDatabase,
        document: DocumentModel,
        documentId: string,
        base64Data: string | null,
        fileKey: string,
        startTime: number
    ): Promise<void> {
        try {
            if (!base64Data)
                throw new Error('Failed to convert Blob to Base64.');

            document.fileKey = fileKey;

            await this.localDocumentsService.addUpdateDocument(
                connection,
                document
            ); // Update document metadata with key
            await this.localDocumentsService.addUpdateContent(
                connection,
                documentId,
                base64Data
            ); // Update document content

            // Update total data downloaded and total download time
            const fileSize = document.fileSize ?? 0; // Ensure fileSize is not undefined
            this.totalDataDownloaded += fileSize;
            const duration = Math.round(performance.now() - startTime);
            this.totalDownloadTime += duration;
            this.totalDownloads++;

            const fileSizeMB = (fileSize / (1024 * 1024)).toFixed(3); // File size in MB, rounded to 3 decimal places
            const speedMbps = (
                fileSize /
                (1024 * 1024) /
                (duration / 1000)
            ).toFixed(2); // Speed in Mbps, rounded to 2 decimal places

            this.partialResultCallback?.({
                success: true,
                message: `Downloaded ${document.documentName}`,
                data: `File size: ${fileSizeMB} MB, Speed: ${speedMbps} Mbps`,
                duration: duration,
            });

            base64Data = null;
        } catch (error: any) {
            // If there is an error during the database update, handle it
            this.handleError(
                error,
                startTime,
                document.documentName,
                documentId
            );
        }
    }

    // private async downloadContent(documentId: string): Promise<void> {
    //      let connection: IDBDatabase | null = null;
    //      let document: DocumentModel | null = null;
    //      const startTime = performance.now();
    //      let url = '';
    //      let response = null; // Declare the response variable here

    //      try {
    //           //Get the document from local storage
    //           connection = await this.localDocumentsService.openConnection(Constants.LocalStorage.DocumentsForAdmin);
    //           document = await this.localDocumentsService.getDocument(connection, documentId, false, true);
    //           if (!document) throw Error("Document meta data could not be found in local storage.");

    //           //Additional performance feature ask - so that we aren't downloading documents twice (once for viewer, and once for admin);
    //           var foundDocument = await this.checkViewerBucketForDocument(documentId);
    //           if (foundDocument) {
    //                const duration = Math.round(performance.now() - startTime);
    //                this.totalDownloads++;
    //                this.partialResultCallback?.({
    //                     success: true,
    //                     message: `Downloaded ${foundDocument!.documentName}`,
    //                     data: `Discovered in admin local storage`,
    //                     duration: duration
    //                });
    //                return;
    //           }

    //           url = `${this.configService.get(Constants.Config.ConfigApiCoreBaseUrl)}/api/documents/GetFastDocumentBinaryById?id=${documentId}`;
    //           response = await this.http.get(url, { responseType: 'blob', observe: 'response' }).toPromise();
    //           const duration = Math.round(performance.now() - startTime); // Duration in milliseconds, rounded

    //           if (response && response.status === 200 && response.body) {
    //                //Get the encrypted key for the document
    //                const fileKey = response.headers.get('X-File-Key');

    //                // Check if fileKey is null, undefined, an empty string, or has no length
    //                if (fileKey === null || fileKey === undefined || fileKey === '' || fileKey.length === 0) {
    //                     throw Error("Document key was not retrieved from request header, as expected.");
    //                }

    //                var base64Data = await this.readBlobAsBase64(response.body);
    //                if (base64Data) {

    //                     try {

    //                          document.fileKey = fileKey;

    //                          await this.localDocumentsService.addUpdateDocument(connection, document); //update document meta data with key
    //                          await this.localDocumentsService.addUpdateContent(connection, documentId, base64Data); //update document content

    //                          //Update total data downloaded and total download time
    //                          this.totalDataDownloaded += document!.fileSize;
    //                          this.totalDownloadTime += duration;
    //                          this.totalDownloads++;
    //                          const fileSizeMB = (document!.fileSize / (1024 * 1024)).toFixed(3); // File size in MB, rounded to 2 decimal places
    //                          const speedMbps = ((document!.fileSize / (1024 * 1024)) / (duration / 1000)).toFixed(2); // Speed in Mbps for this file, rounded to 2 decimal places

    //                          this.partialResultCallback?.({
    //                               success: true, // Or false, depending on the operation result
    //                               message: `Downloaded ${document!.documentName}`,
    //                               data: `file size: ${fileSizeMB} MB, speed: ${speedMbps} Mbps`,
    //                          });

    //                     }
    //                     catch (error: any) {
    //                          throw error;
    //                     }
    //                     finally {
    //                          //clear variables immediately for fastest garbage collection; yes important
    //                          base64Data = null;
    //                          response = null;
    //                          document = null;

    //                          //ensure we clean up connection always to avoid memory leaks
    //                          if (connection) {
    //                               connection.close();
    //                               connection = null;
    //                          }
    //                     }
    //                }
    //           } else {
    //                //Handle non-success responses
    //                this.partialResultCallback?.({
    //                     success: false,
    //                     message: `The document could not be downloaded from the server. Status: ${response!.status}`,
    //                     data: `document ID: ${documentId}`, // Some relevant data
    //                });
    //                console.log("Bad response for file download", response!.status);
    //           }

    //      } catch (error: any) {
    //           const duration = Math.round(performance.now() - startTime);
    //           // Handle non-success responses
    //           this.partialResultCallback?.({
    //                success: false,
    //                message: `Download of ${document!.documentName} unsuccessful`,
    //                data: `${error.message},${documentId}`,
    //                duration: duration
    //           });
    //           console.log(error);
    //      }

    // }

    // private async readBlobAsBase64(blob: Blob): Promise<string | null> {
    //      return new Promise((resolve, reject) => {
    //           let reader: FileReader | null = new FileReader(); // Use let for block scope
    //           reader.readAsDataURL(blob);
    //           reader.onloadend = () => {
    //                const base64Data = reader!.result ? reader!.result.toString().split(',')[1] : null;
    //                resolve(base64Data);
    //                reader = null; // Attempting to nullify reader here might still raise issues
    //           };
    //           reader.onerror = (error) => {
    //                reject(new Error('Failed to read blob as base64'));
    //                reader = null; // Same issue as above
    //           };
    //      });
    // }

    private async asyncPool(
        poolLimit: number,
        array: any[],
        iteratorFn: (value: any) => Promise<void>
    ): Promise<void[]> {
        const ret = [];
        const executing: any[] = [];
        for (const item of array) {
            const p = Promise.resolve().then(() => iteratorFn(item));
            ret.push(p);

            if (poolLimit <= array.length) {
                const e = p.then(() =>
                    executing.splice(executing.indexOf(e), 1)
                ) as Promise<void>;
                executing.push(e);
                if (executing.length >= poolLimit) {
                    await Promise.race(executing);
                }
            }
        }
        return Promise.all(ret);
    }

    private async downloadDocuments(documents: string[]): Promise<void> {
        await this.asyncPool(
            this.maxDocumentsAtAnyOneTime,
            documents,
            document => this.downloadContent(document)
        );
    }

    private async checkViewerBucketForDocument(
        documentId: string
    ): Promise<DocumentModel | null> {
        //Do the check for admin just to save this from being executed from efficiency standpoint
        //As a pure viewer won't have an admin localstorage anyways, so the check technically isn't necessary
        if (!this.user?.userRoles.includes('dmx-admin')) return null;

        let connection1: IDBDatabase | null = null;
        let connection2: IDBDatabase | null = null;
        try {
            connection1 = await this.localDocumentsService.openConnection(
                Constants.LocalStorage.DocumentsForViewer
            );
            const document = await this.localDocumentsService.getDocument(
                connection1,
                documentId,
                true,
                true
            );

            if (document && document.file) {
                connection2 = await this.localDocumentsService.openConnection(
                    Constants.LocalStorage.DocumentsForAdmin
                );
                const documentFromViewer =
                    await this.localDocumentsService.getDocument(
                        connection2,
                        documentId,
                        false,
                        false
                    );

                //Now that we know document exists in admins local storage and content for this file exists in the admin local storage, lets ensure it's the same version by just grabbing the metadata
                if (
                    documentFromViewer &&
                    documentFromViewer.updateDate == document.updateDate
                ) {
                    await this.localDocumentsService.addUpdateContent(
                        connection2,
                        document.documentId,
                        document.file
                    );
                    await this.localDocumentsService.addUpdateThumbnail(
                        connection2,
                        document.documentId,
                        document.thumbnail
                    );

                    //when storing DocumentModel in metadata we never store the content/file and/or thumbnail
                    document.file = '';
                    document.thumbnail = '';
                    await this.localDocumentsService.addUpdateDocument(
                        connection2,
                        document
                    );
                    return document;
                }
            }
        } catch (error: any) {
            throw error;
        } finally {
            //ensure we clean up connection always to avoid memory leaks
            if (connection1) {
                connection1.close();
                connection1 = null;
            }

            if (connection2) {
                connection2.close();
                connection2 = null;
            }
        }

        return null;
    }
}

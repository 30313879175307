import moment from 'moment';

export class DateHelper {
	static albertaRightNow(): moment.Moment {
		//this function gets the current time in Alberta
		return moment().tz('America/Edmonton').local(true);
	}

	static momentFromUtcToMst(utcDate: Date): moment.Moment {
		let mst = moment.utc(utcDate, true).tz('America/Denver');
		return mst;
	}

	static asMst(date: Date): moment.Moment {
		let mst = moment(date, true).tz('America/Denver').local(true);
		return mst;
	}

	static addMinutes(date: Date, durationInMinutes: number): Date {
		let retVal = moment(date, true);

		//add the duration in minutes to the date being passed in
		retVal.add(durationInMinutes, 'minutes');
		return retVal.toDate();
	}
}

export const getDaysUntilMSTDate = (date: any): number => {
	//assume the date provided is already in MST
	//ignore the time and just find out whether the meeting is on the same DAY
	//then show the appropriate badge
	const dateValue = moment.tz(date, 'America/Denver').startOf('day');
	const today = moment().tz('America/Denver').startOf('day');
	const days = Math.floor(dateValue.diff(today, 'days', true));
	return days;
};


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResult } from '@ecom/ecom-app-generated/core-api/ServiceResult';
import { EndPointService } from '@ecom/ecom-app-generated/core-api/endpoint.service';
import { ConfigService } from '@ecom/common/services/config.service';
 
import { IdRequestModel } from '../Models/IdRequestModel'
import { DocumentAssignmentsModel } from '../Models/DocumentAssignmentsModel' 
import { AddDocumentAssignmentRequestModel } from '../Models/AddDocumentAssignmentRequestModel'
import { IdResponseModel } from '../Models/IdResponseModel' 
import { ReorderDocumentAssignmentsRequestModel } from '../Models/ReorderDocumentAssignmentsRequestModel'

@Injectable({
     providedIn: 'root'
 })
export class DocumentAssignmentsService extends EndPointService {

    constructor(http: HttpClient, configService: ConfigService) {
      super(http, configService,'documentassignments');
    } 

  //get
  public getDocumentAssignmentsByMeetingId(model: IdRequestModel) : Promise<ServiceResult<DocumentAssignmentsModel[]>> {
      return this.getWithQuery<ServiceResult<DocumentAssignmentsModel[]>,IdRequestModel>('GetDocumentAssignmentsByMeetingId',model);
  };
  
  
  //post
  public addDocumentAssignment(model: AddDocumentAssignmentRequestModel) : Promise<ServiceResult<DocumentAssignmentsModel>> {
      return this.post<ServiceResult<DocumentAssignmentsModel>,AddDocumentAssignmentRequestModel>('AddDocumentAssignment',model);
  };
  
  //post
  public deleteDocumentAssignmentById(model: IdRequestModel) : Promise<ServiceResult<IdResponseModel>> {
      return this.post<ServiceResult<IdResponseModel>,IdRequestModel>('DeleteDocumentAssignmentById',model);
  };
  
  //post
  public deleteDocumentAssignmentByAgendaItemId(model: IdRequestModel) : Promise<ServiceResult<IdResponseModel>> {
      return this.post<ServiceResult<IdResponseModel>,IdRequestModel>('DeleteDocumentAssignmentByAgendaItemId',model);
  };
  
  //post
  public sortDocumentAssignments(model: ReorderDocumentAssignmentsRequestModel) : Promise<ServiceResult<DocumentAssignmentsModel[]>> {
      return this.post<ServiceResult<DocumentAssignmentsModel[]>,ReorderDocumentAssignmentsRequestModel>('SortDocumentAssignments',model);
  };
}